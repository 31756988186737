import React from "react";
import { Head } from "@/Components/Atoms/Head";
import { Container } from "@/Components/Atoms/Container";
import ReactMarkdown from "react-markdown";

export type UserAgreementProps = {
  title: string;
  content: string;
};

const Markdown = (props: UserAgreementProps) => {
  return (
    <>
      <Head title={props.title} />
      <Container className="py-8">
        <ReactMarkdown className="prose prose-invert">{props.content}</ReactMarkdown>
      </Container>
    </>
  );
};

export default Markdown;
