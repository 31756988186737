import React, { forwardRef } from "react";
import { cn } from "@/Utils/shadcn";
import TextareaAutosize, { TextareaAutosizeProps } from "react-textarea-autosize";

export type InlineTextareaProps = TextareaAutosizeProps;

export const InlineTextarea = forwardRef<HTMLTextAreaElement, InlineTextareaProps>(
  ({ className, ...props }, ref) => {
    return (
      <div className="-m-1">
        <TextareaAutosize
          ref={ref}
          className={cn(
            "w-full resize-none rounded-sm border-none bg-transparent p-1 ring-1 ring-muted transition-none focus-visible:border-transparent focus-visible:shadow-sm focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-text disabled:opacity-100",
            className,
          )}
          placeholder=" "
          {...props}
        />
      </div>
    );
  },
);
