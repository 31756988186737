import {
  ArrowUpIcon,
  CalendarIcon,
  ChatBubbleLeftRightIcon,
  ClockIcon,
} from "@heroicons/react/20/solid";
import { Icon } from "@/Types/Icon";
import { BattlestationSorting } from "@/Types/generated_enums";

export const BattlestationSortingLabel = new Map<BattlestationSorting, string>([
  [BattlestationSorting.Popular, "Most Popular"],
  [BattlestationSorting.Recent, "Most Recent"],
  [BattlestationSorting.Active, "Recently Active"],
  [BattlestationSorting.Updated, "Recently Updated"],
]);

export const BattlestationSortingIcon = new Map<BattlestationSorting, Icon>([
  [BattlestationSorting.Popular, ArrowUpIcon],
  [BattlestationSorting.Recent, CalendarIcon],
  [BattlestationSorting.Active, ChatBubbleLeftRightIcon],
  [BattlestationSorting.Updated, ClockIcon],
]);
