import { assign, isObject, reduce } from "lodash";
import { flattenObject } from "@/Utils/flattenObject";

export const getFilteredErrors = (
  errors: object,
  patterns: string[],
  getErrorMessage?: (key: string, message: string) => string,
) => {
  const regexPatterns = patterns.map(
    (pattern) =>
      new RegExp(
        "^" +
          `${pattern}`
            .replace(/\./g, "\\.")
            .replace(/\*\*/g, ".*")
            .replace(/\\.\*\\./g, "\\.[^.]+\\.") +
          "$",
      ),
  );

  /*
   * for values thate are objects (front-end validation errors),
   * we want to flatten them and only include the keys that end with .message
   *
   * for values that are strings (backend validation errors),
   * we want to keep them as they are, as they are already the error message
   */
  let flattened = reduce<Record<string, any>, Record<string, any>>(
    errors,
    (result, value, key) => {
      return isObject(value)
        ? assign(
            result,
            flattenObject(value, key, (key) => key.endsWith(".message")),
          )
        : assign(result, { [key]: value });
    },
    {},
  );

  // front-end validation errors are objects with a .message property,
  // whereas backend validation return dot-notation keys with the error message,
  // so we need to normalize the keys to be consistent
  flattened = reduce<Record<string, any>, Record<string, any>>(
    flattened,
    (result, value, key) => {
      if (key.endsWith(".message")) {
        result[key.split(".").slice(0, -1).join(".")] = value;
      } else if (typeof value === "string") {
        result[key] = value;
      }
      return result;
    },
    {},
  );

  return reduce<Record<string, string>, Record<string, string>>(
    flattened,
    (acc, value, key) => {
      if (regexPatterns.some((regex) => regex.test(key))) {
        acc[key] = getErrorMessage?.(key, value) ?? value;
      }
      return acc;
    },
    {},
  );
};
