import React from "react";
import { Rect } from "@/Types/Rect";
import { Motion, spring } from "react-motion";
import { useWidgetsStore } from "@/Stores/useWidgetsStore";
import { Button } from "@/Components/ui/button";

export type ExpandWidgetsProps = {
  rect: Rect;
  collapsed: boolean;
  onExpand: () => void;
};

export const ExpandWidgets = (props: ExpandWidgetsProps) => {
  const unitSize = useWidgetsStore((state) => state.unitSize);

  if (!props.collapsed) return null;

  const Card = (
    <div style={{ width: unitSize, height: unitSize }} className="rounded-3xl border bg-card" />
  );

  return (
    <Motion
      style={{
        x: spring(props.rect.x),
        y: spring(props.rect.y),
      }}>
      {(style) => (
        <div
          style={{
            position: "absolute",
            willChange: "translate",
            translate: `${style.x}px ${style.y}px`,
            width: props.rect.width,
            height: props.rect.height,
          }}
          className="ovreflow-hidden relative">
          <div
            style={{
              maskImage:
                "linear-gradient(to bottom, rgba(0, 0, 0, 1.0) 0%, rgba(0, 0, 0, 0.0) 100%)",
            }}
            className="flex h-full justify-between">
            {Card}
            {Card}
          </div>
          <div className="absolute inset-0 flex items-center justify-center">
            <Button onClick={props.onExpand}>Show more widgets</Button>
          </div>
        </div>
      )}
    </Motion>
  );
};
