import React from "react";
import type { SVGProps } from "react";
const SvgDesk = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" viewBox="0 0 24 24" {...props}>
    <path d="M0 0h24v24H0z" />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={2}
      d="M22 3H2M3 21V3M21 7V4M21 7H4"
    />
    <path stroke="currentColor" strokeWidth={2} d="M13 7h8v12a2 2 0 0 1-2 2h-4a2 2 0 0 1-2-2z" />
    <circle cx={16} cy={14} r={1} fill="currentColor" />
  </svg>
);
export default SvgDesk;
