import React from "react";
import { Link } from "@/Components/Atoms/Link";
import { route } from "ziggy-js";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/Components/ui/form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Input } from "@/Components/ui/input";
import { Checkbox } from "@/Components/ui/checkbox";
import { Button } from "@/Components/ui/button";
import { PasswordInput } from "@/Components/ui/password-input";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/Components/ui/dialog";
import { useMomentumModal } from "@/Hooks/useMomentumModal";

const formSchema = z.object({
  username: z.string(),
  password: z.string(),
  remember_me: z.boolean(),
});

const Login = () => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: "",
      password: "",
      remember_me: false,
    },
  });

  const { show, close, redirect } = useMomentumModal();

  return (
    <Dialog
      open={show}
      onOpenChange={(open) => {
        if (!open) {
          // We call close() first to make closing the dialog feel more responsive
          close();
          redirect();
        }
      }}>
      <DialogContent className="max-h-[94%] max-w-sm overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Sign in to your account</DialogTitle>
          <DialogDescription>
            Or{" "}
            <Link href={route("register")} preserveState preserveScroll>
              create a new account
            </Link>
          </DialogDescription>
        </DialogHeader>
        <Form
          {...form}
          route={route("login.store")}
          options={{
            preserveState: true,
            preserveScroll: true,
          }}
          className="space-y-4">
          <FormField
            control={form.control}
            name="username"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Username or email</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter username or email..."
                    autoComplete="username"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Password</FormLabel>
                <FormControl>
                  <PasswordInput
                    placeholder="Enter password..."
                    autoComplete="current-password"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex items-center justify-between">
            <FormField
              control={form.control}
              name="remember_me"
              render={({ field }) => (
                <FormItem>
                  <div className="flex items-center gap-2">
                    <FormControl>
                      <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                    </FormControl>
                    <FormLabel className="font-normal">Remember me</FormLabel>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Link href={route("password.forgot")} className="text-sm">
              Forgot your password?
            </Link>
          </div>
          <Button type="submit" className="w-full">
            Sign in
          </Button>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default Login;
