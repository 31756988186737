import React, { useMemo, useState } from "react";
import { FieldTypeIcon } from "@/API/Maps/FieldType";
import { ExternalLink, ListPlus, Sparkles, X } from "lucide-react";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/Components/ui/form";
import { InlineEdit } from "@/Components/Atoms/InlineEdit";
import { Button, buttonVariants } from "@/Components/ui/button";
import { FieldPathByValue, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { BattlestationForm } from "@/API/Forms/BattlestationForm";
import { Subfield } from "@/Components/Molecules/Battlestation/Widgets/CustomCardWidget/Subfield";
import { useConfirm } from "@/Contexts/ConfirmDialogContext";
import { countBy, filter, findIndex, fromPairs, get, map } from "lodash";
import { PopoverWrapper } from "@/Components/Molecules/PopoverWrapper";
import { Command } from "cmdk";
import { cn } from "@/Utils/shadcn";
import { CommandEmpty, CommandGroup, CommandItem, CommandList } from "@/Components/ui/command";
import { uuid } from "@/Utils/uuid";
import { DETAIL_LIMIT } from "@/Utils/constants";
import { useIsMobile } from "@/Hooks/useIsMobile";
import { useBattlestationStore } from "@/Stores/useBattlestationStore";
import { EditProductLink } from "@/Components/Molecules/Battlestation/Widgets/CustomCardWidget/EditProductLink";

export type FieldProps = {
  name: FieldPathByValue<BattlestationForm, App.Data.Models.FieldData>;
  field: App.Data.Models.FieldData;
  fieldDefinition?: App.Data.FieldDefinitionData;
  fieldCount: number;
  showErrors: boolean;
  onRemove: () => void;
};

export const Field = (props: FieldProps) => {
  const editing = useBattlestationStore((state) => state.editing);

  const form = useFormContext<BattlestationForm>();

  const confirm = useConfirm();

  const {
    fields: arrayFields,
    insert,
    remove,
  } = useFieldArray({
    control: form.control,
    name: `${props.name}.fields` as "widgets.0.card.fields.0.fields",
  });

  const watchedFields = useWatch({
    control: form.control,
    name: `${props.name}.fields` as "widgets.0.card.fields.0.fields",
  });

  const controlledFields = arrayFields.map((field, index) => ({
    ...field,
    ...watchedFields[index],
    // if we don't use the id from the field array weird shit happens
    id: field.id,
  }));

  const subfields = editing ? controlledFields : props.field.fields;

  const availableSubfields = useMemo(() => {
    const counts = countBy(subfields, "type");
    return filter(
      props.fieldDefinition?.fields,
      (field) => !field.limit || field.limit > get(counts, field.type, 0),
    );
  }, [subfields, props.fieldDefinition]);

  const isMobile = useIsMobile();

  const [open, setOpen] = useState(false);

  const Icon = FieldTypeIcon.get(props.field.type) ?? Sparkles;

  return (
    <div
      className={cn(
        "flex w-full flex-col",
        editing ? "gap-1" : "rounded-lg border bg-card px-3 py-2",
      )}>
      {editing ? (
        <>
          <FormField
            control={form.control}
            name={`${props.name}.body`}
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <div className="relative self-start text-muted-foreground">
                  <FormLabel className="flex items-center gap-1.5 text-sm font-bold">
                    <Icon className="size-3.5" />
                    {props.field.type_label}
                  </FormLabel>
                </div>
                <div className="mt-1 flex items-center gap-2">
                  <FormControl>
                    <InlineEdit
                      className="m-0 text-sm font-bold"
                      placeholder="Enter a name or model..."
                      {...field}
                    />
                  </FormControl>
                  {availableSubfields.length ? (
                    <PopoverWrapper
                      open={open}
                      setOpen={setOpen}
                      trigger={
                        <Button
                          disabled={props.fieldCount >= DETAIL_LIMIT}
                          variant="outline"
                          size="icon"
                          className="size-8 shrink-0">
                          <ListPlus className="size-4" />
                        </Button>
                      }
                      className={cn("p-0", isMobile ? "h-auto" : "max-w-[200px]")}>
                      <Command shouldFilter={false}>
                        <CommandList className={cn(isMobile && "max-h-full overflow-y-auto")}>
                          <CommandEmpty>No details found.</CommandEmpty>
                          <CommandGroup>
                            {availableSubfields.map((field) => {
                              const Icon = FieldTypeIcon.get(field.type) ?? Sparkles;

                              return (
                                <CommandItem
                                  key={field.type}
                                  value={field.type_label}
                                  keywords={field.aliases}
                                  onSelect={() => {
                                    setOpen(false);

                                    const orderMap = fromPairs(
                                      map(props.fieldDefinition?.fields, (x, index) => [
                                        x.type,
                                        index,
                                      ]),
                                    );

                                    const index = findIndex(
                                      subfields,
                                      (x) => orderMap[x.type] > orderMap[field.type],
                                    );

                                    setTimeout(
                                      () =>
                                        insert(index === -1 ? subfields.length : index, {
                                          id: uuid(),
                                          type: field.type,
                                          type_label: field.type_label,
                                          body: "",
                                          product_link: "",
                                        }),
                                      // timeout to allow the field to be auto focsued after the drawer has closed
                                      isMobile ? 400 : 0,
                                    );
                                  }}
                                  className={cn(isMobile && "py-2 text-base")}>
                                  <div className="flex items-center gap-1.5">
                                    <Icon className="size-3.5" />
                                    {field.type_label}
                                  </div>
                                </CommandItem>
                              );
                            })}
                          </CommandGroup>
                        </CommandList>
                      </Command>
                    </PopoverWrapper>
                  ) : null}
                  <Button
                    variant="outline"
                    size="icon"
                    className="size-8 shrink-0"
                    onClick={async () => {
                      if (subfields.length) {
                        const choice = await confirm({
                          title: "Delete detail?",
                          description:
                            "Are you sure? Deleting this detail will delete all sub-details attached to it.",
                          actionText: "Delete",
                        });
                        if (!choice) return;
                      }
                      props.onRemove();
                    }}>
                    <X className="size-4" />
                  </Button>
                </div>
                <FormMessage />
              </FormItem>
            )}
          />
          <EditProductLink name={`${props.name}.product_link`} />
        </>
      ) : (
        <>
          <div className="flex items-center justify-between">
            <div className="relative flex items-center gap-1.5 text-sm font-bold text-muted-foreground">
              <Icon className="size-3.5" />
              {props.field.type_label}
            </div>
            {props.field.product_link && (
              <a
                href={props.field.product_link}
                target="_blank"
                rel="noopener noreferrer"
                className={cn(
                  buttonVariants({ variant: "link" }),
                  "h-auto p-0 text-sm text-muted-foreground hover:text-primary",
                )}>
                Buy now <ExternalLink className="ml-1.5 size-3.5" />
              </a>
            )}
          </div>
          <div className="break-words text-sm font-bold">{props.field.body}</div>
        </>
      )}

      {subfields.length ? (
        <div className="mt-1.5 flex flex-col gap-1.5 border-l-2 pl-3">
          {subfields.map((field, index) => (
            <Subfield
              key={field.id}
              name={`${props.name}.fields.${index}`}
              field={field}
              onRemove={() => {
                remove(index);
              }}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};
