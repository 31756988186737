import React, { useMemo, useState } from "react";
import { Avatar } from "@/Components/Atoms/Avatar";
import { VerifiedBadge } from "@/Components/Atoms/VerifiedBadge";
import { Image } from "@/Components/Atoms/Image";
import { SocialIcon } from "react-social-icons";
import { SocialPlatformBackgroundStyle, SocialPlatformNetwork } from "@/API/Maps/SocialPlatform";
import { getOrFail } from "@/Utils/getOrFail";
import { cn } from "@/Utils/shadcn";
import { Button } from "@/Components/ui/button";
import { SocialPlatform } from "@/Types/generated_enums";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/Components/ui/dialog";
import { useWindowSizeThreshold } from "@/Hooks/useWindowSizeThreshold";
import { Breakpoint } from "@/Types/Enums/Breakpoint";
import { Link, usePage } from "@inertiajs/react";
import { route } from "ziggy-js";
import { BattlestationProps } from "@/Pages/Battlestation/Battlestation";
import { NamedTransformation } from "@/API/NamedTransformation";

export const BattlestationUserBanner = () => {
  const { props } = usePage<BattlestationProps>();

  const [aboutOpen, setAboutOpen] = useState(false);

  const isTablet = useWindowSizeThreshold(Breakpoint.LG);

  const socialLinks = useMemo(
    () =>
      isTablet
        ? props.battlestation.creator!.social_links!.slice(0, 3)
        : props.battlestation.creator!.social_links!,
    [props.battlestation.creator, isTablet],
  );

  if (!props.shouldDisplayUserBanner) {
    return null;
  }

  return (
    <>
      <Dialog open={aboutOpen} onOpenChange={setAboutOpen}>
        <DialogContent className="max-w-sm">
          <DialogHeader>
            <DialogTitle>About</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col gap-3 overflow-hidden">
            {props.battlestation.creator!.about && (
              <div className="overflow-hidden whitespace-pre-line break-words text-sm">
                {props.battlestation.creator!.about}
              </div>
            )}
            {props.battlestation.creator!.social_links && (
              <div className="flex flex-wrap items-center gap-2">
                {props.battlestation.creator!.social_links!.map((socialLink) => (
                  <div
                    key={socialLink.social_platform}
                    className={cn(
                      "flex size-9 items-center overflow-hidden rounded-full shadow-md transition-all hover:-translate-y-px active:scale-95",
                      getOrFail(SocialPlatformBackgroundStyle, socialLink.social_platform),
                    )}>
                    <SocialIcon
                      network={getOrFail(SocialPlatformNetwork, socialLink.social_platform)}
                      url={socialLink.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      bgColor="rgba(0,0,0,0)"
                      className={cn(
                        "!size-full rounded-full",
                        socialLink.social_platform === SocialPlatform.Patreon && "!h-7",
                      )}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
      {/* Desktop banner */}
      <div className="relative hidden h-32 items-center justify-between overflow-hidden rounded-3xl border p-5 shadow-md md:flex">
        {/* Banner image */}
        <div className="absolute inset-y-0 right-0 w-4/6 lg:w-3/4">
          <Image
            image={props.battlestation.creator!.banner_image}
            transform={{
              named: NamedTransformation.BannerImage,
            }}
            style={{
              maskImage: `linear-gradient(to right, transparent, black ${isTablet ? "50" : "25"}%)`,
            }}
            className="size-full object-cover"
          />
          <div className="absolute bottom-3 right-3 flex items-center gap-2">
            {socialLinks.map((socialLink) => (
              <div
                key={socialLink.social_platform}
                className={cn(
                  "flex size-9 items-center overflow-hidden rounded-full shadow-md transition-all hover:-translate-y-px active:scale-95",
                  getOrFail(SocialPlatformBackgroundStyle, socialLink.social_platform),
                )}>
                <SocialIcon
                  network={getOrFail(SocialPlatformNetwork, socialLink.social_platform)}
                  url={socialLink.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  bgColor="rgba(0,0,0,0)"
                  className={cn(
                    "!size-full",
                    socialLink.social_platform === SocialPlatform.Patreon && "!h-7",
                  )}
                />
              </div>
            ))}
            {isTablet && props.battlestation.creator!.social_links!.length > 3 && (
              <Button
                onClick={() => setAboutOpen(true)}
                variant="secondary"
                className="size-9 rounded-full border bg-background font-medium shadow-md hover:-translate-y-px hover:bg-background active:!scale-95">
                +{props.battlestation.creator!.social_links!.length - 3}
              </Button>
            )}
          </div>
        </div>
        {/* User details */}
        <div className="relative flex h-full items-center gap-6">
          <Link
            href={route("user.show", props.battlestation.creator!)}
            className="rounded-full transition-transform active:scale-[98%]">
            <Avatar
              image={props.battlestation.creator?.avatar}
              className="h-[95px] rounded-full shadow-sm ring-2 ring-indigo-200 ring-offset-4 ring-offset-background"
            />
          </Link>
          <div className="flex flex-col gap-1.5">
            <div className="mt-1 flex items-center gap-1.5">
              <Link href={route("user.show", props.battlestation.creator!)}>
                <h2 className="text-xl font-semibold leading-none tracking-tight">
                  {props.battlestation.creator?.username}
                </h2>
              </Link>
              <VerifiedBadge for={props.battlestation.creator!} className="size-5" />
            </div>
            {props.battlestation.creator!.about && (
              <div>
                <p
                  className="line-clamp-2 max-w-[16rem] cursor-pointer text-balance text-sm drop-shadow-md"
                  onClick={() => setAboutOpen(true)}>
                  {props.battlestation.creator!.about}
                </p>
                <Button
                  variant="link"
                  onClick={() => setAboutOpen(true)}
                  className="h-auto p-0 text-sm">
                  ...Show more
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Mobile banner */}
      <div className="flex flex-col gap-4 md:hidden">
        <Image
          image={props.battlestation.creator!.banner_image}
          transform={{
            named: NamedTransformation.BannerImage,
          }}
          className="aspect-[6/1] rounded-xl border object-cover sm:rounded-3xl"
        />
        <div className="flex items-center gap-4">
          <Link
            href={route("user.show", props.battlestation.creator!)}
            className="rounded-full transition-transform active:scale-[98%]">
            <Avatar
              image={props.battlestation.creator?.avatar}
              className="size-20 rounded-full shadow-sm"
            />
          </Link>
          <div className="flex flex-col gap-2.5">
            <div className="mt-1 flex items-center gap-1.5">
              <Link href={route("user.show", props.battlestation.creator!)}>
                <h2 className="text-xl font-semibold leading-none tracking-tight">
                  {props.battlestation.creator?.username}
                </h2>
              </Link>
              <VerifiedBadge for={props.battlestation.creator!} className="size-5" />
            </div>
            <div className="flex items-center gap-2">
              {socialLinks.map((socialLink) => (
                <div
                  key={socialLink.social_platform}
                  className={cn(
                    "flex size-8 items-center overflow-hidden rounded-full shadow-md transition-all active:scale-95",
                    getOrFail(SocialPlatformBackgroundStyle, socialLink.social_platform),
                  )}>
                  <SocialIcon
                    network={getOrFail(SocialPlatformNetwork, socialLink.social_platform)}
                    url={socialLink.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    bgColor="rgba(0,0,0,0)"
                    className={cn(
                      "!size-full",
                      socialLink.social_platform === SocialPlatform.Patreon && "!h-7",
                    )}
                  />
                </div>
              ))}
              <Button
                variant="link"
                onClick={() => setAboutOpen(true)}
                className="h-auto p-0 text-sm">
                ...Show more
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
