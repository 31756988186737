import { type VisitOptions } from "@inertiajs/core";

export const DEFAULT_FORM_OPTIONS: VisitOptions = {
  preserveScroll: (page) => !!Object.keys(page.props.errors).length,
  preserveState: (page) => !!Object.keys(page.props.errors).length,
};

export const DETAIL_LIMIT = 25;
export const IMAGE_LIMIT = 10;
export const TAG_LIMIT = 5;
