import React, { createContext, ReactNode, useCallback, useContext, useRef, useState } from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/Components/ui/alert-dialog";

export type ConfirmDialogContextValue = (options: ConfirmDialogOptions) => Promise<boolean>;

export const ConfirmDialogContext = createContext<ConfirmDialogContextValue>(() =>
  Promise.resolve(false),
);

export type ConfirmDialogProviderProps = {
  children: ReactNode;
};

export type ConfirmDialogOptions = {
  title: string;
  description: string;
  cancelText?: string;
  actionText?: string;
};

export const ConfirmDialogProvider = (props: ConfirmDialogProviderProps) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<ConfirmDialogOptions | null>(null);

  const fn = useRef<((choice: boolean) => void) | null>(null);

  const confirm = useCallback(
    (options: ConfirmDialogOptions) => {
      return new Promise<boolean>((resolve) => {
        setOptions(options);
        setOpen(true);

        fn.current = (choice) => {
          setOpen(false);
          resolve(choice);
        };
      });
    },
    [setOptions, setOpen],
  );

  return (
    <ConfirmDialogContext.Provider value={confirm}>
      {props.children}
      <AlertDialog
        open={open}
        onOpenChange={(open) => {
          if (!open && fn.current) {
            fn.current(false);
          }
        }}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{options?.title}</AlertDialogTitle>
            <AlertDialogDescription>{options?.description}</AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>{options?.cancelText ?? "Cancel"}</AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                fn.current?.(true);
              }}>
              {options?.actionText ?? "OK"}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </ConfirmDialogContext.Provider>
  );
};

export const useConfirm = () => useContext(ConfirmDialogContext);
