import React, { useMemo } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/Components/ui/dialog";
import { GradientScroll } from "@/Components/Atoms/GradientScroll";
import { cn } from "@/Utils/shadcn";
import { CardTypeColor, CardTypeIcon } from "@/API/Maps/CardType";
import { AddDetail } from "@/Components/Molecules/Battlestation/Widgets/CustomCardWidget/AddDetail";
import { Field } from "@/Components/Molecules/Battlestation/Widgets/CustomCardWidget/Field";
import { Ghost, Info, Sparkles } from "lucide-react";
import { Dictionary } from "lodash";
import { useFormErrors } from "@/Hooks/useFormErrors";
import { BattlestationForm } from "@/API/Forms/BattlestationForm";
import { FormErrorsAlert } from "@/Components/Molecules/FormErrorsAlert";
import { useBattlestationStore } from "@/Stores/useBattlestationStore";

export type CustomCardDialogProps = {
  name: `widgets.${number}.card`;
  card: App.Data.Models.CardData;
  fields: App.Data.Models.FieldData[];
  availableFields: App.Data.FieldDefinitionData[];
  fieldDefinitionByType: Dictionary<App.Data.FieldDefinitionData>;
  fieldCount: number;
  showErrors: boolean;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onAddField: (field: App.Data.Models.FieldData) => void;
  onRemoveField: (index: number) => void;
};

export const CustomCardDialog = (props: CustomCardDialogProps) => {
  const editing = useBattlestationStore((state) => state.editing);

  const GlowDialogTop = () =>
    // <div className="pointer-events-none fixed inset-0 opacity-30 blur-2xl">
    //   ...etc
    // </div>
    null;

  const GlowDialogBottom = () =>
    // <div className="pointer-events-none fixed inset-0 opacity-30 blur-2xl">
    //   ...etc
    // </div>
    null;

  const errors = useFormErrors<BattlestationForm>([`${props.name}.**`]);

  const Icon = CardTypeIcon.get(props.card.type) ?? Sparkles;

  const shouldShowDisclaimer = useMemo(
    () =>
      props.fields.some(
        (field) => !!field.product_link || field.fields.some((subfield) => !!subfield.product_link),
      ),
    [props.fields],
  );

  const addDetail = editing ? (
    <AddDetail
      card={props.card}
      fields={props.fields}
      availableFields={props.availableFields}
      fieldCount={props.fieldCount}
      onAdd={props.onAddField}
    />
  ) : null;

  return (
    <Dialog open={props.open} onOpenChange={props.onOpenChange}>
      <DialogContent
        closeClassName="top-6 right-6"
        className="aspect-square max-h-[min(calc(100svh-2rem),420px)] max-w-[min(calc(100svw-2rem),420px)] overflow-hidden !rounded-3xl p-0">
        <GlowDialogTop />
        <GlowDialogBottom />
        <GradientScroll className="flex flex-col gap-4 overflow-y-auto p-6">
          <DialogHeader>
            <DialogTitle className="flex items-center gap-3.5 text-xl font-bold">
              <div
                className={cn(
                  "flex size-12 items-center justify-center rounded-xl shadow-md",
                  CardTypeColor.get(props.card.type),
                )}>
                {Icon && <Icon className="size-7" />}
              </div>
              {props.card.type_label}
            </DialogTitle>
          </DialogHeader>
          {props.showErrors && errors.length ? <FormErrorsAlert errors={errors} /> : null}
          {props.fields.length ? addDetail : null}

          {props.fields.length ? (
            <div className="flex flex-col gap-2">
              {!editing && shouldShowDisclaimer && (
                <div className="flex items-center gap-1 text-xs text-muted-foreground">
                  <Info className="size-3.5" />
                  Commissions may be earned from the links below.
                </div>
              )}
              {props.fields.map((field, index) => (
                <Field
                  key={field.id}
                  name={`${props.name}.fields.${index}`}
                  field={field}
                  fieldDefinition={props.fieldDefinitionByType[field.type]}
                  fieldCount={props.fieldCount}
                  showErrors={props.showErrors}
                  onRemove={() => props.onRemoveField(index)}
                />
              ))}
            </div>
          ) : (
            <div className="flex w-full flex-1 flex-col items-center justify-center gap-6">
              <div className="rounded-full bg-muted p-5 text-muted-foreground">
                <Ghost className="size-10" />
              </div>
              <div className="flex flex-col items-center gap-1">
                <h2 className="text-2xl font-bold tracking-tight">Your gear goes here.</h2>
                <p className="text-center text-sm text-muted-foreground">
                  Add your first detail to get started!
                </p>
              </div>
              {addDetail}
            </div>
          )}
        </GradientScroll>
      </DialogContent>
    </Dialog>
  );
};
