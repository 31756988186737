import React, { ReactNode, useState } from "react";
import { Head } from "@/Components/Atoms/Head";
import { cn } from "@/Utils/shadcn";
import { Button } from "@/Components/ui/button";
import { Container } from "@/Components/Atoms/Container";
import { FieldValues, FormProvider, SubmitHandler, UseFormReturn } from "react-hook-form";

export type CreateLayoutProps<TFieldValues extends FieldValues> = {
  step: number;
  children: ReactNode;
  dirty: boolean;
  onNext: () => Promise<void>;
  onBack: () => void;
  form: UseFormReturn<TFieldValues>;
};

const TOTAL_PAGES = 5;

export const CreateLayout = <TFieldValues extends FieldValues>(
  props: CreateLayoutProps<TFieldValues>,
) => {
  const [loading, setLoading] = useState(false);

  return (
    <FormProvider {...props.form}>
      <form
        className="flex grow flex-col"
        onSubmit={async (e) => {
          e.preventDefault();

          setLoading(true);

          try {
            await props.onNext();
          } catch {}

          setLoading(false);
        }}>
        <Container className="flex grow flex-col items-center justify-between py-4 sm:py-6">
          <Head title="Create setup" />
          {/* content */}
          <div className="flex max-w-xl grow flex-col items-center justify-center">
            {props.children}
          </div>
          {/* toolbar */}
          <div className="flex w-full items-center justify-between">
            {/* spacer */}
            <div className="hidden flex-1 sm:block"></div>
            <div className="flex flex-1 items-center gap-1.5 sm:justify-center sm:gap-2">
              {[...Array(TOTAL_PAGES)].map((_, index) => (
                <div
                  key={index + 1}
                  className={cn(
                    "size-1.5 rounded-full bg-secondary sm:size-2",
                    index + 1 === props.step && "bg-primary",
                  )}
                />
              ))}
            </div>
            <div className="flex flex-1 items-center justify-end gap-2">
              <Button
                type="button"
                variant="secondary"
                disabled={props.step === 1 || loading}
                onClick={props.onBack}>
                Back
              </Button>
              <Button loading={loading}>
                {props.step === TOTAL_PAGES ? "Create" : props.dirty ? "Next" : "Skip"}
              </Button>
            </div>
          </div>
        </Container>
      </form>
    </FormProvider>
  );
};
