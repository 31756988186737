import React, { ReactNode } from "react";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/Components/ui/card";

export type AuthenticationLayoutProps = {
  heading?: ReactNode;
  subheading?: ReactNode;
  children: ReactNode;
};

export const AuthenticationLayout = (props: AuthenticationLayoutProps) => {
  return (
    <div className="py-2 sm:py-12">
      <Card className="mx-auto w-full rounded-none border-0 sm:max-w-sm sm:rounded-xl sm:border">
        <CardHeader>
          <CardTitle className="text-2xl">{props.heading}</CardTitle>
          <CardDescription>{props.subheading}</CardDescription>
        </CardHeader>
        <CardContent>{props.children}</CardContent>
      </Card>
    </div>
  );
};

export default AuthenticationLayout;
