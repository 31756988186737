import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { PaginatedCollection } from "@/API/PaginatedCollection";

type UsePaginatedReturn<T extends object> = {
  items: PaginatedCollection<T> | undefined;
  setItems: Dispatch<SetStateAction<PaginatedCollection<T> | undefined>>;
  setPage: Dispatch<SetStateAction<number>>;
  isLoading: boolean;
  endReached: boolean;
};

export const usePaginated = <T extends object>(
  route: (page: number) => string,
): UsePaginatedReturn<T> => {
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState<PaginatedCollection<T>>();
  const [page, setPage] = useState(1);

  const endReached = useMemo(() => items?.data.length! >= items?.meta.total!, [items, page]);

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      if (items?.meta.current_page === page || isLoading) return;

      setIsLoading(true);

      const response = await axios
        .get<PaginatedCollection<T>>(route(page), { signal: abortController.signal })
        .catch((error) => {
          if (axios.isCancel(error)) return;
          throw error;
        })
        .finally(() => {
          setIsLoading(false);
        });

      if (response) {
        setItems((items) => ({
          ...response.data,
          data: [...(items?.data ?? []), ...response.data.data],
        }));
      }

      return () => {
        abortController.abort();
        setIsLoading(false);
      };
    })();

    return () => {
      abortController.abort();
      setIsLoading(false);
    };
  }, [items, page]);

  return {
    items,
    setItems,
    setPage,
    isLoading: isLoading || items == null,
    endReached,
  };
};
