import { Environment } from "@/API/Enums/Environment";
import ImageKit from "imagekit-javascript";

const ENDPOINT_BASE = "https://ik.imagekit.io/rigrgg/";

export const EnvironmentEndpoint = new Map<string, string>([
  [Environment.Development, `${ENDPOINT_BASE}/development`],
  [Environment.Staging, `${ENDPOINT_BASE}/staging`],
  [Environment.Production, `${ENDPOINT_BASE}/production`],
]);

export const imageKit = new ImageKit({
  urlEndpoint: EnvironmentEndpoint.get(import.meta.env.VITE_APP_ENV)!,
});
