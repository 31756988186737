import React, { memo } from "react";
import { asset } from "laravel-vapor";

export type GlowProps = {
  width: number;
  typeLabel: string;
};

export const Glow = memo((props: GlowProps) => {
  const src =
    "assets/images/widget-glows/" +
    props.typeLabel.toLowerCase().replace(" ", "-") +
    "-glow-" +
    (props.width === 1 ? "small" : "medium") +
    ".png";

  return (
    <img alt="" src={asset(src)} className="pointer-events-none absolute bottom-0 size-full" />
  );
});
