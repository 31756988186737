import React from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/Components/ui/dialog";
import { Image } from "@/Components/Atoms/Image";
import { usePage } from "@inertiajs/react";
import { BattlestationProps } from "@/Pages/Battlestation/Battlestation";
import { Button, buttonVariants } from "@/Components/ui/button";
import { Copy, ExternalLink, Info } from "lucide-react";
import { toast } from "sonner";
import { cn } from "@/Utils/shadcn";
import { GradientScroll } from "@/Components/Atoms/GradientScroll";
import { img } from "@/Utils/img";
import { NamedTransformation } from "@/API/NamedTransformation";

export type SpotlightViewDialogProps = {
  spotlight: App.Data.Models.SpotlightData;
  aspect: number;
  open: boolean;
  onOpenChange: (open: boolean) => void;
};

export const SpotlightViewDialog = (props: SpotlightViewDialogProps) => {
  const {
    props: {
      battlestation: { creator },
    },
  } = usePage<BattlestationProps>();

  return (
    <Dialog open={props.open} onOpenChange={props.onOpenChange}>
      <DialogContent
        onOpenAutoFocus={(event) => event.preventDefault()}
        closeClassName="top-6 right-6"
        className="flex size-full max-h-[min(calc(100svh-2rem),420px)] max-w-[min(calc(100svw-2rem),420px)] flex-col gap-0 overflow-hidden !rounded-3xl p-0">
        <GradientScroll className="flex flex-1 flex-col gap-4 overflow-y-auto p-6">
          <DialogHeader>
            <DialogTitle className="text-xl font-bold">{props.spotlight.name}</DialogTitle>
          </DialogHeader>
          <div className="mt-1 flex flex-col gap-4">
            {/* image */}
            <div className="relative h-44 overflow-hidden rounded-2xl border bg-card shadow-sm">
              <div
                style={{
                  backgroundImage: `url(${img(props.spotlight.image, { named: NamedTransformation.ImageThumbnail })})`,
                  maskImage: `linear-gradient(to right, transparent, black 30%, black 70%, transparent)`,
                }}
                className="absolute inset-0 bg-cover bg-center bg-no-repeat blur-md brightness-50"
              />
              <Image
                image={props.spotlight.image}
                transform={{
                  named: NamedTransformation.ImageThumbnail,
                  aspectRatio: `${props.aspect}-1`,
                }}
                className="relative flex size-full items-center justify-center object-contain"
              />
            </div>
            {/* description */}
            {props.spotlight.description && (
              <p className="text-sm text-muted-foreground">{props.spotlight.description}</p>
            )}
          </div>
        </GradientScroll>
        <div className="flex flex-col gap-2 border-t bg-background px-6 py-3.5">
          <div className="flex items-center gap-1 text-xs text-muted-foreground">
            <Info className="size-3.5" />
            Commissions may be earned from the link below.
          </div>
          <div className="flex flex-col items-center gap-2 sm:flex-row">
            {/* discount code */}
            {props.spotlight.discount_code && (
              <div className="flex w-full overflow-hidden rounded-md bg-card">
                <div className="flex w-full items-center overflow-hidden px-4 text-sm italic">
                  <span className="w-full min-w-0 truncate text-center">
                    Use code <span className="font-bold">{props.spotlight.discount_code}</span>
                  </span>
                </div>
                <Button
                  variant="secondary"
                  size="icon"
                  className="shrink-0 rounded-l-none"
                  onClick={() => {
                    navigator.clipboard.writeText(props.spotlight.discount_code);
                    toast.success("Copied to clipboard.");
                  }}>
                  <Copy className="size-4" />
                </Button>
              </div>
            )}
            {/* product link */}
            <a
              href={props.spotlight.product_link}
              target="_blank"
              rel="noopener noreferrer"
              className={cn(
                buttonVariants({ variant: "accent" }),
                "w-full",
                props.spotlight.discount_code && "sm:w-auto",
              )}>
              Buy now <ExternalLink className="-mr-0.5 ml-1.5 size-4" />
            </a>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
