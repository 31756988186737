import React, { Dispatch, SetStateAction } from "react";
import {
  createOnSubmitHandler,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/Components/ui/form";
import { CreateLayout } from "@/Layouts/CreateLayout";
import { useFormContext, useFormState } from "react-hook-form";
import { CreateForm } from "@/API/Forms/CreateForm";
import { Input } from "@/Components/ui/input";
import { route } from "ziggy-js";
import { pipe } from "@/Utils/pipe";
import { pickArray } from "@/Utils/pick";
import { purgeUUIDs } from "@/Utils/purgeUUIDs";
import { toast } from "sonner";
import { entries } from "lodash";

export type StepFiveProps = {
  setStep: Dispatch<SetStateAction<number>>;
};

const StepFive = (props: StepFiveProps) => {
  const form = useFormContext<CreateForm>();

  const state = useFormState({
    control: form.control,
    name: "stepFive.name",
    exact: true,
  });

  return (
    <CreateLayout
      step={5}
      form={form}
      dirty={state.isDirty}
      onNext={async () => {
        let isValid = await form.trigger("stepFive.name");
        if (!isValid) return;

        await form.handleSubmit(
          createOnSubmitHandler(form, route("battlestation.store"), {
            method: "post",
            transform: (data) => ({
              name: data.stepFive.name,
              about: data.stepFour.about,
              tags: pickArray(data.stepThree.tags, ["id", "name"]),
              images: pipe(data.stepTwo.images)
                .through(pickArray, ["id", "key", "name", "crop", "upload_response"])
                .through(purgeUUIDs).value,
              details: data.stepOne.details,
            }),
            onError: (errors) => {
              toast.error(
                <div>
                  <div>Please fix the following issues:</div>
                  <ul>
                    {entries(errors).map(([key, value]) => (
                      <li key={key} className="list-inside list-disc">
                        {value}
                      </li>
                    ))}
                  </ul>
                </div>,
              );
            },
          }),
        )();
      }}
      onBack={() => {
        props.setStep((step) => step - 1);
      }}>
      <div className="flex flex-col items-center gap-6">
        <div className="flex flex-col items-center gap-2">
          <h2 className="text-2xl font-bold leading-tight tracking-tight">
            Add a name <span className="text-red-500">*</span>
          </h2>
          <p className="text-balance text-center text-sm text-muted-foreground">
            Think of something short and personal that describes your setup. This can be changed any
            time you like.
          </p>
        </div>
        <FormField
          control={form.control}
          name="stepFive.name"
          render={({ field }) => (
            <FormItem className="flex w-full flex-col items-center">
              <FormControl>
                <Input
                  placeholder="Give your setup a name..."
                  className="max-w-sm"
                  autoComplete="off"
                  {...field}
                />
              </FormControl>
              <FormMessage className="text-center" />
            </FormItem>
          )}
        />
      </div>
    </CreateLayout>
  );
};

export default StepFive;
