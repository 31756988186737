import React, { useMemo, useRef, useState } from "react";
import { BattlestationTags } from "@/Components/Organisms/Battlestation/BattlestationTags";
import { cn } from "@/Utils/shadcn";
import { useWindowSize } from "@/Hooks/useWindowSize";
import { GradientScroll } from "@/Components/Atoms/GradientScroll";
import { BattlestationActions } from "@/Components/Organisms/Battlestation/BattlestationActions";
import { InlineTextarea } from "@/Components/Atoms/InlineTextarea";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/Components/ui/form";
import { Avatar } from "@/Components/Atoms/Avatar";
import { Link, usePage } from "@inertiajs/react";
import { route } from "ziggy-js";
import { Points } from "@/Components/Atoms/Points";
import { useFormContext } from "react-hook-form";
import { BattlestationForm } from "@/API/Forms/BattlestationForm";
import type { BattlestationProps } from "@/Pages/Battlestation/Battlestation";
import { BattlestationImages } from "@/Components/Organisms/Battlestation/BattlestationImages";
import { useBattlestationStore } from "@/Stores/useBattlestationStore";
import { VerifiedBadge } from "@/Components/Atoms/VerifiedBadge";

export const BattlestationDetails = () => {
  const { props } = usePage<BattlestationProps>();

  const { width } = useWindowSize();

  const form = useFormContext<BattlestationForm>();

  const editing = useBattlestationStore((state) => state.editing);

  const descriptionRef = useRef<HTMLParagraphElement>(null);

  const [showFullDescription, setShowFullDescription] = useState(false);

  const descriptionIsTruncated = useMemo(
    () =>
      showFullDescription ||
      (descriptionRef.current &&
        descriptionRef.current.scrollHeight > descriptionRef.current.clientHeight),
    [props.battlestation.about, descriptionRef.current, width],
  );

  const Title = editing ? (
    <FormField
      control={form!.control}
      name="name"
      render={({ field }) => (
        <FormItem>
          <FormControl>
            <InlineTextarea
              className="!text-xl font-semibold leading-tight tracking-tight"
              autoComplete="off"
              placeholder="My setup"
              {...field}
            />
          </FormControl>
          <FormMessage className="pt-1.5" />
        </FormItem>
      )}
    />
  ) : (
    <h2 className="line-clamp-2 text-ellipsis break-words text-xl font-semibold leading-tight tracking-tight">
      {props.battlestation.name}
    </h2>
  );

  const UserInfo = props.shouldDisplayUserBanner ? null : (
    <div className="flex items-center justify-start gap-2">
      <Link
        href={route("user.show", props.battlestation.creator!)}
        className="inline-flex rounded-full">
        <Avatar image={props.battlestation.creator?.avatar} className="size-8 rounded-full" />
      </Link>
      <div className="flex flex-col">
        <div className="flex items-center gap-1">
          <Link
            href={route("user.show", props.battlestation.creator!)}
            className="text-sm font-medium text-secondary-foreground">
            {props.battlestation.creator!.username}
          </Link>
          <VerifiedBadge for={props.battlestation.creator!} className="size-4" />
        </div>
        <span className="-mt-0.5 text-xs text-muted-foreground">
          <Points points={props.battlestation.creator!.points!} />
        </span>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col gap-4">
      <div className="flex h-[270px] overflow-hidden rounded-3xl border bg-card shadow-md sm:h-[500px]">
        {/* Carousel */}
        <div className="h-full w-full min-w-0 flex-1">
          <BattlestationImages />
        </div>
        {/* Details (desktop) */}
        <div className="hidden shrink-0 basis-5/12 flex-col justify-between overflow-hidden border-l p-4 md:flex lg:basis-4/12 lg:p-6">
          {/* Scrollable container */}
          <GradientScroll className="-mx-4 -mt-2 mb-2 flex grow flex-col gap-4 overflow-y-auto px-4 pt-2">
            <div className="flex flex-col gap-2.5">
              {Title}
              {UserInfo}
            </div>
            {/* Tags */}
            {props.battlestation.tags && <BattlestationTags />}
            {/* Description */}
            <div className="flex flex-col">
              {editing ? (
                <FormField
                  control={form!.control}
                  name="about"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>About</FormLabel>
                      <FormControl>
                        <InlineTextarea
                          className="resize-none text-sm text-secondary-foreground"
                          placeholder="Write something about your setup..."
                          minRows={4}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              ) : (
                <p
                  ref={descriptionRef}
                  className="overflow-hidden whitespace-pre-line break-words text-sm text-secondary-foreground">
                  {props.battlestation.about}
                </p>
              )}
            </div>
          </GradientScroll>
          {/*  Action buttons */}
          <BattlestationActions battlestation={props.battlestation} />
        </div>
      </div>
      {/* Details (mobile) */}
      <div className="flex flex-col gap-4 md:hidden">
        <div className="5 flex flex-col gap-2">
          {Title}
          {UserInfo}
        </div>
        {/* Tags */}
        {props.battlestation.tags && <BattlestationTags />}
        {/* Description */}
        <div className="flex flex-col">
          {editing ? (
            <FormField
              control={form!.control}
              name="about"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>About</FormLabel>
                  <FormControl>
                    <InlineTextarea
                      className="resize-none text-sm text-secondary-foreground"
                      placeholder="Write something about your setup..."
                      minRows={4}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          ) : (
            <>
              <p
                ref={descriptionRef}
                className={cn(
                  "text-ellipsis whitespace-pre-line break-words text-sm text-secondary-foreground",
                  showFullDescription || "line-clamp-3",
                )}>
                {props.battlestation.about}
              </p>
              {descriptionIsTruncated && (
                <div>
                  <button
                    type="button"
                    className="text-sm text-indigo-300 hover:text-indigo-200"
                    onClick={() => setShowFullDescription((value) => !value)}>
                    {showFullDescription ? "Show less" : "Show more"}
                  </button>
                </div>
              )}
            </>
          )}
        </div>
        <div className="flex flex-col gap-4 sm:flex-row sm:items-end">
          {/*  Action buttons */}
          <div className="flex-1">
            <BattlestationActions battlestation={props.battlestation} />
          </div>
        </div>
      </div>
    </div>
  );
};
