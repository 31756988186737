export type BreakpointMap = Map<number, string>;

export const getResponsiveClasses = (breakpoints: BreakpointMap, width: number): string => {
  let result = "";
  let maxBreakpoint = -1;

  for (const [breakpoint, classes] of breakpoints) {
    if (width >= breakpoint && breakpoint > maxBreakpoint) {
      result = classes;
      maxBreakpoint = breakpoint;
    }
  }

  return result;
};
