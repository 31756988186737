import React, { useMemo } from "react";
import { cn } from "@/Utils/shadcn";
import { DynamicImage, Image, ImageProps } from "@/Components/Atoms/Image";
import { NamedTransformation } from "@/API/NamedTransformation";

export type AvatarProps = Omit<ImageProps, "image"> & {
  image?: DynamicImage | null;
  username?: string;
};

export const Avatar = ({ image, username, className, ...props }: AvatarProps) => {
  const altText = useMemo(() => (username ? `${username}'s avatar` : "Avatar image"), [username]);

  if (!image) {
    return (
      <span
        className={cn("inline-block overflow-hidden rounded-md bg-gray-100", className)}
        aria-label={altText}>
        <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
        </svg>
      </span>
    );
  }

  return (
    <Image
      image={image}
      transform={{
        named: NamedTransformation.Avatar,
        ...props.transform,
      }}
      className={cn("rounded-md object-cover", className)}
      alt={altText}
      {...props}
    />
  );
};
