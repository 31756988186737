import React from "react";
import { Button } from "@/Components/ui/button";
import { ChevronUp, Pencil } from "lucide-react";
import { Collapsible, CollapsibleContent } from "@/Components/ui/collapsible";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/Components/ui/tooltip";
import { useConfirm } from "@/Contexts/ConfirmDialogContext";
import { usePage } from "@inertiajs/react";
import { BattlestationProps } from "@/Pages/Battlestation/Battlestation";
import { useIsMobile } from "@/Hooks/useIsMobile";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from "@/Components/ui/dropdown-menu";
import { useWidgetsStore } from "@/Stores/useWidgetsStore";
import { useBattlestationStore } from "@/Stores/useBattlestationStore";

export type BattlestationToolbarProps = {
  rememberKey?: string;
  onSave: () => void;
  onPublish: () => void;
};

export const BattlestationToolbar = (props: BattlestationToolbarProps) => {
  const page = usePage<BattlestationProps>();

  const editing = useBattlestationStore((state) => state.editing);
  const setEditing = useBattlestationStore((state) => state.setEditing);

  const reset = useWidgetsStore((state) => state.reset);

  const confirm = useConfirm();

  const isMobile = useIsMobile();

  return (
    <Collapsible
      open={editing}
      className="pointer-events-auto fixed bottom-4 right-4 z-40 flex h-12 items-center gap-2 xl:bottom-6 xl:right-6 xl:h-14">
      <CollapsibleContent className="duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-right-[5%] data-[state=open]:slide-in-from-right-[5%]">
        <div className="flex items-center gap-1 rounded-md border bg-background/80 p-1 backdrop-blur-sm">
          <Button
            type="button"
            variant="transparentGhost"
            onClick={async () => {
              const choice = await confirm({
                title: "Revert changes?",
                description:
                  "Are you sure you want to stop editing and revert all unsaved changes? This action cannot be undone.",
                cancelText: "Continue editing",
                actionText: "Revert changes",
              });

              if (!choice) return;

              reset();

              if (page.props.battlestation.published_at) {
                setEditing(false);
              }

              if (props.rememberKey) {
                localStorage.removeItem(props.rememberKey);
              }
            }}>
            Revert
          </Button>
          {page.props.battlestation.published_at === null ? (
            isMobile ? (
              <DropdownMenu modal>
                <DropdownMenuTrigger asChild>
                  <Button type="button">
                    Save
                    <ChevronUp className="-mr-1 ml-1 size-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem onClick={props.onSave}>Save Draft</DropdownMenuItem>
                  <DropdownMenuItem onClick={props.onPublish}>Save & Publish</DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            ) : (
              <>
                <Button variant="transparentGhost" type="button" onClick={props.onSave}>
                  Save Draft
                </Button>
                <Button type="button" onClick={props.onPublish}>
                  Save & Publish
                </Button>
              </>
            )
          ) : (
            <Button type="button" onClick={props.onPublish}>
              Save & Publish
            </Button>
          )}
        </div>
      </CollapsibleContent>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            type="button"
            variant="accent"
            size="icon"
            className="size-12 rounded-xl xl:size-14"
            disabled={!page.props.battlestation.published_at}
            onClick={() => setEditing(!editing)}>
            <Pencil className="size-5 xl:size-6" />
          </Button>
        </TooltipTrigger>
        <TooltipContent side="left" hidden={editing}>
          Make changes
        </TooltipContent>
      </Tooltip>
    </Collapsible>
  );
};
