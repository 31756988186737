import { FieldValues, useFormState } from "react-hook-form";
import { getFilteredErrors } from "@/Utils/getFilteredErrors";
import { entries } from "lodash";

export const useFormErrors = <TFieldValues extends FieldValues = FieldValues>(
  patterns: string[],
) => {
  const { errors } = useFormState<TFieldValues>();

  return entries(getFilteredErrors(errors, patterns));
};
