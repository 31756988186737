import React from "react";
import type { SVGProps } from "react";
const SvgNameDescription = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" viewBox="0 0 197 157" {...props}>
    <circle cx={114.5} cy={95.5} r={58.5} fill="#4F45E4" opacity={0.2} />
    <circle cx={160} cy={65} r={25} fill="url(#a)" fillOpacity={0.6} />
    <circle
      cx={12}
      cy={12}
      r={12}
      fill="url(#b)"
      opacity={0.4}
      transform="matrix(-1 0 0 1 169 122)"
    />
    <mask
      id="c"
      width={35}
      height={35}
      x={0}
      y={122}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}>
      <circle cx={17.5} cy={139.5} r={17.5} fill="#F3F4F6" />
    </mask>
    <g mask="url(#c)">
      <circle cx={16.5} cy={140.5} r={24.5} fill="#F3F4F6" />
      <circle cx={17.5} cy={158.5} r={17.5} fill="#D1D5DB" />
      <circle cx={17.5} cy={133.5} r={5.5} fill="#D1D5DB" />
    </g>
    <path
      fill="url(#d)"
      stroke="#374151"
      strokeWidth={2}
      d="M63.358 114.923h-.603l-.282.534c-2.786 5.293-5.239 8.773-8.358 11.134-2.702 2.046-5.983 3.31-10.591 4.097 1.05-1.798 1.77-3.526 2.178-5.476.542-2.595.515-5.509.078-9.401l-.1-.888H16.381C10.1 114.923 5 109.812 5 103.5V49.423C5 43.111 10.099 38 16.381 38h101.237C123.901 38 129 43.111 129 49.423V103.5c0 6.312-5.099 11.423-11.382 11.423z"
    />
    <path stroke="#F9FAFB" strokeLinecap="round" strokeWidth={5} d="M21 55h73" opacity={0.45} />
    <path stroke="#F9FAFB" strokeLinecap="round" strokeWidth={5} d="M21 70h61" opacity={0.4} />
    <path stroke="#F9FAFB" strokeLinecap="round" strokeWidth={5} d="M21 86h54" opacity={0.35} />
    <path stroke="#F9FAFB" strokeLinecap="round" strokeWidth={5} d="M21 101h69" opacity={0.3} />
    <g strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} clipPath="url(#e)">
      <path
        stroke="#fff"
        d="M17.66 33.662a2.27 2.27 0 0 0-1.844-1.377L8.68 31.51a.567.567 0 0 1-.156-1.078l6.62-2.773a2.27 2.27 0 0 0 1.376-1.843l.775-7.134a.566.566 0 0 1 1.08-.157l2.77 6.62a2.26 2.26 0 0 0 1.845 1.377l7.134.774a.567.567 0 0 1 .156 1.08l-6.62 2.77a2.27 2.27 0 0 0-1.376 1.845l-.775 7.134a.566.566 0 0 1-1.08.157z"
      />
      <path
        stroke="#4F45E4"
        d="m26.906 18.014.65 4.483M29.473 19.93l-4.483.651M11.25 36.31l.325 2.24M12.533 37.267l-2.242.326"
      />
    </g>
    <g strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} clipPath="url(#f)">
      <path
        stroke="#868992"
        d="M40.096 38.264a1.46 1.46 0 0 0-.879-1.194l-4.25-1.813a.365.365 0 0 1 .106-.693l4.596-.47a1.46 1.46 0 0 0 1.194-.877l1.813-4.25a.365.365 0 0 1 .694.106l.468 4.596a1.46 1.46 0 0 0 .878 1.194l4.25 1.812a.365.365 0 0 1-.106.695l-4.596.467a1.46 1.46 0 0 0-1.194.879l-1.813 4.25a.364.364 0 0 1-.694-.106z"
      />
      <path
        stroke="#0D9488"
        d="m48.72 30.355-.438 2.884M49.943 32.016l-2.884-.438M35.651 38.694l-.22 1.442M36.262 39.525l-1.441-.22"
      />
    </g>
    <rect
      width={139}
      height={26}
      x={57}
      y={1}
      fill="url(#g)"
      stroke="#374151"
      strokeWidth={2}
      rx={8}
    />
    <path stroke="#F9FAFB" strokeLinecap="round" strokeWidth={6} d="M90 14h69" opacity={0.3} />
    <circle cx={74} cy={14} r={6} fill="#F9FAFB" opacity={0.3} />
    <defs>
      <linearGradient id="a" x1={160} x2={160} y1={40} y2={90} gradientUnits="userSpaceOnUse">
        <stop stopColor="#9A45E4" />
        <stop offset={1} stopColor="#55267E" />
      </linearGradient>
      <linearGradient id="b" x1={12} x2={12} y1={0} y2={24} gradientUnits="userSpaceOnUse">
        <stop stopColor="#0D9488" stopOpacity={0.75} />
        <stop offset={1} stopColor="#042E2A" stopOpacity={0.75} />
      </linearGradient>
      <linearGradient
        id="d"
        x1={105.965}
        x2={31.79}
        y1={57.096}
        y2={131.751}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#111827" />
        <stop offset={1} stopColor="#131832" />
      </linearGradient>
      <linearGradient
        id="g"
        x1={132.008}
        x2={128.278}
        y1={0}
        y2={30.476}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#111827" />
        <stop offset={1} stopColor="#131832" />
      </linearGradient>
      <clipPath id="e">
        <path fill="#fff" d="M4 17.905 30.901 14l3.905 26.901-26.901 3.905z" />
      </clipPath>
      <clipPath id="f">
        <path fill="#fff" d="m34.63 26 17.303 2.63-2.63 17.302L32 43.302z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgNameDescription;
