import React, { createContext, PropsWithChildren, useContext, useRef } from "react";
import { createStore, useStore } from "zustand";
import axios from "axios";
import { route } from "ziggy-js";
import { create } from "mutative";
import { CommentForm } from "@/API/Forms/CommentForm";
import { PaginatedCollection } from "@/API/PaginatedCollection";
import { CommentSorting } from "@/Types/generated_enums";

type State = {
  battlestation: App.Data.Models.BattlestationData | null;
  comments: App.Data.Models.CommentData[];
  commentsPage: number;
  commentsCount: number;
  focusedCommentId: number;
  parentCommentId: number;
  commentsSorting: App.Enums.CommentSorting;
  hasMore: boolean;
  loading: boolean;
  disabled: boolean;
  open: boolean;
};

type Actions = {
  loadMoreComments: () => Promise<void>;
  loadMoreReplies: (rootId: number) => Promise<void>;
  addComment: (data: CommentForm) => Promise<void>;
  addReply: (data: CommentForm, rootId: number) => Promise<void>;
  toggleLiked: (rootId: number, replyId?: number) => void;
  editComment: (data: CommentForm, rootId: number, replyId?: number) => Promise<void>;
  deleteComment: (rootId: number, replyId?: number) => Promise<void>;
  setSorting: (sorting: App.Enums.CommentSorting) => Promise<void>;
  setOpen: (open: boolean) => void;
};

const defaultState: State = {
  battlestation: null,
  comments: [],
  commentsPage: 1,
  commentsCount: 0,
  focusedCommentId: -1,
  parentCommentId: -1,
  commentsSorting: CommentSorting.Popular, // App.Enums.CommentSorting.Popular,
  hasMore: true,
  loading: false,
  disabled: false,
  open: false,
};

type CommentsStore = ReturnType<typeof createCommentsStore>;

const updateCommentOrReply = (
  comments: Array<App.Data.Models.CommentData>,
  recipe: (draft: App.Data.Models.CommentData) => void,
  rootId: number,
  replyId?: number,
): Array<App.Data.Models.CommentData> => {
  return create(comments, (draft) => {
    const rootIdx = draft.findIndex((comment) => comment.id === rootId);

    if (replyId) {
      const replyIdx = draft[rootIdx].replies!.data.findIndex((comment) => comment.id === replyId);
      recipe(draft[rootIdx].replies!.data[replyIdx]);
    } else {
      recipe(draft[rootIdx]);
    }
  });
};

const createCommentsStore = (initialState?: Partial<State>) =>
  createStore<State & Actions>((set, get) => ({
    ...defaultState,
    ...initialState,

    loadMoreComments: async () => {
      const {
        battlestation,
        commentsPage,
        commentsSorting,
        hasMore,
        focusedCommentId,
        parentCommentId,
      } = get();

      if (!battlestation || !hasMore) return;

      set({ loading: true });

      try {
        const response = await axios
          .get<PaginatedCollection<App.Data.Models.CommentData>>(
            route("battlestation.comments.index", {
              battlestation,
              page: commentsPage,
              _query: {
                commentSorting: commentsSorting,
                ...(focusedCommentId && { commentId: focusedCommentId }),
                ...(parentCommentId && { parentCommentId }),
              },
            }),
          )
          .catch((error) => {
            console.error("Error fetching comments");
            throw error;
          });

        if (!response) return;

        set((state) => {
          const existingIds = new Set(state.comments.map((x) => x.id));

          return {
            comments: [
              ...state.comments,
              ...response.data.data.filter((comment) => !existingIds.has(comment.id)),
            ],
            commentsPage: state.commentsPage + 1,
            loading: false,
            hasMore: response.data.meta.current_page < response.data.meta.last_page,
          };
        });
      } catch (error) {
        set({ loading: false });
        throw error;
      }
    },

    loadMoreReplies: async (rootId) => {
      const { comments } = get();

      const index = comments.findIndex((comment) => comment.id === rootId);
      const comment = comments[index];
      const page = comment.replies_preloaded ? 1 : (comment.replies?.meta.current_page ?? 0) + 1;

      try {
        const response = await axios.get<NonNullable<App.Data.Models.CommentData["replies"]>>(
          route("comment.replies.index", {
            comment: rootId,
            page,
          }),
        );

        set((state) => {
          return {
            comments: create(state.comments, (draft) => {
              if (draft[index].replies && !draft[index].replies_preloaded) {
                const existingIds = new Set(draft[index].replies!.data.map((x) => x.id));
                draft[index].replies!.meta.current_page = response.data.meta.current_page;
                draft[index].replies!.data.push(
                  ...response.data.data.filter((reply) => !existingIds.has(reply.id)),
                );
              } else {
                draft[index].replies = response.data;
                draft[index].replies_preloaded = false;
              }
            }),
          };
        });
      } catch (error) {
        console.error("Error fetching replies");
        throw error;
      }
    },

    addComment: async (data) => {
      const { battlestation } = get();

      if (!battlestation) {
        console.error("Cannot add comment without a battlestation");
        return;
      }

      try {
        const response = await axios.post<App.Data.Models.CommentData>(
          route("battlestation.comments.store", {
            battlestation,
          }),
          data,
        );

        set((state) => ({
          commentsCount: state.commentsCount + 1,
          comments: create(state.comments, (draft) => {
            draft.unshift(response.data);
          }),
        }));
      } catch (error) {
        console.error("Error adding comment: ", error);
        throw error;
      }
    },

    addReply: async (data, rootId) => {
      const { comments, loadMoreReplies } = get();
      const index = comments.findIndex((comment) => comment.id === rootId);

      if (!comments[index].replies) {
        await loadMoreReplies(rootId);
      }

      try {
        const response = await axios.post<App.Data.Models.CommentData>(
          route("comment.replies.store", {
            comment: comments[index],
          }),
          data,
        );

        set((state) => ({
          commentsCount: state.commentsCount + 1,
          comments: create(state.comments, (draft) => {
            draft[index].replies!.data.unshift(response.data);
            draft[index].replies_count = (draft[index].replies_count ?? 0) + 1;
          }),
        }));
      } catch (error) {
        console.error("Error replying to comment: ", error);
        throw error;
      }
    },

    toggleLiked: (rootId, replyId) => {
      set((state) => ({
        comments: updateCommentOrReply(
          state.comments,
          (comment) => {
            comment.voted = !comment.voted;
            comment.points = comment.voted ? comment.points + 1 : comment.points - 1;
          },
          rootId,
          replyId,
        ),
      }));
    },

    editComment: async (data, rootId, replyId) => {
      const { comments } = get();
      const index = comments.findIndex((comment) => comment.id === rootId);

      let comment: App.Data.Models.CommentData;

      if (replyId) {
        const replyIndex = comments[index].replies!.data.findIndex(
          (comment) => comment.id === replyId,
        );
        comment = comments[index].replies!.data[replyIndex];
      } else {
        comment = comments[index];
      }

      if (!comment) {
        console.error("Cannot find comment to edit");
        return;
      }

      try {
        const response = await axios.put<App.Data.Models.CommentData>(
          route("comment.update", {
            comment,
          }),
          data,
        );

        set((state) => ({
          comments: updateCommentOrReply(
            state.comments,
            (comment) => {
              comment.body = response.data.body;
              comment.body_plaintext = response.data.body_plaintext;
            },
            rootId,
            replyId,
          ),
        }));
      } catch (error) {
        console.error("Error editing comment: ", error);
        throw error;
      }
    },

    deleteComment: async (rootId, replyId) => {
      const { comments } = get();
      const index = comments.findIndex((comment) => comment.id === rootId);

      if (replyId) {
        const replyIndex = comments[index].replies!.data.findIndex(
          (comment) => comment.id === replyId,
        );

        const reply = comments[index].replies!.data[replyIndex];

        try {
          await axios.delete<App.Data.Models.CommentData>(
            route("comment.destroy", {
              comment: reply,
            }),
          );
        } catch (error) {
          console.error("Error deleting comment: ", error);
          throw error;
        }

        const repliesCount = Math.max(comments[index].replies_count! - 1, 0);

        if (repliesCount === 0) {
          set((state) => ({
            comments: create(state.comments, (draft) => {
              draft.splice(index, 1);
            }),
          }));
        } else {
          set((state) => ({
            comments: create(state.comments, (draft) => {
              draft[index].replies_count = repliesCount;
              draft[index].replies!.data.splice(replyIndex, 1);
            }),
          }));
        }
      } else {
        const comment = comments[index];

        try {
          await axios.delete<App.Data.Models.CommentData>(
            route("comment.destroy", {
              comment,
            }),
          );
        } catch (error) {
          console.error("Error deleting comment: ", error);
          throw error;
        }

        const hasReplies = (comments[index].replies_count ?? 0) > 0;

        if (hasReplies) {
          set((state) => ({
            comments: create(state.comments, (draft) => {
              draft[index].body = "[deleted comment]";
              draft[index].deleted = true;
            }),
          }));
        } else {
          set((state) => ({
            comments: create(state.comments, (draft) => {
              draft.splice(index, 1);
            }),
          }));
        }
      }

      set((state) => ({
        commentsCount: state.commentsCount - 1,
      }));
    },

    setSorting: async (sorting) => {
      const { battlestation, commentsSorting } = get();
      if (!battlestation || sorting === commentsSorting) return;

      set({ commentsSorting: sorting, commentsPage: 1, disabled: true });

      try {
        const response = await axios
          .get<PaginatedCollection<App.Data.Models.CommentData>>(
            route("battlestation.comments.index", {
              battlestation,
              page: 1,
              _query: {
                commentSorting: sorting,
              },
            }),
          )
          .catch((error) => {
            console.error("Error fetching comments");
            throw error;
          });

        if (!response) return;

        set({
          comments: response.data.data,
          commentsPage: 2,
          disabled: false,
          hasMore: response.data.meta.current_page < response.data.meta.last_page,
        });
      } catch (error) {
        set({ disabled: false });
        throw error;
      }
    },

    setOpen: (open) => set({ open }),
  }));

const CommentsContext = createContext<CommentsStore | null>(null);

type CommentsProviderProps = PropsWithChildren<Partial<State>>;

export const CommentsProvider = ({ children, ...props }: CommentsProviderProps) => {
  const storeRef = useRef<CommentsStore>();

  if (!storeRef.current) {
    storeRef.current = createCommentsStore(props);
    storeRef.current.getState().loadMoreComments();
  }

  return <CommentsContext.Provider value={storeRef.current}>{children}</CommentsContext.Provider>;
};

export const useCommentsContext = <T extends any>(selector: (state: State & Actions) => T): T => {
  const store = useContext(CommentsContext);
  if (!store) throw new Error("Missing CommentsProvider in the tree");
  return useStore(store, selector);
};
