import { CreateForm } from "@/API/Forms/CreateForm";
import React, { Dispatch, SetStateAction } from "react";
import { useFormContext, useFormState } from "react-hook-form";
import { FormControl, FormField, FormItem, FormMessage } from "@/Components/ui/form";
import { CreateLayout } from "@/Layouts/CreateLayout";
import { TextareaAutosize } from "@/Components/ui/textarea";
import { useIsMobile } from "@/Hooks/useIsMobile";

export type StepFiveProps = {
  setStep: Dispatch<SetStateAction<number>>;
};

const StepFour = (props: StepFiveProps) => {
  const form = useFormContext<CreateForm>();

  const state = useFormState({
    control: form.control,
    name: "stepFour.about",
    exact: true,
  });

  const isMobile = useIsMobile();

  return (
    <CreateLayout
      step={4}
      form={form}
      dirty={state.isDirty}
      onNext={async () => {
        let isValid = await form.trigger("stepFour.about");
        if (!isValid) return;

        props.setStep((step) => step + 1);
      }}
      onBack={() => {
        props.setStep((step) => step - 1);
      }}>
      <div className="flex flex-col items-center gap-6">
        <div className="flex flex-col items-center gap-2">
          <h2 className="text-2xl font-bold leading-tight tracking-tight">Add a description</h2>
          <p className="text-balance text-center text-sm text-muted-foreground">
            Write something about your setup, e.g. what you use it for, or how you put it together.
          </p>
        </div>
        <FormField
          control={form.control}
          name="stepFour.about"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormControl>
                <TextareaAutosize
                  minRows={4}
                  maxRows={isMobile ? 4 : 6}
                  placeholder="Write something interesting..."
                  className="resize-none"
                  {...field}
                />
              </FormControl>
              <FormMessage className="text-center" />
            </FormItem>
          )}
        />
      </div>
    </CreateLayout>
  );
};

export default StepFour;
