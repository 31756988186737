import React from "react";
import { Head } from "@/Components/Atoms/Head";
import { Container } from "@/Components/Atoms/Container";
import { Battlestations } from "@/Components/Organisms/Browse/Battlestations";
import { FeaturedBattlestations } from "@/Components/Organisms/Browse/FeaturedBattlestations/FeaturedBattlestations";

export type BrowseProps = {
  featuredBattlestations?: App.Data.Models.BattlestationData[];
};

const Browse = (props: BrowseProps) => {
  return (
    <>
      <Head title="Browse" />
      {props.featuredBattlestations && (
        <FeaturedBattlestations battlestations={props.featuredBattlestations} />
      )}
      <Container className="flex flex-col gap-6 py-6">
        <Battlestations />
      </Container>
    </>
  );
};

export default Browse;
