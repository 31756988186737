import React, { ReactNode, useMemo, useState } from "react";
import { usePage } from "@inertiajs/react";
import type { BattlestationProps } from "@/Pages/Battlestation/Battlestation";
import { DialogWrapper } from "@/Components/Molecules/DialogWrapper";
import { query } from "@/Utils/query";
import { Button } from "@/Components/ui/button";
import { cn } from "@/Utils/shadcn";
import { Checkbox } from "@/Components/ui/checkbox";
import { FlaskConical } from "lucide-react";
import { Icon } from "@/Types/Icon";
import { CustomWidgets, NameDescription, Photos } from "@/Components/Atoms/Icons/tutorial-graphics";
import { useAtom } from "jotai";
import { BattlestationState } from "@/State/BattlestationState";

type Step = {
  title: string;
  topText: ReactNode;
  bottomText: ReactNode;
  image?: Icon;
  icon?: Icon;
};

export const BattlestationTutorial = () => {
  const { props } = usePage<BattlestationProps>();

  const [hideTutorialOnce, setHideTutorialOnce] = useAtom(BattlestationState.hideTutorialOnce);
  const [hideTutorialForever, setHideTutorialForever] = useAtom(
    BattlestationState.hideTutorialForever,
  );

  const steps = useMemo<Step[]>(() => {
    const autoAdd = query("autoAdd");

    return [
      ...(autoAdd
        ? [
            {
              title: "Auto-add is experimental",
              icon: FlaskConical,
              topText: "Check on your auto-added gear to make sure everything's correct.",
              bottomText: (
                <>
                  We've started you off with some <strong>custom widgets</strong> to hold your
                  details!
                </>
              ),
            },
          ]
        : []),
      {
        title: "Custom widgets",
        image: CustomWidgets,
        ...(props.battlestation.widgets?.length
          ? {
              topText: "With widgets, you can organise your gear into categories and more.",
              bottomText:
                'Click the "plus" to add a widget, then customize and reorder your widget board until you\'re happy.',
            }
          : {
              topText:
                'To add your gear, click the "plus" and try creating your first custom widget.',
              bottomText: "With widgets, you can organise your gear into categories and more.",
            }),
      },
      {
        image: Photos,
        ...(props.battlestation.images?.length
          ? {
              title: "Edit your photos",
              topText:
                'Select "edit images" on the image gallery to upload, reorder and rename your photos.',
              bottomText: "You can crop any photo straight from the image gallery.",
            }
          : {
              title: "Adding photos",
              topText: 'To start adding photos, select "edit images" on the image gallery.',
              bottomText: "Keep customizing your gallery until you're happy!",
            }),
      },
      {
        title: "Name and description",
        topText: "Remember to tell everyone about your setup with a name and description.",
        bottomText: "You can always change these later.",
        image: NameDescription,
      },
      {
        title: "Enjoy rigr.gg",
        topText:
          "Your rigr.gg page is made to be customizable, so spend as long as you like getting it just right.",
        bottomText: (
          <>
            We're still in beta, so if you have any feedback or suggestions, head over to the{" "}
            <a
              href="https://discord.gg/jRVNHdBFzn"
              target="_blank"
              rel="noopener noreferrer"
              className="underline">
              official Discord
            </a>
            . We'd love to see you there!
          </>
        ),
      },
    ];
  }, []);

  const [open, setOpen] = useState<boolean>(
    () => !props.battlestation.published_at && !hideTutorialOnce && !hideTutorialForever,
  );

  const [step, setStep] = useState(1);

  const [hideTutorialChecked, setHideTutorialChecked] = useState(false);

  const currentStep = useMemo(() => {
    return steps[step - 1] ?? steps[steps.length - 1];
  }, [steps, step]);

  const onClose = () => {
    setOpen(false);

    setHideTutorialOnce(true);

    if (hideTutorialChecked) {
      setHideTutorialForever(true);
    }
  };

  return (
    <DialogWrapper
      preventClickOutside
      title={currentStep.title}
      open={open}
      setOpen={() => onClose()}
      dialogClassName="h-[500px] max-w-[min(calc(100svw-2rem),350px)] flex flex-col justify-between gap-6"
      drawerClassName="max-h-[500px]"
      drawerContentClassName="h-full flex flex-col justify-between gap-6">
      {/* space for mobile */}
      <div className="-mt-4 block md:hidden"></div>
      <div className="flex flex-col items-center gap-6 text-balance text-center text-sm">
        {currentStep.icon && (
          <currentStep.icon className="size-20 shrink-0 text-muted-foreground" />
        )}
        <p>{currentStep.topText}</p>
        {currentStep.image && <currentStep.image className="h-44 text-background" />}
        <p>{currentStep.bottomText}</p>
      </div>
      <div className="flex flex-col gap-4">
        {step === steps.length && (
          <div className="flex items-center justify-center gap-2">
            <Checkbox
              id="hide-tutorial"
              checked={hideTutorialChecked}
              onCheckedChange={(checked) => setHideTutorialChecked(!!checked)}
            />
            <label htmlFor="hide-tutorial" className="text-sm font-medium">
              Don't show the tutorial again
            </label>
          </div>
        )}
        <div className="flex flex-1 items-center justify-center gap-2">
          {[...Array(steps.length)].map((_, index) => (
            <div
              key={index + 1}
              className={cn("size-2 rounded-full bg-secondary", index + 1 === step && "bg-primary")}
            />
          ))}
        </div>
        <Button
          onClick={() => {
            if (step === steps.length) {
              onClose();
            } else {
              setStep((step) => step + 1);
            }
          }}
          className="w-full">
          {step === steps.length ? "Let's go" : "Next"}
        </Button>
      </div>
    </DialogWrapper>
  );
};
