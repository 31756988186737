import create from "zustand";
import { RefObject } from "react";

type State = {
  unitSize: number;
  padding: number;
  columns: number;
  grid: RefObject<HTMLDivElement> | null;
};

type Actions = {
  init: (
    unitSize: number,
    padding: number,
    columns: number,
    grid: RefObject<HTMLDivElement>,
    reset: () => void,
  ) => void;
  reset: () => void;
};

export const useWidgetsStore = create<State & Actions>((set) => ({
  unitSize: 0,
  padding: 0,
  columns: 0,
  grid: null,
  init: (unitSize, padding, columns, grid, reset) =>
    set({ unitSize, padding, columns, grid, reset }),
  reset: () => {},
}));
