import { FieldPath, FieldValues, useFormState } from "react-hook-form";
import { get, keys } from "lodash";

export const useHasErrors = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  name?: TName | TName[],
  filter?: (key: string) => boolean,
) => {
  const { errors } = useFormState<TFieldValues>();

  if (!name) return false;

  let result = Array.isArray(name)
    ? name.flatMap((x) => keys(get(errors, x) ?? {}))
    : keys(get(errors, name) ?? {});

  if (filter) {
    result = result.filter(filter);
  }

  return result.length > 0;
};
