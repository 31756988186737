import React, { router, usePage } from "@inertiajs/react";
import { useEffect, useState } from "react";
import axios from "axios";
import { ResolverFunction } from "@/Components/Molecules/MomentumModal";

/**
 * This hook was copied from https://github.com/josemariagomez/momentum-modal-react/blob/main/src/use-modal.tsx
 * as the export from the package is not working as expected. I haven't modified the code in any way, but it seems to work.
 */
export const useMomentumModal = (resolverCallback: ResolverFunction | null = null) => {
  const modal = usePage().props?.modal;
  const props = modal?.props;
  const key = modal?.key;

  const [show, setShow] = useState<boolean>(false);
  const [vnode, setVnode] = useState<JSX.Element>(<></>);

  const resolver: ResolverFunction =
    resolverCallback !== null ? resolverCallback : globalThis.resolveMomentumModal;

  const setHeaders = (values: Record<string, string | null>) => {
    Object.entries(values).forEach(([key, value]) =>
      ["post", "put", "patch", "delete"].forEach((method) => {
        /** @ts-ignore */
        axios.defaults.headers[method][key] = value;
      }),
    );
  };

  const resetHeaders = () => {
    const headers = ["X-Inertia-Modal-Key", "X-Inertia-Modal-Redirect"];

    /** @ts-ignore */
    headers.forEach(([key, _]) =>
      ["get", "post", "put", "patch", "delete"].forEach((method) => {
        /** @ts-ignore */
        delete axios.defaults.headers[method][key];
      }),
    );
  };

  const updateHeaders = () => {
    setHeaders({
      "X-Inertia-Modal-Key": key,
      "X-Inertia-Modal-Redirect": modal?.redirectURL,
    });

    axios.defaults.headers.get["X-Inertia-Modal-Redirect"] = modal?.redirectURL ?? "";
  };

  const close = () => {
    setShow(false);
    resetHeaders();
  };

  const resolveComponent = () => {
    if (typeof resolver !== "function") {
      throw Error("Resolver function not defined. You have to define it at Inertia's entrypoint.");
    }

    if (!modal?.component) {
      return close();
    }

    const component = modal?.component ? resolver(modal.component) : null;

    if (component) {
      const ComponentToRender = component.default;
      setVnode(<ComponentToRender key={key} {...props} />);
    } else {
      setVnode(<></>);
    }

    setShow(true);
  };

  useEffect(() => {
    resolveComponent();
  }, []);

  useEffect(() => {
    resolveComponent();
  }, [modal]);

  useEffect(updateHeaders, [key]);

  // For some reason setting the default headers on axios isn't working, despite pinning the axios version,
  // so we're manually setting the headers on the visit object as a workaround.
  useEffect(() => {
    return router.on("before", (event) => {
      if (modal) {
        event.detail.visit.headers["X-Inertia-Modal-Key"] = modal.key;
        event.detail.visit.headers["X-Inertia-Modal-Redirect"] = modal.redirectURL ?? "";
      }
    });
  }, [modal]);

  const redirect = () => {
    const redirectURL = modal?.redirectURL ?? modal?.baseURL;

    setVnode(<></>);

    if (!redirectURL) {
      return;
    }

    return router.visit(redirectURL, {
      preserveScroll: true,
      preserveState: true,
    });
  };

  return {
    show,
    vnode,
    close,
    redirect,
    props,
  };
};
