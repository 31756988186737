import { z } from "zod";
import { fileSchema } from "@/Schemas/fileSchema";
import { tagSchema } from "@/Schemas/tagSchema";

export const stepOneSchema = z.object({
  details: z.string().max(3000).optional(),
});

export const stepTwoSchema = z.object({
  images: z.array(fileSchema).max(10, "You can only add up to 10 photos.").optional(),
});

export const stepThreeSchema = z.object({
  tags: z.array(tagSchema).optional(),
});

export const stepFourSchema = z.object({
  about: z.string().min(2).max(3000).or(z.literal("")),
});

export const stepFiveSchema = z.object({
  name: z.string({ required_error: "You must add a name to continue." }).min(2).max(50),
});

export const createSchema = z.object({
  stepOne: stepOneSchema,
  stepTwo: stepTwoSchema,
  stepThree: stepThreeSchema,
  stepFour: stepFourSchema,
  stepFive: stepFiveSchema,
});
