export class NamedTransformation {
  static ImageLarge = {
    width: 1600,
  };

  static ImageRegular = {
    width: 800,
  };

  static ImageThumbnail = {
    width: 400,
  };

  static Avatar = {
    width: 200,
    height: 200,
    focus: "center",
  };

  static BannerImage = {
    width: 1000,
    height: 167,
    focus: "center",
  };
}
