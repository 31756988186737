import React, { memo, useState } from "react";
import { cn } from "@/Utils/shadcn";
import { Image } from "@/Components/Atoms/Image";
import { ImagePlus } from "lucide-react";
import { useMedia } from "react-use";
import { UploadImageDialog } from "@/Components/Molecules/Battlestation/Widgets/ImageWidget/UploadImageDialog";
import { CropImageDialog } from "@/Components/Molecules/Battlestation/Widgets/CropImageDialog";
import { useFormContext, useWatch } from "react-hook-form";
import { BattlestationForm } from "@/API/Forms/BattlestationForm";
import { WidgetLayout } from "@/Components/Molecules/Battlestation/Widgets/WidgetLayout";
import { WidgetPropsBase } from "@/Types/Widgets/WidgetPropsBase";
import { create } from "mutative";
import { usePreloadImages } from "@/Hooks/usePreloadImages";
import { Crop } from "react-image-crop";
import { asset } from "laravel-vapor";
import { useIsMobile } from "@/Hooks/useIsMobile";
import { useBattlestationStore } from "@/Stores/useBattlestationStore";
import { useFormErrors } from "@/Hooks/useFormErrors";
import { ImageDialog } from "@/Components/Molecules/ImageDialog";
import { NamedTransformation } from "@/API/NamedTransformation";

export type ImageWidgetProps = WidgetPropsBase<App.Data.Models.WidgetData>;

export const ImageWidget = memo(({ widget, ...props }: ImageWidgetProps) => {
  const form = useFormContext<BattlestationForm>();

  const widgetIndex = form.getValues("widgets").findIndex((x) => x.id === widget.id);

  const name: `widgets.${number}` = `widgets.${widgetIndex}`;

  const width = form.getValues(`${name}.width`);
  const aspect = width === 2 ? 2 : 1;

  usePreloadImages(
    [widget.image],
    [
      // cropped and scaled down
      {
        transforms: {
          named: NamedTransformation.ImageThumbnail,
          aspectRatio: `${aspect}-1`,
        },
      },
      // cropped larger version for zooming
      {
        transforms: {
          named: NamedTransformation.ImageLarge,
          aspectRatio: `${aspect}-1`,
        },
      },
      // uncropped full size
      {
        preventCrop: true,
      },
    ],
  );

  const editing = useBattlestationStore((state) => state.editing);
  const rearranging = useBattlestationStore((state) => state.rearranging);

  const isTouch = useMedia("(pointer: coarse)");

  const [uploadOpen, setUploadOpen] = useState(false);
  const [cropOpen, setCropOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const [crop, setCrop] = useState<Crop>();

  const imageWatcher = useWatch({
    control: form.control,
    name: `${name}.image`,
  });

  const image = editing && !props.preview ? imageWatcher! : widget.image!;

  const errors = useFormErrors<BattlestationForm>([name]);

  const isMobile = useIsMobile();

  return (
    <WidgetLayout
      gridConfig={props.gridConfig}
      clickable={true}
      needsAttention={!image}
      onResize={() => {
        const image = form.getValues(`${name}.image`);
        form.setValue(
          `${name}.image`,
          create(image, (draft) => {
            if (!draft) return;
            draft.crop = null;
          }),
        );
      }}>
      {!props.preview && (
        <>
          <UploadImageDialog
            name={name}
            open={uploadOpen}
            onOpenChange={setUploadOpen}
            onUploaded={() => {
              setCrop(undefined);
              setUploadOpen(false);
              setCropOpen(true);
            }}
          />
          <CropImageDialog
            image={image}
            aspect={aspect}
            errors={errors}
            open={cropOpen}
            crop={crop}
            setCrop={setCrop}
            onOpenChange={setCropOpen}
            onCrop={(crop) => {
              const image = form.getValues(`${name}.image`);
              form.setValue(
                `${name}.image`,
                create(image, (draft) => {
                  if (!draft) return;
                  draft.crop = crop;
                }),
              );
            }}
            onReplaceImage={() => {
              if (!isMobile) {
                setCropOpen(false);
              }
              setUploadOpen(true);
            }}
          />
          <ImageDialog image={image} aspect={aspect} open={expanded} setOpen={setExpanded} />
        </>
      )}
      <div
        className={cn(
          "overflow-hidden rounded-3xl",
          props.preview ? "size-full" : "absolute inset-0",
        )}
        onClick={() => {
          if (rearranging || props.preview) return;

          if (editing) {
            if (image) {
              setCrop(undefined);
              setCropOpen(true);
            } else {
              setUploadOpen(true);
            }
          } else {
            setExpanded(true);
          }
        }}>
        <div
          className={cn(
            "pointer-events-none z-10 size-full",
            props.preview && (widget.width === 1 ? "aspect-square" : "aspect-[2/1]"),
          )}>
          {image ? (
            <Image
              image={image}
              transform={{ named: NamedTransformation.ImageThumbnail, aspectRatio: `${aspect}-1` }}
              className="size-full object-cover"
            />
          ) : (
            <div className="flex size-full flex-col items-center justify-center gap-1.5 p-2 text-muted-foreground">
              <img
                alt=""
                src={asset(
                  `assets/images/widget-glows/pc-build-glow-${widget.width === 1 ? "small" : "medium"}.png`,
                )}
                className="pointer-events-none absolute bottom-0 size-full"
              />
              <ImagePlus className="size-6" />
              <div className="text-balance text-center text-xs font-medium">
                {isTouch ? "Touch" : "Click"} to add image
              </div>
            </div>
          )}
        </div>
      </div>
    </WidgetLayout>
  );
});
