import React, { useEffect, useMemo, useState } from "react";
import {
  BattlestationSortingIcon,
  BattlestationSortingLabel,
} from "@/API/Maps/BattlestationSorting";
import { Spinner } from "@/Components/Atoms/Spinner";
import { BattlestationCardDetailed } from "@/Components/Molecules/BattlestationCardDetailed";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { Tabs, TabsList, TabsTrigger } from "@/Components/ui/tabs";
import { Breakpoint } from "@/Types/Enums/Breakpoint";
import { useAbortController } from "@/Hooks/useAbortController";
import { usePaginated } from "@/Hooks/usePaginated";
import { route } from "ziggy-js";
import { useWindowSizeThreshold } from "@/Hooks/useWindowSizeThreshold";
import InfiniteScroll from "react-infinite-scroll-component";
import { cn } from "@/Utils/shadcn";
import axios from "axios";
import { BattlestationSorting } from "@/Types/generated_enums";

export const Battlestations = () => {
  const abortController = useAbortController();

  const [sorting, setSorting] = useState(BattlestationSorting.Popular);
  const [sortingLabel, setSortingLabel] = useState(() => BattlestationSortingLabel.get(sorting));

  const [disabled, setDisabled] = useState(false);

  const { items, setItems, setPage, endReached, isLoading } =
    usePaginated<App.Data.Models.BattlestationData>((page) =>
      route("browse.battlestations", {
        page,
        _query: {
          battlestationSorting: sorting,
        },
      }),
    );

  useEffect(() => {
    if (disabled) return;
    setSortingLabel(BattlestationSortingLabel.get(sorting));
  }, [sorting, disabled]);

  const sortingOptions = useMemo(
    () =>
      Array.from(BattlestationSortingLabel).map(([key]) => ({
        value: key,
        label: BattlestationSortingLabel.get(key)!,
        Icon: BattlestationSortingIcon.get(key)!,
      })),
    [BattlestationSortingLabel, BattlestationSortingIcon],
  );

  const isSmall = useWindowSizeThreshold(Breakpoint.SM);

  return (
    <div
      className={cn(
        "flex flex-col gap-6",
        disabled && "pointer-events-none cursor-default opacity-75",
      )}>
      <div className="flex w-full flex-col justify-between gap-4 sm:flex-row sm:items-center">
        <h2 className="heading-xl">{sortingLabel} Battlestations</h2>
        <Tabs
          orientation={isSmall ? "horizontal" : "vertical"}
          value={sorting.toString()}
          onValueChange={async (value) => {
            const newSorting = parseInt(value) as BattlestationSorting;
            setSorting(newSorting);

            setDisabled(true);

            const response = await axios
              .get(
                route("browse.battlestations", {
                  page: 1,
                  _query: {
                    battlestationSorting: newSorting,
                  },
                }),
                {
                  signal: abortController?.signal,
                },
              )
              .catch((error) => {
                if (axios.isCancel(error)) return;
                throw error;
              })
              .finally(() => {
                setDisabled(false);
              });

            if (!response) return;

            setPage(1);
            setItems(response.data);
          }}>
          <TabsList className="h-auto w-full flex-col border bg-card sm:w-auto md:h-9 md:flex-row">
            {sortingOptions.map(({ value, label, Icon }) => (
              <TabsTrigger
                key={value}
                value={value.toString()}
                className="w-full data-[state=active]:bg-accent md:w-auto">
                <div className="flex items-center gap-2">
                  <Icon className="-mx-1 size-3.5" />
                  {label}
                </div>
              </TabsTrigger>
            ))}
          </TabsList>
        </Tabs>
      </div>
      {/* Battlestations */}
      <InfiniteScroll
        dataLength={items?.data.length ?? 0}
        next={() => {
          if (!isLoading) {
            setPage((page) => page + 1);
          }
        }}
        hasMore={!endReached}
        loader={
          <div className="flex justify-center">
            <Spinner />
          </div>
        }
        endMessage={
          <div className="flex flex-col items-center gap-2 py-8 text-sm text-gray-500">
            <CheckCircleIcon className="h-8 w-8" />
            You've reached the end!
          </div>
        }
        className="-m-2 flex flex-col gap-8 p-2">
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
          {items?.data.map((battlestation) => (
            <BattlestationCardDetailed key={battlestation.id} battlestation={battlestation} />
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
};
