import React, { useState } from "react";
import { Avatar } from "@/Components/Atoms/Avatar";
import { route } from "ziggy-js";
import { useUser } from "@/Hooks/useUser";
import { CommentType } from "@/Types/Enums/CommentType";
import { Form, FormControl, FormField, FormItem } from "@/Components/ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/Components/ui/button";
import { TextareaAutosize } from "@/Components/ui/textarea";
import { Breakpoint } from "@/Types/Enums/Breakpoint";
import { useWindowSize } from "react-use";
import { CommentForm, CommentFormSchema } from "@/API/Forms/CommentForm";

export type CommentInputProps = {
  type: CommentType;
  entity: App.Data.Models.BattlestationData | App.Data.Models.CommentData;
  autoFocus?: boolean;
  defaultValue?: string;
  onCancel?: () => void;
  onSuccess?: (data: App.Data.Models.CommentData) => void;
  onSubmit?: (data: CommentForm) => Promise<void>;
};

export const CommentInput = (props: CommentInputProps) => {
  const user = useUser();

  const { width } = useWindowSize();

  const [expanded, setExpanded] = useState(false);

  const form = useForm<CommentForm>({
    resolver: zodResolver(CommentFormSchema),
    defaultValues: {
      body: props.defaultValue ?? "",
    },
  });

  return (
    <div className="flex items-start gap-4">
      <div className="flex-shrink-0">
        <Avatar image={user?.avatar} username={user?.username} className="h-8 w-8" />
      </div>
      <div className="flex-1">
        <Form
          {...form}
          className="space-y-2"
          onReset={() => {
            setExpanded(false);
          }}
          onSubmit={async (data, resolve, reject) => {
            try {
              await props.onSubmit?.(data);
              setExpanded(false);
              form.reset();
              resolve();
            } catch (error) {
              reject();
              throw error;
            }
          }}>
          <FormField
            control={form.control}
            name="body"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <TextareaAutosize
                    autoFocus={props.autoFocus}
                    minRows={expanded ? 2 : 1}
                    placeholder="Add your comment..."
                    className="min-h-0 resize-none"
                    onFocus={() => setExpanded(true)}
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          {expanded && (
            <div className="mt-1 flex justify-end sm:mt-3">
              <div className="flex flex-col items-end gap-2 sm:flex-row sm:items-center sm:gap-4">
                <div className="text-sm text-gray-400">
                  Please follow our{" "}
                  <a
                    href={route("code-of-conduct")}
                    target="_blank"
                    className="font-medium text-indigo-400 hover:text-indigo-300">
                    Code of Conduct
                  </a>
                  .
                </div>
                <div className="flex gap-2">
                  <Button
                    variant="ghost"
                    type="reset"
                    size={width < Breakpoint.XS ? "sm" : "default"}
                    onClick={() => {
                      form.reset();
                      props.onCancel?.();
                    }}>
                    Cancel
                  </Button>
                  <Button type="submit" size={width < Breakpoint.XS ? "sm" : "default"}>
                    Comment
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Form>
      </div>
    </div>
  );
};
