import React from "react";
import { Dialog, DialogOverlay, DialogPortal } from "@/Components/ui/dialog";
import { DialogContent } from "@radix-ui/react-dialog";
import { Image } from "@/Components/Atoms/Image";
import { img } from "@/Utils/img";
import { NamedTransformation } from "@/API/NamedTransformation";
import { ImageTransform } from "@/Types/ImageTransform";

export type ImageDialogProps = {
  image: App.Data.Models.ImageData;
  aspect?: number;
  open: boolean;
  setOpen: (open: boolean) => void;
};

export const ImageDialog = (props: ImageDialogProps) => {
  let transform: ImageTransform = {
    named: NamedTransformation.ImageLarge,
  };

  if (props.aspect) {
    transform.aspectRatio = `${props.aspect}-1`;
  }

  return (
    <Dialog open={props.open} onOpenChange={props.setOpen}>
      <DialogPortal>
        <DialogOverlay className="z-40 cursor-zoom-out" />
        <DialogContent
          style={{ pointerEvents: "none" }}
          className="fixed left-1/2 top-1/2 z-50 flex size-full -translate-x-1/2 -translate-y-1/2 cursor-zoom-out items-center justify-center p-4 outline-none duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:p-8">
          <div className="safari:items-center flex h-full flex-col justify-center gap-2">
            <Image
              image={props.image}
              transform={transform}
              onClick={() => props.setOpen(false)}
              className="safari:min-h-0 safari:w-full safari:min-w-0 safari:flex-shrink safari:flex-grow safari:basis-auto pointer-events-auto max-h-full max-w-full object-contain"
            />
            <div>
              <a
                href={img(props.image, undefined, true)}
                target="_blank"
                className="pointer-events-auto shrink-0 text-sm text-muted-foreground transition-colors hover:text-primary hover:underline">
                View original image
              </a>
            </div>
          </div>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
