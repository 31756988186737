import React from "react";
import { Progress } from "@/Components/ui/progress";

export type TextProgressProps = {
  entity: string;
  value: number;
  limit: number;
};

export const TextProgress = (props: TextProgressProps) => {
  return (
    <div className="flex flex-col gap-2 sm:flex-row sm:items-center sm:gap-4">
      <span className="shrink-0 text-sm leading-tight text-muted-foreground">
        {props.value}/{props.limit} {props.entity}
      </span>
      <Progress value={(props.value / props.limit) * 100} />
    </div>
  );
};
