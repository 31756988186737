import React, { forwardRef, useState } from "react";
import { FileInput, FileInputProps } from "@/Components/Atoms/FileInput";
import { uploadFileToS3 } from "@/Utils/uploadFileToS3";
import { FormImage } from "@/Types/FormImage";
import { z } from "zod";
import { fileSchema } from "@/Schemas/fileSchema";
import { img } from "@/Utils/img";
import { uuid } from "@/Utils/uuid";

export type FormFileInputProps = Omit<FileInputProps, "value" | "uploading" | "onChange"> & {
  value: z.infer<typeof fileSchema> | null;
  onChange?: (value: FormImage) => void;
};

export const FormFileInput = forwardRef<HTMLInputElement, FormFileInputProps>(
  ({ value, onChange, ...props }, ref) => {
    const [uploading, setUploading] = useState(false);

    return (
      <FileInput
        {...props}
        ref={ref}
        url={img(value) ?? ""}
        uploading={uploading}
        onChange={async (files) => {
          const file = files[0];

          setUploading(true);

          const uploadResponse = await uploadFileToS3(file);
          const formData: FormImage = {
            id: uuid(),
            name: file.name,
            key: uploadResponse.key,
            upload_response: uploadResponse,
          };

          setUploading(false);

          onChange?.(formData);
        }}
      />
    );
  },
);
