import * as React from "react";

import { cn } from "@/Utils/shadcn";
import { useEffect, useRef } from "react";
import ReactTextareaAutosize, { TextareaAutosizeProps } from "react-textarea-autosize";

const baseClassNames =
  "flex min-h-[60px] w-full rounded-md border border-input bg-transparent px-3 py-2 text-sm shadow-sm placeholder:text-muted-foreground focus-visible:border-transparent focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 touch:text-base";

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, autoFocus, ...props }, parentRef) => {
    const localRef = useRef<HTMLTextAreaElement | null>(null);

    useEffect(() => {
      if (autoFocus) {
        const strLength = (localRef.current?.value.length ?? 0) * 2;
        localRef.current?.focus();
        localRef.current?.setSelectionRange(strLength, strLength);
      }
    }, [autoFocus]);

    return (
      <textarea
        className={cn(baseClassNames, className)}
        ref={(ref) => {
          if (typeof parentRef === "function") {
            parentRef(ref);
          } else if (parentRef) {
            parentRef.current = ref;
          }
          localRef.current = ref;
        }}
        {...props}
      />
    );
  },
);
Textarea.displayName = "Textarea";

const TextareaAutosize = React.forwardRef<HTMLTextAreaElement, TextareaAutosizeProps>(
  ({ className, autoFocus, ...props }, parentRef) => {
    const localRef = useRef<HTMLTextAreaElement | null>(null);

    useEffect(() => {
      if (autoFocus) {
        const strLength = (localRef.current?.value.length ?? 0) * 2;
        localRef.current?.focus();
        localRef.current?.setSelectionRange(strLength, strLength);
      }
    }, [autoFocus]);

    return (
      <ReactTextareaAutosize
        className={cn(baseClassNames, className)}
        ref={(ref) => {
          if (typeof parentRef === "function") {
            parentRef(ref);
          } else if (parentRef) {
            parentRef.current = ref;
          }
          localRef.current = ref;
        }}
        {...props}
      />
    );
  },
);
TextareaAutosize.displayName = "TextareaAutosize";

export { Textarea, TextareaAutosize };
