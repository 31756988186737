import React, { useRef } from "react";
import { fileSchema } from "@/Schemas/fileSchema";
import { z } from "zod";
import { useFileUploadsStore } from "@/Stores/useFileUploadsStore";
import { useForm, useFormContext } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { route } from "ziggy-js";
import { purgeUUIDs } from "@/Utils/purgeUUIDs";
import { SettingsLayout } from "@/Layouts/SettingsLayout";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/Components/ui/form";
import { Textarea } from "@/Components/ui/textarea";
import { Avatar } from "@/Components/Atoms/Avatar";
import { uploadFileToS3 } from "@/Utils/uploadFileToS3";
import { FormImage } from "@/Types/FormImage";
import { uuid } from "@/Utils/uuid";
import { Button } from "@/Components/ui/button";
import { FormFileInput } from "@/Components/Molecules/FormFileInput";
import { Input } from "@/Components/ui/input";
import { SocialPlatform } from "@/Types/generated_enums";
import { SocialIcon } from "react-social-icons";
import { SocialPlatformBackgroundStyle, SocialPlatformNetwork } from "@/API/Maps/SocialPlatform";
import { getOrFail } from "@/Utils/getOrFail";
import { cn } from "@/Utils/shadcn";
import { Alert, AlertDescription, AlertTitle } from "@/Components/ui/alert";
import { Crown } from "lucide-react";
import { createFQDNSchema } from "@/Schemas/fqdnSchema";

export type ProfileProps = {
  user: App.Data.Models.UserData;
  socialLinkFields: App.Data.SocialLinkFieldsData;
  can: {
    show_profile_banner_notice: boolean;
    add_social_links: boolean;
  };
};

const formSchema = z.object({
  about: z.string(),
  avatar: fileSchema.nullable(),
  banner_image: fileSchema.nullable(),
  social_links: z.object({
    // TODO: Use refinements to validate FQDNs
    youtube: createFQDNSchema({
      allowedDomains: ["youtube.com", "youtu.be"],
      platformName: "YouTube",
    }).nullable(),
    instagram: createFQDNSchema({
      allowedDomains: "instagram.com",
      platformName: "Instagram",
    }).nullable(),
    twitter: createFQDNSchema({
      allowedDomains: ["twitter.com", "x.com"],
      platformName: "Twitter",
    }).nullable(),
    facebook: createFQDNSchema({
      allowedDomains: ["facebook.com", "fb.com"],
      platformName: "Facebook",
    }).nullable(),
    tiktok: createFQDNSchema({
      allowedDomains: "tiktok.com",
      platformName: "TikTok",
    }).nullable(),
    twitch: createFQDNSchema({
      allowedDomains: "twitch.tv",
      platformName: "Twitch",
    }).nullable(),
    patreon: createFQDNSchema({
      allowedDomains: "patreon.com",
      platformName: "Patreon",
    }).nullable(),
  }),
});

type ProfileForm = z.infer<typeof formSchema>;

const Profile = (props: ProfileProps) => {
  const avatarRef = useRef<HTMLInputElement>(null);
  const bannerImageRef = useRef<HTMLInputElement>(null);

  const uploadsInProgress = useFileUploadsStore((state) => state.uploadsInProgress);

  const form = useForm<ProfileForm>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      about: props.user.about ?? "",
      avatar: props.user.avatar,
      banner_image: props.user.banner_image,
      social_links: props.socialLinkFields,
    },
  });

  return (
    <Form
      {...form}
      route={route("settings.profile.store")}
      options={{
        transform: (data) =>
          Object.assign(data, {
            avatar: purgeUUIDs(data.avatar),
            banner_image: purgeUUIDs(data.banner_image),
          }),
      }}
      onReset={() => {
        if (avatarRef.current) {
          avatarRef.current.value = "";
        }

        if (bannerImageRef.current) {
          bannerImageRef.current.value = "";
        }
      }}>
      <SettingsLayout
        form={form}
        disabled={uploadsInProgress > 0}
        title="Profile"
        description="This information will be displayed publicly so be careful what you share.">
        {props.can.show_profile_banner_notice && (
          <Alert>
            <Crown className="size-4 !text-amber-400" />
            <AlertTitle>Heads up!</AlertTitle>
            <AlertDescription>
              The user banner will appear on your setup page(s) if you've set an avatar and a banner
              image.
            </AlertDescription>
          </Alert>
        )}
        <div>
          <FormField
            control={form.control}
            name="about"
            render={({ field }) => (
              <FormItem>
                <FormLabel>About me</FormLabel>
                <FormControl>
                  <Textarea placeholder="Write something about yourself..." rows={3} {...field} />
                </FormControl>
                <FormDescription>Brief description for your profile.</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        {/* Avatar */}
        <div>
          <FormField
            control={form.control}
            name="avatar"
            render={({ field: { value, onChange, ...field } }) => (
              <FormItem>
                <FormLabel>Avatar</FormLabel>
                <FormControl>
                  <div className="mt-2 flex items-center gap-2">
                    <Avatar image={value} username={props.user.username} className="size-9" />
                    <div className="flex gap-2">
                      <input
                        {...field}
                        id="avatar"
                        name="avatar"
                        type="file"
                        accept="image/png, image/jpeg"
                        className="sr-only absolute"
                        tabIndex={-1}
                        onInput={async (e) => {
                          const target = e.target as HTMLInputElement;
                          if (!target.files?.length) return;

                          e.preventDefault();

                          const file = target.files[0];

                          const uploadResponse = await uploadFileToS3(file);
                          const formData: FormImage = {
                            id: uuid(),
                            name: file.name,
                            key: uploadResponse.key,
                            upload_response: uploadResponse,
                          };

                          form.setValue("avatar", formData, {
                            shouldValidate: true,
                            shouldDirty: true,
                          });
                        }}
                      />
                      <Button type="button" variant="secondary" className="relative">
                        Change<span className="sr-only"> avatar</span>
                        <label htmlFor="avatar" className="absolute inset-0 cursor-pointer" />
                      </Button>
                    </div>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        {/* Banner image */}
        <div>
          <FormField
            control={form.control}
            name="banner_image"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Banner image</FormLabel>
                  <FormControl>
                    <FormFileInput {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
        </div>
        {props.can.add_social_links && (
          <>
            <div className="flex flex-col space-y-1.5 pt-4">
              <h4 className="font-semibold leading-none tracking-tight">Social Links</h4>
              <p className="text-sm text-muted-foreground">
                Add any social links you want to appear publicly on your setup pages.
              </p>
            </div>
            <div className="grid gap-4 sm:grid-cols-2">
              <SocialLink name="YouTube" placeholder="Add a link to your YouTube channel..." />
              <SocialLink name="Instagram" placeholder="Add a link to your Instagram page..." />
              <SocialLink name="Twitter" placeholder="Add a link to your Twitter account..." />
              <SocialLink name="Facebook" placeholder="Add a link to a Facebook page..." />
              <SocialLink name="TikTok" placeholder="Add a link to your Tiktok channel..." />
              <SocialLink name="Twitch" placeholder="Add a link to your Twitch channel..." />
              <SocialLink name="Patreon" placeholder="Add a link to your Patreon page..." />
            </div>
          </>
        )}
      </SettingsLayout>
    </Form>
  );
};

type SocialLinkProps = {
  name: keyof typeof SocialPlatform;
  placeholder: string;
};

const SocialLink = (props: SocialLinkProps) => {
  const form = useFormContext<ProfileForm>();

  return (
    <FormField
      control={form.control}
      name={`social_links.${props.name.toLowerCase() as Lowercase<typeof props.name>}`}
      render={({ field }) => (
        <FormItem className="basis-1 space-y-2">
          <FormLabel className="flex items-center gap-1.5">
            <div
              className={cn(
                "flex size-6 items-center rounded-full",
                getOrFail(SocialPlatformBackgroundStyle, SocialPlatform[props.name]),
              )}>
              <SocialIcon
                network={getOrFail(SocialPlatformNetwork, SocialPlatform[props.name])}
                bgColor="rgba(0,0,0,0)"
                className={cn("!h-8", props.name === "Patreon" && "!h-5")}
              />
            </div>
            {props.name}
          </FormLabel>
          <FormControl>
            <Input {...field} value={field.value ?? ""} placeholder={props.placeholder} />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default Profile;
