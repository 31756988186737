import React, { useEffect, useState } from "react";
import { Comment } from "@/Components/Molecules/Comment";
import { CommentInput } from "@/Components/Molecules/CommentInput";
import { Spinner } from "@/Components/Atoms/Spinner";
import { CommentType } from "@/Types/Enums/CommentType";
import { ChatBubbleLeftRightIcon, CheckCircleIcon } from "@heroicons/react/24/outline";
import { CommentSortingLabel } from "@/API/Maps/CommentSorting";
import { useUser } from "@/Hooks/useUser";
import { FormattedNumber } from "@/Components/Atoms/FormattedNumber";
import { Link } from "@/Components/Atoms/Link";
import { route } from "ziggy-js";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from "@/Components/ui/dropdown-menu";
import { Button } from "@/Components/ui/button";
import { ArrowUpDown } from "lucide-react";
import { Breakpoint } from "@/Types/Enums/Breakpoint";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/Components/ui/drawer";
import { useWindowSizeThreshold } from "@/Hooks/useWindowSizeThreshold";
import { Avatar } from "@/Components/Atoms/Avatar";
import { Textarea } from "@/Components/ui/textarea";
import { usePage } from "@inertiajs/react";
import type { BattlestationProps } from "@/Pages/Battlestation/Battlestation";
import { useCommentsContext } from "@/Stores/useCommentsStore";
import { cn } from "@/Utils/shadcn";
import InfiniteScroll from "react-infinite-scroll-component";
import { useBattlestationStore } from "@/Stores/useBattlestationStore";

export const BattlestationComments = () => {
  const { props } = usePage<BattlestationProps>();

  const user = useUser();

  const editing = useBattlestationStore((state) => state.editing);
  const commentsOpen = useBattlestationStore((state) => state.commentsOpen);
  const setCommentsOpen = useBattlestationStore((state) => state.setCommentsOpen);

  const comments = useCommentsContext((state) => state.comments);
  const commentsCount = useCommentsContext((state) => state.commentsCount);
  const commentsSorting = useCommentsContext((state) => state.commentsSorting);
  const hasMore = useCommentsContext((state) => state.hasMore);
  const disabled = useCommentsContext((state) => state.disabled);
  const loadMoreComments = useCommentsContext((state) => state.loadMoreComments);
  const addComment = useCommentsContext((state) => state.addComment);
  const setSorting = useCommentsContext((state) => state.setSorting);

  const [loaded, setLoaded] = useState(!props.commentId);

  const isMobile = useWindowSizeThreshold(Breakpoint.LG);
  const isXs = useWindowSizeThreshold(Breakpoint.XS);

  const topComment = comments?.find((comment) => !comment.deleted);

  useEffect(() => {
    if (loaded || !comments || !props.commentId) return;

    const commentElement = document.getElementById(`comment-${props.commentId}`);

    if (commentElement) {
      commentElement.scrollIntoView({ behavior: "smooth", block: "center" });
      setLoaded(true);
    }
  }, [comments]);

  const Sorting = (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size={isXs ? "sm" : "default"}>
          <ArrowUpDown className="mr-2 size-4" />
          {CommentSortingLabel.get(commentsSorting)}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuRadioGroup
          value={commentsSorting.toString()}
          onValueChange={(value) => setSorting(parseInt(value))}>
          {Array.from(CommentSortingLabel).map(([key]) => (
            <DropdownMenuRadioItem key={key} value={key.toString()}>
              {CommentSortingLabel.get(key)}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );

  const Content = editing ? (
    // skeleton UI for editing
    <div className="flex flex-col gap-6">
      {Array.from({ length: 3 }).map((_, index) => (
        <div key={index} className="flex gap-4">
          {/* Avatar */}
          <div className="h-12 w-12 shrink-0 rounded-md border bg-primary/10" />

          <div className="flex flex-1 flex-col gap-2">
            {/* User info and timestamp */}
            <div className="flex gap-2">
              <div className="h-4 w-24 rounded-md bg-primary/10" />
              <div className="h-4 w-20 rounded-md bg-primary/10" />
            </div>

            {/* Comment text */}
            <div className="h-10 w-full rounded-md bg-primary/10" />

            {/* Buttons */}
            <div className="flex gap-2">
              <div className="h-6 w-16 rounded-md bg-primary/10" />
              <div className="h-6 w-16 rounded-md bg-primary/10" />
            </div>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <div className="flex flex-col gap-6">
      {/* Comment input */}
      {user ? (
        user?.email_verified_at == null ? (
          <div className="flex flex-col gap-2 text-sm text-gray-300">
            <div>You need to verify your email before you can post comments.</div>
            <div>
              Haven't received anything?{" "}
              <Link as="button" href={route("verification.send")} method="post">
                Resend verification email.
              </Link>
            </div>
          </div>
        ) : (
          <CommentInput
            type={CommentType.Comment}
            entity={props.battlestation}
            autoFocus={isMobile && !topComment}
            onSubmit={addComment}
          />
        )
      ) : (
        <div className="text-sm text-gray-300">You must be logged in to post comments.</div>
      )}
      {/* Comments */}
      {comments?.length ? (
        <InfiniteScroll
          style={{ overflow: undefined }}
          dataLength={comments?.length}
          next={() => loadMoreComments()}
          hasMore={hasMore}
          scrollableTarget={isMobile ? "comments-scrollable" : "html"}
          loader={
            <div className="flex justify-center">
              <Spinner />
            </div>
          }
          endMessage={
            <div className="flex flex-col items-center gap-2 py-8 text-sm text-gray-500">
              <CheckCircleIcon className="h-8 w-8" />
              You've reached the end!
            </div>
          }
          className="flex flex-col gap-6">
          {comments.map((comment) => (
            <Comment key={comment.id} comment={comment} />
          ))}
        </InfiniteScroll>
      ) : (
        <div className="relative block w-full rounded-lg border-2 border-dashed border-gray-700 p-8 text-center">
          <ChatBubbleLeftRightIcon className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-100">No comments yet</h3>
          <span className="mt-1 text-sm text-gray-500">Start the conversation...</span>
        </div>
      )}
    </div>
  );

  return isMobile ? (
    <Drawer open={commentsOpen} onOpenChange={setCommentsOpen}>
      <DrawerTrigger>
        <div className="flex rounded-xl border p-4 text-left transition-transform active:scale-[98%]">
          <div className="flex w-full flex-col gap-2">
            <div className="flex items-center gap-2">
              <span>Comments</span>
              <span className="font-normal text-muted-foreground">
                <FormattedNumber number={commentsCount} />
              </span>
            </div>
            {topComment ? (
              <div className="flex gap-4 overflow-hidden">
                <Avatar
                  image={topComment?.author?.avatar}
                  username={topComment?.author?.username}
                  className="size-8 shrink-0"
                />
                <div className="flex flex-col gap-0.5 overflow-hidden">
                  <div className="flex items-center gap-3">
                    <div className="text-sm font-medium">{topComment?.author?.username}</div>
                    <div className="text-xs text-muted-foreground">{topComment?.created_at}</div>
                  </div>
                  <div className="line-clamp-2 text-ellipsis break-words text-sm text-gray-300">
                    {topComment?.body}
                  </div>
                </div>
              </div>
            ) : (
              <div className="pointer-events-none flex items-start gap-4">
                <div className="flex-shrink-0">
                  <Avatar image={user?.avatar} username={user?.username} className="h-8 w-8" />
                </div>
                <Textarea
                  rows={1}
                  placeholder="Start the conversation..."
                  className="min-h-0 resize-none"
                />
              </div>
            )}
          </div>
        </div>
      </DrawerTrigger>
      <DrawerContent
        className={cn(
          "mx-auto max-h-[90%]",
          disabled && "pointer-events-none cursor-default opacity-75",
        )}>
        <DrawerHeader className="flex items-center justify-between">
          <DrawerTitle>
            <div className="flex items-center gap-2">
              <span>Comments</span>
              <span className="font-normal text-muted-foreground">
                <FormattedNumber number={commentsCount} />
              </span>
            </div>
          </DrawerTitle>
          {Sorting}
        </DrawerHeader>
        <div id="comments-scrollable" className="flex flex-col overflow-auto px-4 pb-4 pt-0.5">
          {Content}
        </div>
      </DrawerContent>
    </Drawer>
  ) : (
    <div
      className={cn(
        "flex flex-col gap-2",
        disabled && "pointer-events-none cursor-default opacity-75",
      )}>
      <div id="comments" className="flex scroll-m-20 flex-row items-center justify-between">
        <span className="text-sm font-medium">
          {editing ? (
            "Comments"
          ) : (
            <FormattedNumber
              number={commentsCount}
              suffixSingular="Comment"
              suffixPlural="Comments"
            />
          )}
        </span>
        {editing || Sorting}
      </div>
      {Content}
    </div>
  );
};
