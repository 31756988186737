import React from "react";
import { Head } from "@/Components/Atoms/Head";
import { cn } from "@/Utils/shadcn";
import { Link } from "@inertiajs/react";
import { route } from "ziggy-js";
import { Icon } from "@/Types/Icon";
import { FormBox, FormBoxProps } from "@/Components/Molecules/FormBox";
import { buttonVariants } from "@/Components/ui/button";
import { FieldValues } from "react-hook-form";
import { Container } from "@/Components/Atoms/Container";
import { CircleUserRound, Cog, ExternalLink, KeyRound } from "lucide-react";

type SettingsNaigationItem = {
  name: string;
  href: string;
  active: boolean;
  icon: Icon;
};

type ExternalLinkItem = {
  name: string;
  href: string;
};

export type SettingsLayoutProps<T extends FieldValues> = FormBoxProps<T>;

export const SettingsLayout = <T extends FieldValues>(props: SettingsLayoutProps<T>) => {
  const navigation: SettingsNaigationItem[] = [
    {
      name: "Account",
      href: route("settings.account.index"),
      active: route().current("settings.account.index"),
      icon: Cog,
    },
    {
      name: "Profile",
      href: route("settings.profile.index"),
      active: route().current("settings.profile.index"),
      icon: CircleUserRound,
    },
    {
      name: "Password",
      href: route("settings.password.index"),
      active: route().current("settings.password.index"),
      icon: KeyRound,
    },
  ];

  const externalLinks: ExternalLinkItem[] = [
    {
      name: "User agreement",
      href: route("user-agreement"),
    },
    {
      name: "Code of conduct",
      href: route("code-of-conduct"),
    },
  ];

  return (
    <>
      <Head title="Settings" />
      <Container className="pt-6 text-3xl font-semibold">Settings</Container>
      <div className="mx-auto w-full max-w-6xl px-4 py-6 sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-5 lg:px-8">
        <aside className="mb-6 lg:col-span-3 lg:mb-0">
          <nav>
            <div className="space-y-1">
              {navigation.map((item) => {
                return (
                  <Link
                    key={item.name}
                    href={item.href}
                    aria-current={item.active ? "page" : undefined}
                    className={cn(
                      buttonVariants({
                        variant: item.active ? "secondary" : "ghost",
                      }),
                      "w-full justify-start",
                    )}>
                    <item.icon className="-ml-1 mr-2 size-5 shrink-0" aria-hidden="true" />
                    <span className="truncate">{item.name}</span>
                  </Link>
                );
              })}
            </div>
            <div className="mx-auto my-2 h-0.5 w-24 rounded-full bg-secondary content-[*]"></div>
            <div className="space-y-1">
              {externalLinks.map((item) => {
                return (
                  <a
                    key={item.name}
                    href={item.href}
                    target="_blank"
                    className={cn(buttonVariants({ variant: "ghost" }), "w-full justify-start")}>
                    <ExternalLink className="mr-2 size-5 shrink-0" />
                    <span className="truncate">{item.name}</span>
                  </a>
                );
              })}
            </div>
          </nav>
        </aside>
        <div className="col-span-9">
          {/* I have no idea why the IDE thinks the children prop isn't being passed in without passing it explicitly */}
          <FormBox {...props} children={props.children} />
        </div>
      </div>
    </>
  );
};
