import React, { useState } from "react";
import { FormErrorsAlert } from "@/Components/Molecules/FormErrorsAlert";
import { DialogWrapper } from "@/Components/Molecules/DialogWrapper";
import { Button } from "@/Components/ui/button";
import { create } from "mutative";
import ReactCrop, { centerCrop, Crop, makeAspectCrop, PercentCrop } from "react-image-crop";
import { FormImage } from "@/Types/FormImage";
import { img } from "@/Utils/img";

import "react-image-crop/dist/ReactCrop.css";

export type CropImageDialogProps = {
  aspect: number;
  errors: [string, string][];
  image?: FormImage | null;
  open: boolean;
  crop: Crop | undefined;
  setCrop: (crop: Crop | undefined) => void;
  onOpenChange: (open: boolean) => void;
  onCrop: (crop: Crop) => void;
  onReplaceImage?: () => void;
};

const IMAGE_MIN_WIDTH = 400;
const IMAGE_MIN_HEIGHT = 200;

export const CropImageDialog = (props: CropImageDialogProps) => {
  const [minWidth, setMinWidth] = useState(0);
  const [minHeight, setMinHeight] = useState(0);

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    if (!props.image) return;

    const { naturalWidth, naturalHeight, clientWidth, clientHeight } = e.currentTarget;

    const settings: PercentCrop = {
      unit: "%",
      width: 100,
      height: 100,
      x: 0,
      y: 0,
    };

    if (props.image.crop && !props.crop) {
      props.setCrop(props.image.crop);
    } else if (!props.crop) {
      props.setCrop({
        ...centerCrop(
          makeAspectCrop(settings, props.aspect, naturalWidth, naturalHeight),
          naturalWidth,
          naturalHeight,
        ),
      });
    }

    setMinWidth((clientWidth / naturalWidth) * IMAGE_MIN_WIDTH);
    setMinHeight((clientHeight / naturalHeight) * IMAGE_MIN_HEIGHT);
  };

  if (!props.image) return null;

  return (
    <DialogWrapper
      title="Crop Image"
      description={
        <>
          <span className="sm:hidden">
            {props.onReplaceImage ? (
              <>
                Adjust the crop area to the desired position and size, or{" "}
                <Button variant="link" className="h-auto p-0" onClick={props.onReplaceImage}>
                  replace the image
                </Button>
                .
              </>
            ) : (
              "Adjust the crop area to the desired position and size."
            )}
          </span>
          <span className="hidden sm:block">
            Adjust the crop area to the desired position and size.
          </span>
        </>
      }
      open={props.open}
      setOpen={props.onOpenChange}
      footer={
        <div className="flex w-full flex-col justify-between gap-2 sm:flex-row sm:items-center">
          {props.onReplaceImage ? (
            <Button variant="secondary" className="hidden sm:block" onClick={props.onReplaceImage}>
              Replace Image
            </Button>
          ) : (
            <div />
          )}
          <div className="flex w-full flex-col justify-end gap-2 sm:flex-row sm:items-center">
            <Button
              variant="secondary"
              onClick={() => {
                props.setCrop(undefined);
                props.onOpenChange(false);
              }}>
              Cancel
            </Button>
            <Button
              onClick={async () => {
                if (!props.crop) return;

                const roundedCrop = create(props.crop, (draft) => {
                  draft.x = Math.round(draft.x);
                  draft.y = Math.round(draft.y);
                  draft.width = Math.round(draft.width);
                  draft.height = Math.round(draft.height);
                });

                props.onCrop(roundedCrop);

                props.onOpenChange(false);
              }}>
              Save
            </Button>
          </div>
        </div>
      }
      drawerContentClassName="overflow-visible">
      <div className="mx-auto flex h-full max-w-sm flex-col justify-center gap-4">
        {props.errors.length ? <FormErrorsAlert errors={props.errors} /> : null}
        <div data-vaul-no-drag="" className="relative">
          <ReactCrop
            crop={props.crop}
            onChange={(_, crop) => props.setCrop(crop)}
            className="rounded-md"
            minWidth={minWidth}
            minHeight={minHeight}
            aspect={props.aspect}
            keepSelection>
            <img
              src={img(props.image, undefined, true)}
              alt={props.image.name ?? ""}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        </div>
      </div>
    </DialogWrapper>
  );
};
