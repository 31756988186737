import React, { useState } from "react";
import { Crop as CropIcon } from "lucide-react";
import ReactCrop, { centerCrop } from "react-image-crop";
import { FormImage } from "@/Types/FormImage";
import { create } from "mutative";
import { Button } from "@/Components/ui/button";
import { DialogWrapper } from "@/Components/Molecules/DialogWrapper";
import { useWindowSizeThreshold } from "@/Hooks/useWindowSizeThreshold";
import { Breakpoint } from "@/Types/Enums/Breakpoint";
import { ErrorBadge } from "@/Components/Atoms/ErrorBadge";
import { BattlestationForm } from "@/API/Forms/BattlestationForm";
import { FormErrorsAlert } from "@/Components/Molecules/FormErrorsAlert";
import { useFormErrors } from "@/Hooks/useFormErrors";

import "react-image-crop/dist/ReactCrop.css";
import { img } from "@/Utils/img";

export type BattlestationEditImageCropProps = {
  image?: FormImage;
  name?: `images.${number}`;
  onCrop: (crop: App.Data.CropData) => void;
};

const IMAGE_MIN_WIDTH = 800;
const IMAGE_MIN_HEIGHT = 600;

export const BattlestationImageCrop = (props: BattlestationEditImageCropProps) => {
  const [open, setOpen] = useState(false);
  const [crop, setCrop] = useState<App.Data.CropData>();

  const [minWidth, setMinWidth] = useState(0);
  const [minHeight, setMinHeight] = useState(0);

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    if (!props.image) return;

    const { naturalWidth, naturalHeight, clientWidth, clientHeight } = e.currentTarget;

    if (props.image.crop && !crop) {
      setCrop(props.image.crop);
    } else if (!crop) {
      setCrop({
        ...centerCrop(
          {
            unit: "%",
            width: 100,
            height: 100,
            x: 0,
            y: 0,
          },
          naturalWidth,
          naturalHeight,
        ),
      });
    }

    setMinWidth((clientWidth / naturalWidth) * IMAGE_MIN_WIDTH);
    setMinHeight((clientHeight / naturalHeight) * IMAGE_MIN_HEIGHT);
  };

  const isSmall = useWindowSizeThreshold(Breakpoint.SM);

  const errors = useFormErrors<BattlestationForm>(["images.*.crop"]);

  if (!props.image) return null;

  return (
    <>
      <DialogWrapper
        open={open}
        setOpen={setOpen}
        title="Crop Image"
        description="Adjust the crop area to the desired position and size."
        trigger={
          <Button
            variant="secondary"
            size={isSmall ? "sm" : "default"}
            onClick={() => {
              setCrop(undefined);
            }}
            className="rounded-xl bg-secondary/80 shadow-sm backdrop-blur-sm hover:bg-secondary/60 md:rounded-md">
            <div className="mr-1.5">
              {errors.length ? (
                <ErrorBadge className="size-3.5" />
              ) : (
                <CropIcon className="size-3.5" />
              )}
            </div>
            Crop Image
          </Button>
        }
        footer={
          <>
            <Button
              variant="secondary"
              onClick={() => {
                setCrop(undefined);
                setOpen(false);
              }}>
              Cancel
            </Button>
            <Button
              onClick={async () => {
                if (!crop) return;

                const roundedCrop = create(crop, (draft) => {
                  draft.x = Math.round(draft.x);
                  draft.y = Math.round(draft.y);
                  draft.width = Math.round(draft.width);
                  draft.height = Math.round(draft.height);
                });

                props.onCrop(roundedCrop);

                setOpen(false);
              }}>
              Save
            </Button>
          </>
        }
        drawerContentClassName="overflow-visible">
        {errors.length ? <FormErrorsAlert errors={errors} /> : null}
        <div data-vaul-no-drag="" className="relative">
          <ReactCrop
            crop={crop}
            onChange={(_, crop) => setCrop(crop)}
            className="rounded-md"
            minWidth={minWidth}
            minHeight={minHeight}
            keepSelection>
            <img
              src={img(props.image, undefined, true)}
              alt={props.image.name ?? ""}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        </div>
      </DialogWrapper>
    </>
  );
};
