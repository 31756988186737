import React, { useMemo, useState } from "react";
import { Plus, X } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "@/Components/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/Components/ui/command";
import { Button } from "@/Components/ui/button";
import { FormField } from "@/Components/ui/form";
import { useFormContext, useWatch } from "react-hook-form";
import { BattlestationForm } from "@/API/Forms/BattlestationForm";
import { usePage } from "@inertiajs/react";
import type { BattlestationProps } from "@/Pages/Battlestation/Battlestation";
import { TAG_LIMIT } from "@/Utils/constants";
import { useBattlestationStore } from "@/Stores/useBattlestationStore";

export const BattlestationTags = () => {
  const { props } = usePage<BattlestationProps>();

  const form = useFormContext<BattlestationForm>();

  const editing = useBattlestationStore((state) => state.editing);

  const [open, setOpen] = useState(false);

  const selectedTags = useWatch({ control: form.control, name: "tags" });

  const availableTags = useMemo(() => {
    const selectedIds = selectedTags.map((tag: App.Data.Models.TagData) => tag.id);
    return props.edit?.available_tags?.filter((tag) => !selectedIds.includes(tag.id));
  }, [props.available_tags, selectedTags]);

  const addTag = (tag: App.Data.Models.TagData) => {
    const draft = [...selectedTags, tag];

    form.setValue("tags", draft, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const removeTag = (tag: App.Data.Models.TagData) => {
    const draft = [...selectedTags];

    const index = draft.findIndex((t) => t.id === tag.id);
    if (index !== -1) draft.splice(index, 1);

    form.setValue("tags", draft, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  return (
    <FormField
      control={form.control}
      name="tags"
      render={({ field }) => (
        <div className="flex flex-wrap gap-2">
          {/* TODO: Search by tag when clicked */}
          {(editing ? field.value : props.battlestation.tags ?? []).map(
            (tag: App.Data.Models.TagData) => (
              <div
                key={tag.id}
                className="flex h-6 items-center gap-1 overflow-hidden rounded-md bg-muted px-2 text-xs text-muted-foreground">
                <div>#{tag.name}</div>
                {editing && (
                  <button
                    type="button"
                    className="-mr-0.5 flex h-6 items-center justify-center hover:text-primary"
                    onClick={() => removeTag(tag)}>
                    <X className="size-3.5" />
                  </button>
                )}
              </div>
            ),
          )}
          {editing && (
            <Popover open={open} onOpenChange={setOpen} modal>
              <PopoverTrigger asChild>
                <Button
                  disabled={field.value.length >= TAG_LIMIT}
                  variant="secondary"
                  size="sm"
                  className="h-6">
                  <Plus className="mr-0.5 size-3" />
                  Add tag
                </Button>
              </PopoverTrigger>
              <PopoverContent align="start" className="w-[200px] p-0">
                <Command>
                  <CommandInput placeholder="Search tags..." />
                  <CommandList>
                    <CommandEmpty>No tags found.</CommandEmpty>
                    <CommandGroup>
                      {availableTags?.map((tag) => (
                        <CommandItem
                          key={tag.id}
                          value={tag.name}
                          onSelect={() => {
                            addTag(tag);
                            setOpen(false);
                          }}>
                          #{tag.name}
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
          )}
        </div>
      )}
    />
  );
};
