import React from "react";
import { Spinner } from "@/Components/Atoms/Spinner";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { route } from "ziggy-js";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { create } from "mutative";
import { CheckCheck } from "lucide-react";
import { Notification } from "@/Components/Organisms/Notifications/Notification";
import { Button } from "@/Components/ui/button";
import { useAbortController } from "@/Hooks/useAbortController";
import { usePaginated } from "@/Hooks/usePaginated";

export const Notifications = () => {
  const abortController = useAbortController();

  const { items, setItems, setPage, isLoading, endReached } =
    usePaginated<App.Data.Models.NotificationData>((page) =>
      route("notifications.index", {
        page,
      }),
    );

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between">
        <h2 className="text-lg font-bold">Notifications</h2>
        <Button
          title="Mark all as read"
          variant="ghost"
          size="icon"
          onClick={async () => {
            await axios.post(route("notifications.mark-all-as-read"), {
              signal: abortController?.signal,
            });

            setItems((items) =>
              create(items, (draft) => {
                if (!draft) return;
                draft.data.forEach((notification) => {
                  notification.read = true;
                });
              }),
            );
          }}>
          <span className="sr-only">Mark all as read</span>
          <CheckCheck className="size-5" />
        </Button>
      </div>
      <InfiniteScroll
        dataLength={items?.data.length ?? 0}
        next={() => isLoading || setPage((page) => page + 1)}
        hasMore={!endReached}
        loader={
          <div className="flex justify-center">
            <Spinner />
          </div>
        }
        endMessage={
          <div className="flex flex-col items-center gap-2 py-8 text-sm text-gray-500">
            <CheckCircleIcon className="h-8 w-8" />
            You've reached the end!
          </div>
        }
        height={300}
        className="-mx-2 space-y-0.5 overscroll-contain">
        {items?.data.map((notification) => (
          <Notification key={notification.id} notification={notification} />
        ))}
      </InfiniteScroll>
    </div>
  );
};
