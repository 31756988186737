import React from "react";
import type { SVGProps } from "react";
const SvgSteeringWheel = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="lucide lucide-steering-wheel"
    viewBox="0 0 24 24"
    {...props}>
    <circle cx={12} cy={12} r={10} />
    <path d="m3.3 7 7 4M13.7 11l7-4M12 14v8" />
    <circle cx={12} cy={12} r={2} />
  </svg>
);
export default SvgSteeringWheel;
