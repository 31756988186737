import React from "react";
import { cn } from "@/Utils/shadcn";
import { useCarousel, useDotButton } from "@/Components/ui/carousel";

export const FeaturedBattlestationCarouselDots = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & { isPlaying: boolean }
>(({ className, isPlaying, ...props }, ref) => {
  const { api } = useCarousel();
  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(api);

  return (
    <div
      ref={ref}
      className={cn("-mb-1.5 mt-2 flex items-center justify-center", className)}
      {...props}>
      {scrollSnaps.map((_, index) => (
        <button
          key={index}
          type="button"
          aria-label={`Scroll to slide ${index + 1}`}
          className="cursor-pointer px-[0.2rem] py-1.5"
          onClick={() => onDotButtonClick(index)}>
          <div
            className={cn(
              "size-2 rounded-full bg-gray-600 transition-all duration-500 ease-in-out",
              index === selectedIndex && isPlaying && "w-10",
            )}>
            <div
              className={cn(
                "h-full w-[var(--embla-autoplay-progress)] min-w-2 max-w-full rounded-full bg-gray-600 transition-colors duration-500 ease-in-out",
                index === selectedIndex ? "bg-primary" : "w-full",
              )}
            />
          </div>
        </button>
      ))}
    </div>
  );
});
