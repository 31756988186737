import React, { ReactNode, useEffect } from "react";
import { useFlash } from "@/Hooks/useFlash";
import { ThemeProvider } from "@/Components/ui/theme-provider";
import { TooltipProvider } from "@/Components/ui/tooltip";
import { Toaster } from "@/Components/ui/sonner";
import { ConfirmDialogProvider } from "@/Contexts/ConfirmDialogContext";
import { toast } from "sonner";
import { MomentumModal } from "@/Components/Molecules/MomentumModal";

export type BaseLayoutProps = {
  children?: ReactNode;
};

export const BaseLayout = (props: BaseLayoutProps) => {
  return (
    <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
      <MomentumModal />
      <TooltipProvider>
        <ConfirmDialogProvider>
          <Toaster
            position="bottom-center"
            offset="1rem"
            toastOptions={{
              className: "border bg-background",
            }}
          />
          <FlashMessageHandler>
            <div className="flex min-h-svh flex-col">{props.children}</div>
          </FlashMessageHandler>
        </ConfirmDialogProvider>
      </TooltipProvider>
    </ThemeProvider>
  );
};

type FlashMessageHandlerProps = {
  children: ReactNode;
};

const FlashMessageHandler = (props: FlashMessageHandlerProps) => {
  const flash = useFlash();

  useEffect(() => {
    if (!flash) return;

    if (flash.success) {
      toast.success(flash.success);

      flash.success = "";
    } else if (flash.error) {
      toast.error(flash.error);

      flash.error = "";
    }

    if (flash.clear_auto_save) {
      localStorage.removeItem(flash.clear_auto_save);
      flash.clear_auto_save = "";
    }
  }, [flash]);

  return <>{props.children}</>;
};
