import { useEffect, useRef, useState } from "react";
import { DeepPartialSkipArrayKey, UseFormReturn, useWatch } from "react-hook-form";
import { BattlestationForm } from "@/API/Forms/BattlestationForm";
import dayjs from "dayjs";
import { toast } from "sonner";
import { useFlash } from "@/Hooks/useFlash";
import { get } from "lodash";
import { create } from "mutative";
import { useBattlestationStore } from "@/Stores/useBattlestationStore";

export type BattlestationAutoSaveProps = {
  form: UseFormReturn<BattlestationForm>;
  rememberKey: string | null;
  updatedAt: string | null;
  transform?: (data: BattlestationForm) => BattlestationForm;
};

const CURRENT_VERSION = 2;

export const BattlestationAutoSave = (props: BattlestationAutoSaveProps) => {
  const initialised = useRef(false);
  const restored = useRef(false);
  const flashed = useRef(false);

  const [restoredFormData, setRestoredFormData] = useState<any>(null);
  const [migrationPerformed, setMigrationPerformed] = useState(false);

  const flash = useFlash();

  const watch = useWatch({
    control: props.form.control,
  });

  const editing = useBattlestationStore((state) => state.editing);

  const saveToLocalStorage = () => {
    if (!props.rememberKey) return;

    localStorage.setItem(
      props.rememberKey,
      JSON.stringify({
        ...watch,
        __meta: {
          version: CURRENT_VERSION,
          // if we care about not overriding newer data for this form, we need to store the updatedAt date
          ...(props.updatedAt && {
            updatedAt: new Date().toISOString(),
          }),
        },
      }),
    );
  };

  // if there is data in local storage, restore it after 1 render
  useEffect(() => {
    if (!restoredFormData) return;

    props.form.reset(restoredFormData);

    setRestoredFormData(null);
  }, [restoredFormData]);

  // if we've performed a migration on the data, re-save it to local storage after 2 renders
  useEffect(() => {
    if (!migrationPerformed || restoredFormData) return;

    saveToLocalStorage();

    setMigrationPerformed(false);
  }, [migrationPerformed, restoredFormData]);

  useEffect(() => {
    if (!props.rememberKey || !editing || !restored.current || flashed.current) return;

    toast.success("We've restored your autosaved data.");
    flashed.current = true;
  }, [editing]);

  useEffect(() => {
    if (!props.rememberKey) return;

    if (!initialised.current) {
      initialised.current = true;

      const savedJson = localStorage.getItem(props.rememberKey);
      let savedData = savedJson && JSON.parse(savedJson);

      if (
        savedJson &&
        // make sure we're not restoring data that's older than what's in the database
        (!props.updatedAt ||
          !savedData.__meta?.updatedAt ||
          dayjs(savedData.__meta.updatedAt).isAfter(props.updatedAt)) &&
        // we don't want to restore data if the backend has instructed us to clear the autosave
        flash.clear_auto_save !== props.rememberKey
      ) {
        const willRequireUpdate = (savedData?.__meta?.version ?? 0) < CURRENT_VERSION;

        if (savedData && willRequireUpdate) {
          // add more migrations here as needed
          savedData = migrateToV2(savedData, watch);
          setMigrationPerformed(true);
        }

        if (props.transform) {
          savedData = props.transform(savedData);
        }

        setRestoredFormData(savedData);

        restored.current = true;
      } else {
        // if the conditions above aren't met, we want to clear the autosave
        localStorage.removeItem(props.rememberKey);
        return;
      }
    }

    if (!props.form.formState.isDirty) {
      return;
    }

    saveToLocalStorage();
  }, [watch]);

  return null;
};

const migrateToV2 = (data: any, battlestation: DeepPartialSkipArrayKey<BattlestationForm>) => {
  if (get(data, "__meta.version", 0) >= 2) {
    return data;
  }

  return create(data, (draft) => {
    draft.widgets = battlestation.widgets;

    delete draft.custom_card_fields;

    draft.__meta = {
      ...(draft.__meta || {}),
      version: 2,
    };
  });
};
