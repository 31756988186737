import React, { ReactNode, StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { createInertiaApp, router } from "@inertiajs/react";
import { AppLayout } from "@/Layouts/AppLayout";
import Vapor from "laravel-vapor";
import "../css/app.css";
import { Page } from "@/API/Page";
import dayjs from "dayjs";
import AdvancedFormat from "dayjs/plugin/advancedFormat";
import * as Sentry from "@sentry/react";
import { ResponseBase } from "@/API/ResponseBase";

dayjs.extend(AdvancedFormat);

window.Vapor = Vapor;

Vapor.withBaseAssetUrl(import.meta.env.VITE_VAPOR_ASSET_URL);

if (import.meta.env.VITE_APP_ENV !== "local") {
  Sentry.init({
    dsn: "https://6c381ed0ff6eb51ff77f2a44cb4ff079@o4507810058731520.ingest.us.sentry.io/4507810150940672",
    environment: import.meta.env.VITE_APP_ENV,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/(staging\.)?rigr\.gg/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

type PageType = {
  default: Page<unknown>;
};

globalThis.resolveMomentumModal = (name: string) => {
  const pages = import.meta.glob<PageType>("./Pages/**/*.tsx", { eager: true });
  return pages[`./Pages/${name}.tsx`];
};

createInertiaApp<ResponseBase>({
  resolve: async (name) => {
    const pages = import.meta.glob<PageType>("./Pages/**/*.tsx", { eager: true });
    const page = pages[`./Pages/${name}.tsx`];

    if (page.default.layout === undefined) {
      page.default.layout = (page: ReactNode) => <AppLayout children={page} />;
    }

    return page;
  },
  setup({ el, App, props }) {
    createRoot(el).render(
      <StrictMode>
        <App {...props} />
      </StrictMode>,
    );
  },
  progress: {
    color: "#6366f1",
  },
});

// set sentry user
router.on("navigate", (event) => {
  if (!("auth" in event.detail.page.props)) {
    return;
  }

  const auth = event.detail.page.props.auth as ResponseBase["auth"];

  if (auth.user) {
    Sentry.setUser({
      id: auth.user.id,
      username: auth.user.username,
      email: auth.user.email,
      ip_address: "{{auto}}",
    });
  } else {
    Sentry.setUser(null);
  }
});
