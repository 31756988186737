import React, { ImgHTMLAttributes, useMemo } from "react";
import { cn } from "@/Utils/shadcn";
import { FormImage } from "@/Types/FormImage";
import { Image as ImageIcon } from "lucide-react";
import { ImageTransform } from "@/Types/ImageTransform";
import { img } from "@/Utils/img";

export type DynamicImage = App.Data.Models.ImageData | FormImage | string;

export type ImageProps = ImgHTMLAttributes<HTMLImageElement> & {
  image?: DynamicImage | null;
  transform?: ImageTransform;
  preventCrop?: boolean;
};

export const Image = ({ image, transform, preventCrop, alt, className, ...props }: ImageProps) => {
  if (!image) {
    return (
      <div
        className={cn(
          "flex items-center justify-center overflow-hidden border bg-card",
          className,
        )}>
        <ImageIcon className="size-5" />
      </div>
    );
  }

  const url = useMemo(() => {
    return img(image, transform, preventCrop);
  }, [image, transform, preventCrop]);

  return <img src={url} alt={alt} className={className} {...props} />;
};
