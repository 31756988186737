import React, { useRef, useState } from "react";
import { Motion, spring } from "react-motion";
import { CirclePlus, Crown, Plus } from "lucide-react";
import { Rect } from "@/Types/Rect";
import { cn } from "@/Utils/shadcn";
import { Button } from "@/Components/ui/button";
import { useWidgetsStore } from "@/Stores/useWidgetsStore";
import { calculateSize } from "@/Utils/grid";
import { DialogWrapper } from "@/Components/Molecules/DialogWrapper";
import { WidgetPreview } from "@/Components/Molecules/Battlestation/AddWidget/WidgetPreview";
import { uuid } from "@/Utils/uuid";
import { useIsMobile } from "@/Hooks/useIsMobile";

// Import Swiper React components
import { Pagination } from "swiper/modules";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { WidgetType } from "@/Types/generated_enums";

export type AddWidgetProps = {
  buttonRect: Rect;
  freeWidgets: App.Data.Models.WidgetData[];
  proWidgets: App.Data.Models.WidgetData[];
  showAddButton: boolean;
  onAddWidget: (widget: App.Data.Models.WidgetData, rect: Rect) => void;
};

export const AddWidget = (props: AddWidgetProps) => {
  const unitSize = useWidgetsStore((state) => state.unitSize);
  const padding = useWidgetsStore((state) => state.padding);

  const widgetRefs = useRef<Record<number, HTMLDivElement>>({});

  const selectSizeContainer = useRef<HTMLDivElement>(null);

  const [state, setState] = useState<"default" | "selectWidget" | "selectSize">("default");

  const [selectedWidget, setSelectedWidget] = useState<App.Data.Models.WidgetData | null>(null);
  const [selectedWidth, setSelectedWidth] = useState<number>(1);
  const [hideSelected, setHideSelected] = useState(false);

  const isMobile = useIsMobile();

  if (!props.showAddButton) return null;

  const selectSize = (
    <DialogWrapper
      open={state === "selectSize"}
      setOpen={(open) => {
        if (state !== "selectSize") return;
        if (open) return;

        if (isMobile) {
          setState("selectWidget");
        } else {
          setState("default");
        }
      }}
      footer={
        <div className="flex flex-col items-center px-4">
          <div className="swiper-pagination !static w-full shrink-0 py-2" />
          <Button
            className="w-full"
            onClick={() => {
              if (!selectedWidget) return;

              const element = widgetRefs.current[selectedWidth];
              const rect = element.getBoundingClientRect();

              const widget: App.Data.Models.WidgetData = {
                ...selectedWidget,
                id: uuid(),
                width: selectedWidth,
                ...(selectedWidget.type === WidgetType.Card && {
                  card: {
                    ...selectedWidget.card!,
                    id: uuid(),
                    fields: [],
                  },
                }),
                ...(selectedWidget.type === WidgetType.Image && {
                  ...(selectedWidget.image
                    ? {
                        image: {
                          ...selectedWidget.image!,
                          id: uuid(),
                        },
                      }
                    : {}),
                }),
                ...(selectedWidget.type === WidgetType.Spotlight && {
                  ...(selectedWidget.spotlight
                    ? {
                        spotlight: {
                          name: "",
                          description: "",
                          product_link: "",
                          discount_code: "",
                          image: null,
                        },
                      }
                    : {}),
                }),
              };

              props.onAddWidget(widget, rect);

              setState("default");

              setHideSelected(true);
            }}>
            <CirclePlus className="-ml-0.5 mr-1.5 size-4" />
            Add Widget
          </Button>
        </div>
      }
      title="Select Size"
      description="Select a size for your widget."
      className={cn("max-h-[86%]", isMobile ? "px-0" : "w-auto max-w-none")}>
      {isMobile ? (
        <div ref={selectSizeContainer} className="flex h-full items-center">
          <Swiper
            data-vaul-no-drag
            initialSlide={selectedWidth - 1}
            onSlideChange={(swiper: SwiperClass) => {
              setSelectedWidth(swiper.activeIndex + 1);
            }}
            slidesPerView="auto"
            centeredSlides={true}
            spaceBetween={8}
            watchSlidesProgress={true}
            modules={[Pagination]}
            pagination={{
              el: ".swiper-pagination",
              bulletClass: "w-2 h-2 rounded-full inline-block mx-1.5 bg-gray-600 transition-colors",
              bulletActiveClass: "!bg-gray-100",
            }}
            className="w-full">
            {[1, 2].map((width) => {
              const targetWidth = calculateSize(width, unitSize, padding);

              const previewWidth = Math.min(
                targetWidth,
                selectSizeContainer.current?.clientWidth ?? targetWidth,
              );

              // calculate the preview height to maintain the correct aspect ratio
              // if the previewWidth is less than the targetWidth, figure out the ratio
              const ratio = previewWidth / targetWidth;
              const previewHeight = unitSize * ratio;

              return (
                <SwiperSlide
                  data-vaul-no-drag
                  key={width}
                  style={{
                    width: "fit-content",
                    height: "fit-content",
                  }}>
                  <div
                    ref={(ref) => {
                      if (!ref) return;
                      widgetRefs.current[width] = ref;
                    }}
                    className={cn(
                      "pointer-events-none",
                      hideSelected && selectedWidth === width && "invisible",
                    )}>
                    <WidgetPreview
                      widget={{ ...selectedWidget!, width }}
                      style={{
                        width: `${previewWidth}px`,
                        height: `${previewHeight}px`,
                      }}
                      className="h-32 hover:border-border group-active:scale-[98%] 2xs:h-36 xs:h-40"
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      ) : (
        <div className="flex gap-1">
          {[1, 2].map((width) => (
            <button
              key={width}
              type="button"
              onClick={() => setSelectedWidth(width)}
              className={cn(
                "group rounded-4xl border-2 border-transparent p-2 transition-colors",
                selectedWidth === width && "border-accent",
              )}>
              <div
                ref={(ref) => {
                  if (!ref) return;
                  widgetRefs.current[width] = ref;
                }}
                className={cn(hideSelected && selectedWidth === width && "invisible")}>
                <WidgetPreview
                  widget={{ ...selectedWidget!, width }}
                  className="h-36 hover:border-border group-active:scale-[98%]"
                />
              </div>
            </button>
          ))}
        </div>
      )}
    </DialogWrapper>
  );

  return (
    <>
      <DialogWrapper
        open={isMobile ? state !== "default" : state === "selectWidget"}
        setOpen={(open) => {
          if (open) {
            setState("selectWidget");
          } else {
            setState("default");
          }
        }}
        title="Add Widget"
        description="Select a widget to add to your page."
        className={cn(isMobile || "w-auto max-w-none")}>
        {props.proWidgets.length ? (
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <h2 className="flex items-center gap-1.5 text-xs text-muted-foreground">
                <Crown className="size-3.5 text-amber-400" /> Pro Widgets
              </h2>
              <WidgetGrid
                widgets={props.proWidgets}
                onClick={(widget) => {
                  setSelectedWidget(widget);
                  setSelectedWidth(widget.width);
                  setState("selectSize");
                }}
              />
            </div>
            <div className="flex flex-col gap-2">
              <h2 className="flex items-center gap-1.5 text-xs text-muted-foreground">
                Basic Widgets
              </h2>
              <WidgetGrid
                widgets={props.freeWidgets}
                onClick={(widget) => {
                  setSelectedWidget(widget);
                  setSelectedWidth(widget.width);
                  setState("selectSize");
                }}
              />
            </div>
          </div>
        ) : (
          <WidgetGrid
            widgets={props.freeWidgets}
            onClick={(widget) => {
              setSelectedWidget(widget);
              setSelectedWidth(widget.width);
              setState("selectSize");
            }}
          />
        )}
        {/* render inside for nested drawers if it's a modal */}
        {isMobile && selectSize}
      </DialogWrapper>
      {/* otherwise render outside so the dialog can be shown on its own */}
      {isMobile ? null : selectSize}
      <Motion
        style={{
          x: spring(props.buttonRect.x),
          y: spring(props.buttonRect.y),
        }}>
        {(style) => (
          <button
            type="button"
            style={{
              position: "absolute",
              willChange: "translate",
              translate: `${style.x}px ${style.y}px`,
              width: props.buttonRect.width,
              height: props.buttonRect.height,
            }}
            className="flex items-center justify-center rounded-3xl border-4 border-dashed text-border pointer-fine:transition-[transform,colors] pointer-fine:hover:border-gray-700 pointer-fine:hover:text-gray-700 pointer-fine:active:scale-[98%]"
            onClick={() => {
              setHideSelected(false);
              setState("selectWidget");
            }}>
            <Plus className="size-10" />
          </button>
        )}
      </Motion>
    </>
  );
};

type WidgetGridProps = {
  widgets: App.Data.Models.WidgetData[];
  onClick: (widget: App.Data.Models.WidgetData) => void;
};

const WidgetGrid = (props: WidgetGridProps) => {
  const isMobile = useIsMobile();

  return (
    <div
      className={cn(
        "grid gap-3",
        isMobile ? "grid-cols-2" : "grid-cols-[repeat(4,minmax(theme(spacing.36),1fr))]",
      )}>
      {props.widgets.map((widget) => (
        <button key={widget.id} type="button" onClick={() => props.onClick(widget)}>
          <WidgetPreview
            widget={widget}
            className={cn(isMobile ? "aspect-square size-full" : "size-36")}
          />
        </button>
      ))}
    </div>
  );
};
