import { ElementType } from "react";
import { CustomCardWidget } from "@/Components/Molecules/Battlestation/Widgets/CustomCardWidget/CustomCardWidget";
import { ImageWidget } from "@/Components/Molecules/Battlestation/Widgets/ImageWidget/ImageWidget";
import { WidgetType } from "@/Types/generated_enums";
import { SpotlightWidget } from "@/Components/Molecules/Battlestation/Widgets/SpotlightWidget/SpotlightWidget";

export const WidgetComponentByType = new Map<WidgetType, ElementType>([
  [WidgetType.Card, CustomCardWidget],
  [WidgetType.Image, ImageWidget],
  [WidgetType.Spotlight, SpotlightWidget],
]);
