import React, { ReactNode, useEffect, useState } from "react";
import { cn } from "@/Utils/shadcn";
import { ErrorBadge } from "@/Components/Atoms/ErrorBadge";
import { GridSpaceItem } from "@/Types/Widgets/GridSpaceItem";
import { Button } from "@/Components/ui/button";
import { RectangleHorizontal, Square, Trash2 } from "lucide-react";

export type WidgetLayoutProps = {
  gridConfig?: {
    gridSpaceItem: GridSpaceItem;
    editing: boolean;
    rearranging: boolean;
    initialAnimation: boolean;
    showErrors: boolean;
    onTouchStart: (event: React.TouchEvent) => void;
    onMouseDown: (event: React.MouseEvent) => void;
    onResize: (width: number) => void;
    onDelete: () => void;
  };
  clickable: boolean;
  needsAttention: boolean;
  children: ReactNode;
  onResize?: (width: number) => void;
};

export const WidgetLayout = (props: WidgetLayoutProps) => {
  const [awaitingResizeTrigger, setAwaitingResizeTrigger] = useState(false);

  useEffect(() => {
    // We need to wait for the resize event to be handled by the grid before we can call the onResize callback
    // as the grid items may have changed positions which would cause the callback to run against the wrong widget
    if (awaitingResizeTrigger) {
      if (props.onResize && props.gridConfig?.gridSpaceItem.width) {
        props.onResize(props.gridConfig.gridSpaceItem.width);
      }
      setAwaitingResizeTrigger(false);
    }
  }, [awaitingResizeTrigger, props.gridConfig?.gridSpaceItem.width, props.onResize]);

  if (!props.gridConfig) return <>{props.children}</>;

  return (
    <>
      {/* Pulsing for empty widgets */}
      {props.needsAttention && (
        <div className="absolute size-full animate-ping-xs rounded-3xl bg-gray-800"></div>
      )}
      <div
        className={cn(
          "absolute size-full overflow-hidden rounded-3xl border bg-card shadow-2xl transition-[transform,colors]",
          props.clickable && "cursor-pointer select-none hover:border-gray-700 active:scale-[98%]",
          props.gridConfig.rearranging &&
            "cursor-grab select-none active:scale-100 active:cursor-grabbing",
        )}
        onTouchStart={props.gridConfig.onTouchStart}
        onMouseDown={props.gridConfig.onMouseDown}
        onContextMenu={(event) => {
          if (props.gridConfig!.rearranging) event.preventDefault();
        }}>
        {/* Widget content */}
        {props.children}
        {/* Error badge */}
        {props.gridConfig.showErrors && (
          <div className="pointer-events-none absolute right-3 top-3 z-10">
            <ErrorBadge />
          </div>
        )}
      </div>
      {/* Toolbar */}
      {props.gridConfig &&
        props.gridConfig.editing &&
        !props.gridConfig.rearranging &&
        !props.gridConfig.initialAnimation && (
          <div className="absolute left-1/2 top-2 z-20 flex -translate-x-1/2 items-center gap-1 rounded-lg border bg-background/60 p-1 backdrop-blur-sm transition-[opacity,transform] pointer-fine:bottom-0 pointer-fine:top-auto pointer-fine:opacity-0 pointer-fine:group-hover:translate-y-2 pointer-fine:group-hover:opacity-100">
            <Button
              variant={
                props.gridConfig.gridSpaceItem.width === 1 ? "transparent" : "transparentGhost"
              }
              size="icon"
              className="size-8"
              onClick={() => {
                props.gridConfig!.onResize(1);
                setAwaitingResizeTrigger(true);
              }}>
              <Square className="size-5" />
            </Button>
            <Button
              variant={
                props.gridConfig!.gridSpaceItem.width === 2 ? "transparent" : "transparentGhost"
              }
              size="icon"
              className="size-8"
              onClick={() => {
                props.gridConfig!.onResize(2);
                setAwaitingResizeTrigger(true);
              }}>
              <RectangleHorizontal className="size-5" />
            </Button>
            <div className="h-4 w-0.5 rounded-full bg-gray-600/30"></div>
            <Button
              variant="transparentGhost"
              size="icon"
              className="size-8"
              onClick={props.gridConfig!.onDelete}>
              <Trash2 className="size-5" />
            </Button>
          </div>
        )}
    </>
  );
};
