import { useEffect, useCallback } from "react";
import { FormImage } from "@/Types/FormImage";
import { ImageTransform } from "@/Types/ImageTransform";
import { img } from "@/Utils/img";

type ImageSize = {
  transforms?: ImageTransform;
  preventCrop?: true;
};

export const usePreloadImages = (
  images: Array<App.Data.Models.ImageData | FormImage | null | undefined> | undefined,
  sizes: ImageSize[],
) => {
  const preloadImages = useCallback(
    (imagesToLoad: Array<App.Data.Models.ImageData | FormImage>, size: ImageSize) => {
      return new Promise<void>((resolve) => {
        let loadedCount = 0;
        const totalImages = imagesToLoad.length;

        if (totalImages === 0) {
          resolve();
          return;
        }

        const checkAllLoaded = () => {
          if (loadedCount === totalImages) {
            resolve();
          }
        };

        imagesToLoad.forEach((image) => {
          const imgElement = new Image();
          imgElement.onload = imgElement.onerror = () => {
            loadedCount++;
            checkAllLoaded();
          };
          imgElement.src = img(image, size.transforms, size.preventCrop);
        });
      });
    },
    [sizes],
  );

  useEffect(() => {
    if (!images) return;

    const validImages = images.filter(
      (image): image is NonNullable<(typeof images)[number]> => image != null,
    );

    let isCancelled = false;

    const loadImages = async () => {
      for (const size of sizes) {
        await preloadImages(validImages, size);
        if (isCancelled) return;
      }
    };

    loadImages();

    return () => {
      isCancelled = true;
    };
  }, [images, preloadImages]);
};
