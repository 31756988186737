import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/Components/ui/carousel";
import { FeaturedBattlestationCard } from "@/Components/Organisms/Browse/FeaturedBattlestations/FeaturedBattlestationCard";
import { EmblaCarouselType, EmblaEventType } from "embla-carousel";
import { clamp } from "lodash";
import { scale } from "@/Utils/scale";
import Autoplay from "embla-carousel-autoplay";
import { Container } from "@/Components/Atoms/Container";
import { FeaturedBattlestationCarouselDots } from "@/Components/Organisms/Browse/FeaturedBattlestations/FeaturedBattlestationCarouselDots";
import { useWindowSizeThreshold } from "@/Hooks/useWindowSizeThreshold";
import { Breakpoint } from "@/Types/Enums/Breakpoint";
import { cn } from "@/Utils/shadcn";
import { FeaturedBattlestationsBackground } from "@/Components/Organisms/Browse/FeaturedBattlestations/FeaturedBattlestationsBackground";

const ACTIVE_SCALE = 1.1;
const INACTIVE_OPACITY = 0.75;

const AUTOPLAY_DELAY = 5000;
const PROGRESS_INTERVAL = 25;

const PROGRESS_STEP = 100 / (AUTOPLAY_DELAY / PROGRESS_INTERVAL);

export type FeaturedBattlestationsProps = {
  battlestations: App.Data.Models.BattlestationData[];
};

export const FeaturedBattlestations = (props: FeaturedBattlestationsProps) => {
  const [api, setApi] = useState<CarouselApi>();

  const [isPlaying, setIsPlaying] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const tweenNodes = useRef<HTMLElement[]>([]);

  const intervalRef = useRef<ReturnType<typeof setInterval> | undefined>(undefined);
  const dotsRef = useRef<HTMLDivElement>(null);

  const setTweenNodes = useCallback((emblaApi: EmblaCarouselType): void => {
    tweenNodes.current = emblaApi.slideNodes().map((slideNode) => {
      return slideNode.querySelector(".embla__slide-content") as HTMLElement;
    });
  }, []);

  const tweenScale = useCallback((emblaApi: EmblaCarouselType, eventName?: EmblaEventType) => {
    const engine = emblaApi.internalEngine();
    const scrollProgress = emblaApi.scrollProgress();
    const slidesInView = emblaApi.slidesInView();
    const isScrollEvent = eventName === "scroll";

    emblaApi.scrollSnapList().forEach((scrollSnap, snapIndex) => {
      let diffToTarget = scrollSnap - scrollProgress;
      const slidesInSnap = engine.slideRegistry[snapIndex];

      slidesInSnap.forEach((slideIndex) => {
        if (isScrollEvent && !slidesInView.includes(slideIndex)) return;

        if (engine.options.loop) {
          engine.slideLooper.loopPoints.forEach((loopItem) => {
            const target = loopItem.target();

            if (slideIndex === loopItem.index && target !== 0) {
              const sign = Math.sign(target);

              if (sign === -1) {
                diffToTarget = scrollSnap - (1 + scrollProgress);
              }
              if (sign === 1) {
                diffToTarget = scrollSnap + (1 - scrollProgress);
              }
            }
          });
        }

        // get how far away from the center the current slide is
        // if the current slide is the active slide, then the distance is 0
        // if the current slide is the next slide/previous slide then the distance is 1
        // and everything in between is a float between 0 and 1
        const distance = Math.abs(diffToTarget);
        const limit = 1 / emblaApi.scrollSnapList().length;
        const progress =
          1 - Math.round(scale(clamp(distance, 0, limit), 0, limit, 0, 1) * 1e2) / 1e2;

        const tweenNode = tweenNodes.current[slideIndex];

        tweenNode.dataset.transitionsDisabled = "true";

        tweenNode.style.setProperty("--embla-scale", `${scale(progress, 0, 1, 1, ACTIVE_SCALE)}`);

        tweenNode.style.setProperty(
          "--embla-content-opacity",
          scale(progress, 0, 1, INACTIVE_OPACITY, 1).toString(),
        );

        tweenNode.style.setProperty("--embla-border-opacity", progress.toString());
      });
    });
  }, []);

  const reEnableTransitions = () => {
    tweenNodes.current.forEach((node) => {
      delete node.dataset.transitionsDisabled;
    });
  };

  const getProgress = () => {
    if (!dotsRef.current) return 0;

    return (
      parseFloat(
        dotsRef.current.style.getPropertyValue("--embla-autoplay-progress").replace("%", ""),
      ) || 0
    );
  };

  const setProgress = (value: number) => {
    if (!dotsRef.current) return;

    dotsRef.current.style.setProperty("--embla-autoplay-progress", `${value}%`);
  };

  const handleProgress = () => {
    const currentValue = getProgress();
    const targetValue = currentValue + PROGRESS_STEP;

    setProgress(targetValue);
  };

  const resetProgress = () => {
    setProgress(PROGRESS_STEP);
    api?.plugins().autoplay.reset();
  };

  const onPlay = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    resetProgress();

    intervalRef.current = setInterval(handleProgress, 25);

    setIsPlaying(true);
  };

  const onStop = () => {
    if (!intervalRef.current) return;

    clearInterval(intervalRef.current);
    intervalRef.current = undefined;

    setIsPlaying(false);
  };

  const updateActiveIndex = (emblaApi: EmblaCarouselType) => {
    setActiveIndex(emblaApi.selectedScrollSnap());
  };

  useEffect(() => {
    if (!api) return;

    setTweenNodes(api);
    tweenScale(api);
    onPlay();
    reEnableTransitions();

    // scale events
    api
      .on("reInit", setTweenNodes)
      .on("reInit", tweenScale)
      .on("scroll", tweenScale)
      .on("slideFocus", tweenScale)
      .on("settle", reEnableTransitions)
      .on("reInit", reEnableTransitions);

    // autoplay progress events
    api
      .on("reInit", onPlay)
      .on("autoplay:play", onPlay)
      .on("autoplay:stop", onStop)
      .on("select", resetProgress);

    api.on("select", updateActiveIndex);
  }, [api, tweenScale]);

  const isTablet = useWindowSizeThreshold(Breakpoint.MD);

  return (
    <div className="relative">
      <FeaturedBattlestationsBackground
        battlestations={props.battlestations}
        activeIndex={activeIndex}
      />
      <Container className="flex flex-col gap-6 overflow-visible sm:pt-5">
        <Carousel
          setApi={setApi}
          plugins={[
            Autoplay({
              delay: 5000,
              stopOnMouseEnter: true,
              stopOnFocusIn: false,
              stopOnInteraction: false,
            }),
          ]}
          opts={{
            align: isTablet ? "start" : "center",
            loop: true,
          }}
          className="pb-2 pt-5 sm:pb-3">
          <CarouselContent
            wrapperClassName="-mx-1 px-1 overflow-visible lg:overflow-hidden"
            className="-mb-2 -ml-7 select-none overflow-visible pt-4 sm:-mb-2 sm:-ml-12 md:-ml-9 md:mb-0 xs:-mb-1"
            style={{ backfaceVisibility: "hidden" }}>
            {props.battlestations.map((battlestation, index) => (
              <CarouselItem
                key={battlestation.id}
                className={cn(
                  "aspect-[4/3] flex-shrink-0 flex-grow-0 basis-full pl-7 sm:basis-1/2 sm:pl-12 md:basis-1/3 md:pl-9",
                  index === activeIndex && "z-10",
                )}>
                <div
                  className="embla__slide-content origin-left scale-100 will-change-transform sm:scale-[var(--embla-scale)] md:origin-center"
                  data-transitions-disabled="true">
                  <FeaturedBattlestationCard
                    battlestation={battlestation}
                    isActive={index === activeIndex}
                    isPlaying={isPlaying}
                  />
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
          <div className="flex items-center justify-center gap-10 sm:justify-between">
            <FeaturedBattlestationCarouselDots
              ref={dotsRef}
              isPlaying={isPlaying}
              className="m-0 basis-3/12 justify-start"
            />
            <div className="hidden basis-3/12 items-center justify-end gap-2 sm:flex">
              <CarouselPrevious className="static translate-y-0" />
              <CarouselNext className="static translate-y-0" />
            </div>
          </div>
        </Carousel>
      </Container>
    </div>
  );
};
