import { z } from "zod";

export type FQDNOptions = {
  allowedDomains: string | string[];
  allowSubdomains?: boolean;
  requireHttps?: boolean;
  platformName?: string;
};

export type FQDNValidationResult = [boolean, string];

export const validateFQDN = (value: string, options: FQDNOptions): FQDNValidationResult => {
  const {
    allowedDomains,
    allowSubdomains = true,
    requireHttps = true,
    platformName = "this platform",
  } = options;

  if (!value) return [true, ""];

  const url = value.trim().toLowerCase();
  if (!url) return [true, ""];

  const allowedDomainsArray = Array.isArray(allowedDomains) ? allowedDomains : [allowedDomains];

  try {
    const urlObj = new URL(url);

    if (requireHttps && urlObj.protocol !== "https:") {
      return [false, `The URL must use HTTPS for ${platformName}.`];
    }

    const domain = urlObj.hostname;

    for (const allowedDomain of allowedDomainsArray) {
      if (domain === allowedDomain) {
        return [true, ""];
      }
      if (allowSubdomains && domain.endsWith(`.${allowedDomain}`)) {
        return [true, ""];
      }
    }

    return [false, `The URL domain must be valid for ${platformName}.`];
  } catch (error) {
    return [false, `The URL must be a valid URL.`];
  }
};

// Function to create a custom FQDN schema
export const createFQDNSchema = (options: FQDNOptions) => {
  return z.string().refine(
    (value: string) => validateFQDN(value, options)[0],
    (value: string) => ({
      message: validateFQDN(value, options)[1],
    }),
  );
};
