import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";
import { NavbarMobileMenu } from "@/Components/Organisms/Navbar/NavbarMobileMenu";
import { NavbarUserNavigation } from "@/Components/Organisms/Navbar/NavbarUserNavigation";
import { InertiaLinkProps, Link, usePage } from "@inertiajs/react";
import { ResponseBase } from "@/API/ResponseBase";
import { Logo } from "@/Components/Atoms/Logo";
import { Icon } from "@/Types/Icon";
import { route } from "ziggy-js";
import { cn } from "@/Utils/shadcn";
import { Badge } from "@/Components/Atoms/Badge";
import { Discord } from "@/Components/Atoms/Icons";
import { NavbarNotifications } from "@/Components/Organisms/Navbar/NavbarNotifications";
import { buttonVariants } from "@/Components/ui/button";

export type NavigationItem = {
  name: string;
  href: string;
  active: boolean;
};

export type UserNavigationItem = {
  name: string;
  icon: Icon;
  href: string;
  active: boolean;
  method?: InertiaLinkProps["method"];
  external?: boolean;
};

export type NavbarProps = {
  navigation: NavigationItem[];
  userNavigation: UserNavigationItem[][];
};

export const Navbar = (props: NavbarProps) => {
  const {
    props: { auth },
  } = usePage<ResponseBase>();

  const [logoSpinning, setLogoSpinning] = useState(false);

  return (
    <>
      <Disclosure
        as="nav"
        className="fixed left-0 top-0 z-40 w-screen border-b border-gray-800 bg-background/80 backdrop-blur-md">
        {({ open, close }) => (
          <>
            <div className="mx-auto max-w-6xl px-2 sm:px-4 lg:px-8">
              <div className="relative flex h-16 items-center justify-between">
                <div className="flex items-center px-2 lg:px-0">
                  {/* Logo */}
                  <div className="flex-shrink-0">
                    <Link
                      href="/"
                      className="group flex items-center gap-2"
                      aria-label="rigr.gg"
                      onMouseEnter={() => setLogoSpinning(true)}>
                      <Logo
                        className="h-9 w-9 transition-transform group-active:scale-95"
                        svgClassName={cn(logoSpinning && "animate-spin-once")}
                        onAnimationEnd={() => setLogoSpinning(false)}
                      />
                      <h1 className="-mt-0.5 text-2xl font-bold text-white">rigr.gg</h1>
                    </Link>
                  </div>
                  <Badge variant="info" text="Beta" className="ml-2" />
                  {/* Navigation */}
                  <div className="hidden lg:ml-6 lg:block">
                    <div className="flex gap-2">
                      {props.navigation.map((item) => {
                        return (
                          <Link
                            key={item.href}
                            href={item.href}
                            aria-current={item.active ? "page" : undefined}
                            className={buttonVariants({
                              variant: item.active ? "transparent" : "transparentGhost",
                            })}>
                            {item.name}
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                </div>
                {/* TODO: Search */}
                {/*<NavbarSearchInput />*/}
                <div className="flex lg:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button
                    className={buttonVariants({
                      variant: "transparentGhost",
                      size: "icon",
                    })}>
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="pointer-events-none block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="pointer-events-none block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                {auth.user ? (
                  <div className="hidden lg:ml-4 lg:block">
                    <div className="flex items-center gap-4">
                      <a
                        href="https://discord.gg/jRVNHdBFzn"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={buttonVariants({ variant: "transparent" })}>
                        <Discord className="mr-2 size-5" />
                        Join the Discord
                      </a>
                      {!auth.user.battlestations_count && (
                        <Link
                          href={route("battlestation.create")}
                          className={buttonVariants({ variant: "gradient" })}>
                          Create your battlestation
                        </Link>
                      )}
                      <NavbarNotifications />
                      <NavbarUserNavigation userNavigation={props.userNavigation} />
                    </div>
                  </div>
                ) : (
                  <div className="hidden gap-2 lg:flex">
                    <a
                      href="https://discord.gg/jRVNHdBFzn"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={buttonVariants({ variant: "transparent" })}>
                      <Discord className="mr-2 size-5" />
                      Join the Discord
                    </a>
                    <Link
                      href={route("login")}
                      preserveState
                      preserveScroll
                      className={buttonVariants({ variant: "transparent" })}>
                      Sign in
                    </Link>
                    <Link
                      href={route("register")}
                      className={buttonVariants({ variant: "gradient" })}>
                      Create account
                    </Link>
                  </div>
                )}
              </div>
            </div>

            <NavbarMobileMenu
              close={close}
              navigation={props.navigation}
              userNavigation={props.userNavigation}
            />
          </>
        )}
      </Disclosure>
    </>
  );
};
