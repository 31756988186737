import React from "react";
import { Head } from "@/Components/Atoms/Head";
import { AuthenticationLayout } from "@/Layouts/AuthenticationLayout";
import { route } from "ziggy-js";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/Components/ui/form";
import { Button } from "@/Components/ui/button";
import { Input } from "@/Components/ui/input";

export type ResetPasswordProps = {
  token: string;
  email: string;
};

const formSchema = z.object({
  token: z.string(),
  email: z.string().email(),
  password: z.string(),
  password_confirmation: z.string(),
});

const ResetPassword = (props: ResetPasswordProps) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      token: props.token,
      email: props.email,
      password: "",
      password_confirmation: "",
    },
  });

  return (
    <>
      <Head title="Reset password" />
      <AuthenticationLayout
        heading="Reset password"
        subheading={
          <>
            Enter your new password below to reset the password of user{" "}
            <strong>{props.email}</strong>
          </>
        }>
        <Form {...form} route={route("password.reset.store")} className="space-y-4">
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>New password</FormLabel>
                <FormControl>
                  <Input
                    type="password"
                    placeholder="Enter new password..."
                    autoComplete="new-password"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="password_confirmation"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Confirm new password</FormLabel>
                <FormControl>
                  <Input
                    type="password"
                    placeholder="Confirm new password..."
                    autoComplete="new-password"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button type="submit" className="w-full">
            Reset password
          </Button>
        </Form>
      </AuthenticationLayout>
    </>
  );
};

export default ResetPassword;
