import { z } from "zod";
import { fieldSchema } from "@/Schemas/fieldSchema";
import { idSchema } from "@/Schemas/idSchema";
import { CardType } from "@/Types/generated_enums";

export const cardSchema = z.object({
  id: idSchema,
  type: z.nativeEnum(CardType),
  type_label: z.string(),
  name: z.string().nullable(),
  description: z.string().nullable(),
  fields: z.array(fieldSchema),
});
