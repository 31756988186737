import { z } from "zod";
import { idSchema } from "@/Schemas/idSchema";
import { FieldType } from "@/Types/generated_enums";

export const fieldSchema = z.object({
  id: idSchema,
  type: z.nativeEnum(FieldType),
  type_label: z.string(),
  body: z
    .string()
    .min(2, "Field name must be at least 2 characters.")
    .max(100, "Field name must be no more than 100 characters."),
  product_link: z.string().url("Link to product must be a valid URL.").or(z.literal("")),
  fields: z.array(
    z.object({
      id: idSchema,
      type: z.nativeEnum(FieldType),
      type_label: z.string(),
      body: z
        .string()
        .min(2, "Subfield name must be at least 2 characters.")
        .max(100, "Subfield name must be no more than 100 characters."),
      product_link: z.string().url("Link to product must be a valid URL.").or(z.literal("")),
    }),
  ),
});
