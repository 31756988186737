import React, { AnchorHTMLAttributes, forwardRef } from "react";

export const AnchorLink = forwardRef<HTMLAnchorElement, AnchorHTMLAttributes<HTMLAnchorElement>>(
  ({ onClick, ...props }, ref) => {
    return (
      <a
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          document
            .getElementById(e.currentTarget.href.split("#")[1])
            ?.scrollIntoView({ behavior: "smooth" });
        }}
        {...props}
      />
    );
  },
);
