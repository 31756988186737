import * as React from "react";
import * as LabelPrimitive from "@radix-ui/react-label";
import { Slot } from "@radix-ui/react-slot";
import {
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
  FormProvider,
  FormProviderProps,
  SubmitHandler,
  useFormContext,
  UseFormReturn,
} from "react-hook-form";
import { cn } from "@/Utils/shadcn";
import { Label } from "@/Components/ui/label";
import { FormHTMLAttributes, ForwardedRef, forwardRef, ReactNode } from "react";
import { router } from "@inertiajs/react";
import { VisitOptions } from "@inertiajs/core";
import { DEFAULT_FORM_OPTIONS } from "@/Utils/constants";
import { create } from "mutative";
import { values } from "lodash";
import { getFilteredErrors } from "@/Utils/getFilteredErrors";

type FormOptions = Omit<VisitOptions, "method"> & {
  method?: "get" | "post" | "put" | "patch" | "delete";
  transform?: (data: any) => any;
};

/**
 * Create an onSubmit handler for a react-hook-form form
 * The handler will either submit the form using Inertia or call the provided onSubmit function,
 * and provides error handling and form state updates
 */
export const createOnSubmitHandler =
  <
    TFieldValues extends FieldValues,
    TContext = any,
    TTransformedValues extends FieldValues | undefined = undefined,
  >(
    form: UseFormReturn<TFieldValues, TContext, TTransformedValues>,
    routeOrCallback:
      | string
      | ((data: TFieldValues, resolve: () => void, reject: () => void) => void),
    options?: FormOptions,
  ): SubmitHandler<TFieldValues> =>
  (data: TFieldValues) =>
    new Promise<void>((resolve, reject) => {
      if (options?.transform) {
        data = create(data, options.transform as any);
      }
      if (typeof routeOrCallback === "function") {
        routeOrCallback(data, resolve, reject);
      } else {
        const method = options?.method || "post";

        options = options ?? {};
        const { method: _, transform: __, onError, ...inertiaOptions } = options;

        router[method](routeOrCallback, data as any, {
          ...DEFAULT_FORM_OPTIONS,
          onError: (errors) => {
            Object.entries(errors).forEach(([field, message]) => {
              form.setError(field as any, { type: "manual", message });
            });
            onError?.(errors);
          },
          onFinish: () => {
            resolve();
          },
          ...inertiaOptions,
        });
      }
    });

type FormProps<
  TFieldValues extends FieldValues,
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
> = Omit<
  FormProviderProps<TFieldValues, TContext, TTransformedValues> &
    FormHTMLAttributes<HTMLFormElement>,
  "children" | "onSubmit"
> & {
  children: ReactNode | ReactNode[];
  omitFormTag?: boolean;
  route?: string;
  onSubmit?: (data: TFieldValues, resolve: () => void, reject: () => void) => void;
  options?: FormOptions;
};

const Form = forwardRef(
  <
    TFieldValues extends FieldValues,
    TContext = any,
    TTransformedValues extends FieldValues | undefined = undefined,
  >(
    {
      watch,
      getValues,
      getFieldState,
      setError,
      clearErrors,
      setValue,
      trigger,
      formState,
      resetField,
      reset,
      handleSubmit,
      unregister,
      control,
      register,
      setFocus,
      onSubmit,
      omitFormTag,
      route,
      options,
      ...props
    }: FormProps<TFieldValues, TContext, TTransformedValues>,
    ref: ForwardedRef<HTMLFormElement>,
  ) => {
    if (!route && !onSubmit) {
      throw new Error("You must provide either a route or an onSubmit function");
    }

    const form: UseFormReturn<TFieldValues, TContext, TTransformedValues> = {
      watch,
      getValues,
      getFieldState,
      setError,
      clearErrors,
      setValue,
      trigger,
      formState,
      resetField,
      reset,
      handleSubmit,
      unregister,
      control,
      register,
      setFocus,
    };

    const onSubmitHandler = createOnSubmitHandler(form, (route || onSubmit)!, options);

    return (
      <FormProvider {...form}>
        {omitFormTag ? (
          props.children
        ) : (
          <form
            ref={ref}
            className="space-y-4"
            onSubmit={form.handleSubmit(onSubmitHandler as any)}
            {...props}
          />
        )}
      </FormProvider>
    );
  },
) as <
  TFieldValues extends FieldValues,
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
>(
  props: FormProps<TFieldValues, TContext, TTransformedValues> & { ref?: any },
) => JSX.Element;

type FormFieldContextValue<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  name: TName;
};

const FormFieldContext = React.createContext<FormFieldContextValue>({} as FormFieldContextValue);

const FormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  ...props
}: ControllerProps<TFieldValues, TName>) => {
  return (
    <FormFieldContext.Provider value={{ name: props.name }}>
      <Controller {...props} />
    </FormFieldContext.Provider>
  );
};

const useFormField = (name?: string) => {
  const fieldContext = React.useContext(FormFieldContext);
  const itemContext = React.useContext(FormItemContext);
  const { getFieldState, formState } = useFormContext();

  const fieldState = getFieldState(name ?? fieldContext.name, formState);

  if (!fieldContext) {
    throw new Error("useFormField should be used within <FormField>");
  }

  const { id } = itemContext;

  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState,
  };
};

type FormItemContextValue = {
  id: string;
};

const FormItemContext = React.createContext<FormItemContextValue>({} as FormItemContextValue);

const FormItem = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const id = React.useId();

    return (
      <FormItemContext.Provider value={{ id }}>
        <div ref={ref} className={cn("space-y-1", className)} {...props} />
      </FormItemContext.Provider>
    );
  },
);
FormItem.displayName = "FormItem";

const FormLabel = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ className, ...props }, ref) => {
  const { error, formItemId } = useFormField();

  return (
    <Label
      ref={ref}
      className={cn(error && "text-destructive", className)}
      htmlFor={formItemId}
      {...props}
    />
  );
});
FormLabel.displayName = "FormLabel";

const FormControl = React.forwardRef<
  React.ElementRef<typeof Slot>,
  React.ComponentPropsWithoutRef<typeof Slot>
>(({ ...props }, ref) => {
  const { error, formItemId, formDescriptionId, formMessageId } = useFormField();

  return (
    <Slot
      ref={ref}
      id={formItemId}
      aria-describedby={!error ? `${formDescriptionId}` : `${formDescriptionId} ${formMessageId}`}
      aria-invalid={!!error}
      {...props}
    />
  );
});
FormControl.displayName = "FormControl";

const FormDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => {
  const { formDescriptionId } = useFormField();

  return (
    <p
      ref={ref}
      id={formDescriptionId}
      className={cn("text-[0.8rem] text-muted-foreground", className)}
      {...props}
    />
  );
});
FormDescription.displayName = "FormDescription";

const FormMessage = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
  const { error, formMessageId } = useFormField();
  let body = error?.message ?? children;

  if (!body) {
    body = values(getFilteredErrors(error as any, ["**"]))[0];
  }

  if (!body) {
    return null;
  }

  return (
    <p
      ref={ref}
      id={formMessageId}
      className={cn("text-[0.8rem] font-medium text-destructive", className)}
      {...props}>
      {body}
    </p>
  );
});
FormMessage.displayName = "FormMessage";

export {
  useFormField,
  Form,
  FormItem,
  FormLabel,
  FormControl,
  FormDescription,
  FormMessage,
  FormField,
};
