import React from "react";
import { Avatar } from "@/Components/Atoms/Avatar";
import { Link } from "@inertiajs/react";
import axios from "axios";
import { route } from "ziggy-js";
import { buttonVariants } from "@/Components/ui/button";
import { cn } from "@/Utils/shadcn";
import { PopoverClose } from "@radix-ui/react-popover";

export type NotificationProps = {
  notification: App.Data.Models.NotificationData;
};

export const Notification = (props: NotificationProps) => {
  return (
    <PopoverClose asChild>
      <Link
        href={props.notification.destination}
        onBefore={async () => {
          if (!props.notification.read) {
            await axios.post(route("notifications.mark-as-read", props.notification.id));
          }
        }}
        className={cn(
          buttonVariants({
            variant: "ghost",
          }),
          "flex h-auto w-full min-w-0 items-start justify-start whitespace-normal px-2 py-2 text-left font-normal active:scale-[98%]",
        )}>
        <div className="h-8 w-8 shrink-0">
          <Avatar image={props.notification.avatar_url} username={props.notification.username} />
        </div>
        <div className="ml-3 flex min-w-0 flex-1 flex-col items-start gap-0.5 overflow-hidden">
          <div
            className="line-clamp-2 max-w-full break-words text-sm leading-tight"
            title={props.notification.body}>
            {props.notification.body}
          </div>
          <div className="text-xs text-gray-400">{props.notification.created_at}</div>
        </div>
        {props.notification.read || (
          <div className="mx-2 flex min-w-0 py-2">
            <div className="h-3 w-3 rounded-full bg-blue-500" />
          </div>
        )}
      </Link>
    </PopoverClose>
  );
};
