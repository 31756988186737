import React from "react";
import { FormattedNumber } from "@/Components/Atoms/FormattedNumber";

export type PointsProps = {
  points: number;
  appendSuffix?: boolean;
};

export const Points = ({ points, appendSuffix = true }: PointsProps) => {
  return (
    <FormattedNumber
      number={points}
      suffixSingular={appendSuffix ? "point" : undefined}
      suffixPlural={appendSuffix ? "points" : undefined}
    />
  );
};
