import React, { ComponentPropsWithoutRef, createElement, forwardRef } from "react";
import { cn } from "@/Utils/shadcn";
import { WidgetComponentByType } from "@/API/Maps/WidgetComponentByType";
import { WidgetPropsBase } from "@/Types/Widgets/WidgetPropsBase";

export type WidgetPreviewProps = ComponentPropsWithoutRef<"div"> & {
  widget: App.Data.Models.WidgetData;
};

export const WidgetPreview = forwardRef<HTMLDivElement, WidgetPreviewProps>(
  ({ widget, className, ...props }, ref) => {
    const widgetProps: WidgetPropsBase<any> = {
      widget,
      preview: true,
    };

    const WidgetComponent = WidgetComponentByType.get(widget.type);
    if (!WidgetComponent) {
      console.error(
        `Tried to render preview of unknown widget type ${widget.type}. Add it to the WidgetComponentByType map.`,
      );
      return null;
    }

    return (
      <div
        ref={ref}
        className={cn(
          "relative overflow-hidden rounded-3xl border bg-gray-900 text-left shadow-2xl transition-[transform,colors] hover:border-gray-700 active:scale-[98%]",
          className,
        )}
        {...props}>
        {createElement(WidgetComponent, widgetProps)}
      </div>
    );
  },
);
