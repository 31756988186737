import React, { useEffect, useRef } from "react";
import { Head } from "@/Components/Atoms/Head";
import { Container } from "@/Components/Atoms/Container";
import { BattlestationComments } from "@/Components/Organisms/Battlestation/BattlestationComments";
import { usePreloadImages } from "@/Hooks/usePreloadImages";
import { BattlestationDetails } from "@/Components/Organisms/Battlestation/BattlestationDetails";
import { BattlestationWidgets } from "@/Components/Organisms/Battlestation/BattlestationWidgets";
import { BattlestationRelated } from "@/Components/Organisms/Battlestation/BattlestationRelated";
import { BattlestationToolbar } from "@/Components/Organisms/Battlestation/BattlestationToolbar";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  DefaultValues,
  FieldErrors,
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { createOnSubmitHandler } from "@/Components/ui/form";
import { route } from "ziggy-js";
import { BattlestationForm, BattlestationFormSchema } from "@/API/Forms/BattlestationForm";
import { pickArray } from "@/Utils/pick";
import { pipe } from "@/Utils/pipe";
import { purgeUUIDs } from "@/Utils/purgeUUIDs";
import { getFilteredErrors } from "@/Utils/getFilteredErrors";
import { values } from "lodash";
import { toast } from "sonner";
import { cn } from "@/Utils/shadcn";
import { BattlestationTutorial } from "@/Components/Organisms/Battlestation/BattlestationTutorial";
import { useIsMobile } from "@/Hooks/useIsMobile";
import { BattlestationAutoSave } from "@/Components/Organisms/Battlestation/BattlestationAutoSave";
import { CommentsProvider } from "@/Stores/useCommentsStore";
import { useBattlestationStore } from "@/Stores/useBattlestationStore";
import { BattlestationUserBanner } from "@/Components/Organisms/Battlestation/BattlestationUserBanner";
import { NamedTransformation } from "@/API/NamedTransformation";

export type BattlestationProps = {
  battlestation: App.Data.Models.BattlestationData;
  relatedBattlestations: App.Data.Models.BattlestationData[];
  shouldDisplayUserBanner: boolean;
  commentId?: number;
  parentCommentId?: number;
  edit?: {
    can: {
      add_product_links: boolean;
    };
    card_definitions: App.Data.CardDefinitionData[];
    available_tags: App.Data.Models.TagData[];
    available_widgets: {
      free: App.Data.Models.WidgetData[];
      pro: App.Data.Models.WidgetData[];
    };
    remember_key: string;
  };
};

const Battlestation = (props: BattlestationProps) => {
  usePreloadImages(props.battlestation.images!, [
    // cropped and scaled down
    {
      transforms: {
        named: NamedTransformation.ImageRegular,
      },
    },
    // cropped larger version for zooming
    {
      transforms: {
        named: NamedTransformation.ImageLarge,
      },
    },
    // uncropped full size
    {
      preventCrop: true,
    },
  ]);

  const canEdit = "edit" in props;

  const isDraft = useRef(props.battlestation.published_at === null);

  const draftResolver = zodResolver(BattlestationFormSchema.partial());
  const defaultResolver = zodResolver(BattlestationFormSchema);

  const defaultValues: DefaultValues<BattlestationForm> = {
    name: props.battlestation.name,
    about: props.battlestation.about,
    images: props.battlestation.images!,
    tags: props.battlestation.tags!,
    widgets: props.battlestation.widgets!,
  };

  const form = useForm<BattlestationForm>({
    resolver: (...args) => (isDraft.current ? draftResolver(...args) : defaultResolver(...args)),
    defaultValues,
    mode: "onBlur",
  });

  const init = useBattlestationStore((state) => state.init);
  const editing = useBattlestationStore((state) => state.editing);
  const setEditing = useBattlestationStore((state) => state.setEditing);
  const setCommentsOpen = useBattlestationStore((state) => state.setCommentsOpen);
  const setWidgetsExpanded = useBattlestationStore((state) => state.setWidgetsExpanded);
  const setRearranging = useBattlestationStore((state) => state.setRearranging);

  const isMobile = useIsMobile();

  useEffect(() => {
    if (props.commentId && isMobile) {
      setCommentsOpen(true);
    }

    if (!props.battlestation.published_at) {
      setEditing(true);
    }

    return () => {
      init();
    };
  }, []);

  const onFormError = (errors: FieldErrors<BattlestationForm>) => {
    setRearranging(false);

    const messages = values(
      getFilteredErrors(errors, ["**"], (key, message) => {
        if (key.match(/^widgets\..+\.card.*/)) {
          const typeLabel: string =
            form.getValues(key.split(".").slice(0, 3).join(".") as "widgets.0.card")?.type_label ??
            "";
          return `${typeLabel}: ${message}`;
        }
        return message;
      }),
    );

    if (messages.length === 1) {
      toast.error(messages[0]);
    } else {
      toast.error(`Please fix ${messages.length} errors and try again.`);
    }
  };

  type SubmitOptions<
    TFieldValues extends FieldValues,
    TContext = any,
    TTransformedValues extends FieldValues | undefined = undefined,
  > = Parameters<typeof createOnSubmitHandler<TFieldValues, TContext, TTransformedValues>>[2];

  const submitOptions: SubmitOptions<BattlestationForm> = {
    method: "put",
    transform: (data) =>
      Object.assign(data, {
        images: pipe(data.images)
          .through(pickArray, ["id", "key", "name", "crop", "upload_response"])
          .through(purgeUUIDs).value,
        tags: pickArray(data.tags, ["id", "name"]),
        widgets: pipe(data.widgets)
          .through(pickArray, [
            "id",
            "type",
            "width",
            "height",
            /* card fields */
            "card.id",
            "card.type",
            "card.name",
            "card.description",
            "card.fields.*.id",
            "card.fields.*.type",
            "card.fields.*.body",
            "card.fields.*.product_link",
            "card.fields.*.fields.*.id",
            "card.fields.*.fields.*.type",
            "card.fields.*.fields.*.body",
            "card.fields.*.fields.*.product_link",
            /* image fields */
            "image.id",
            "image.key",
            "image.name",
            "image.crop",
            "image.upload_response",
            /* spotlight fields */
            "spotlight.name",
            "spotlight.description",
            "spotlight.product_link",
            "spotlight.discount_code",
            "spotlight.image.id",
            "spotlight.image.key",
            "spotlight.image.name",
            "spotlight.image.crop",
            "spotlight.image.upload_response",
          ])
          .through(purgeUUIDs).value,
      }),
    onSuccess: () => {
      setEditing(false);
    },
    onError: onFormError,
  };

  const saveHandler: SubmitHandler<BattlestationForm> = (data, event) => {
    isDraft.current = true;

    setRearranging(false);
    setWidgetsExpanded(false);

    return createOnSubmitHandler(
      form,
      route("battlestation.update", props.battlestation),
      submitOptions,
    )(data, event);
  };

  const publishHandler: SubmitHandler<BattlestationForm> = (data, event) => {
    isDraft.current = false;

    setRearranging(false);
    setWidgetsExpanded(false);

    return createOnSubmitHandler(
      form,
      route("battlestation.publish", props.battlestation),
      submitOptions,
    )(data, event);
  };

  return (
    <>
      <Head title={props.battlestation.name} />
      <CommentsProvider
        battlestation={props.battlestation}
        focusedCommentId={props.commentId}
        parentCommentId={props.parentCommentId}
        commentsCount={props.battlestation.comments_count!}>
        <FormProvider {...form}>
          {/* auto save is isolated as its own component to prevent large re-renders */}
          <BattlestationAutoSave
            form={form}
            rememberKey={props.edit?.remember_key ?? null}
            updatedAt={props.battlestation.updated_at}
          />
          <BattlestationTutorial />
          {/* Toolbar */}
          {canEdit && (
            <form>
              <BattlestationToolbar
                rememberKey={props.edit?.remember_key}
                onSave={form.handleSubmit(saveHandler, onFormError)}
                onPublish={form.handleSubmit(publishHandler, onFormError)}
              />
            </form>
          )}
          {/* Main content */}
          <Container className="py-6">
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-6 lg:grid lg:grid-cols-12">
                {/* Full width column */}
                <div className="col-span-12 flex flex-col gap-6">
                  <BattlestationUserBanner />
                  {/* Carousel and details */}
                  <BattlestationDetails />
                </div>
                {/* Main column */}
                <div className="col-span-8 flex flex-col-reverse gap-4 md:gap-6 lg:flex-col">
                  {/* Widgets */}
                  <BattlestationWidgets defaultValues={defaultValues} />
                  {/* Comments */}
                  <div className={cn("flex flex-col gap-4", editing && "hidden lg:flex")}>
                    <BattlestationComments />
                  </div>
                </div>
                {/* Secondary column */}
                <div
                  className={cn(
                    "col-span-4 flex flex-col gap-6 lg:pl-3",
                    editing && "hidden lg:flex",
                  )}>
                  {/* Related battlestations */}
                  <BattlestationRelated />
                </div>
              </div>
            </div>
          </Container>
        </FormProvider>
      </CommentsProvider>
    </>
  );
};

export default Battlestation;
