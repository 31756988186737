import { assign, isEmpty, isObject, reduce } from "lodash";

export const flattenObject = (
  obj: Record<string, any>,
  prefix = "",
  predicate?: (key: string, value: any) => boolean,
) =>
  reduce<object, Record<string, any>>(
    obj,
    (acc, value, key) => {
      const newKey = prefix ? `${prefix}.${key}` : key;

      if (isObject(value) && !isEmpty(value)) {
        assign(acc, flattenObject(value, newKey, predicate));
      } else if (!isEmpty(value)) {
        if (predicate?.(newKey, value) ?? true) {
          acc[newKey] = value;
        }
      }

      return acc;
    },
    {},
  );
