import React from "react";
import { Head as InertiaHead } from "@inertiajs/react";

export type HeadProps = {
  title: string;
};

export const Head = (props: HeadProps) => {
  return <InertiaHead title={`${props.title} | rigr.gg`} />;
};
