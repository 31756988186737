import React, { forwardRef, InputHTMLAttributes, useState } from "react";
import { cn } from "@/Utils/shadcn";
import { DocumentPlusIcon } from "@heroicons/react/24/outline";
import { Spinner } from "@/Components/Atoms/Spinner";

export type FileInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> & {
  url?: string;
  uploading?: boolean;
  onChange: (files: File[]) => void;
};

export const FileInput = forwardRef<HTMLInputElement, FileInputProps>(
  ({ name, onChange, uploading, url, className, ...props }, ref) => {
    const [hover, setHover] = useState(false);

    return (
      <div className="relative flex-1">
        {uploading && (
          <div className="absolute inset-0 z-10 flex cursor-not-allowed items-center justify-center">
            <div className="flex gap-4 rounded-md bg-gray-900/75 p-4 backdrop-blur-sm">
              <Spinner />
              <div className="text-sm">Uploading...</div>
            </div>
          </div>
        )}
        <div className="sm:border-gray-200">
          <div className="mt-1 sm:mt-0">
            <div
              className={cn(
                "flex h-40 items-center justify-center rounded-xl border-2 border-dashed p-2",
                uploading && "opacity-50",
                hover && !uploading ? "border-gray-600" : "border-gray-700",
                className,
              )}>
              {url ? (
                <div className="h-full w-full">
                  <img
                    src={url}
                    alt=""
                    className="h-full w-full rounded-md object-cover object-center"
                  />
                </div>
              ) : (
                <div className="space-y-1 text-center">
                  <DocumentPlusIcon className="mx-auto h-12 w-12 text-gray-600" />
                  <div className="flex text-sm text-gray-500">
                    <label
                      htmlFor={name}
                      tabIndex={1}
                      className={cn(
                        "relative cursor-pointer rounded-md font-medium text-indigo-300 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-300 focus-within:ring-offset-2 focus-within:ring-offset-gray-800 hover:opacity-75",
                      )}>
                      <span>
                        Upload a file
                        <span className="sr-only">, {name}</span>
                      </span>
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs text-gray-500">PNG, JPEG up to 15MB</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <input
          {...props}
          ref={ref}
          id={name}
          name={name}
          type="file"
          accept="image/png, image/jpeg"
          className={cn(
            "absolute inset-0 w-full cursor-pointer opacity-0",
            uploading || props.disabled ? "cursor-not-allowed" : "cursor-pointer",
          )}
          tabIndex={-1}
          onInput={async (e) => {
            const target = e.target as HTMLInputElement;
            if (!target.files?.length) return;

            const files = Array.from(target.files);
            target.value = "";

            onChange(files);
          }}
          onDragEnter={() => setHover(true)}
          onDragLeave={() => setHover(false)}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        />
      </div>
    );
  },
);
