import React, { useRef, useState } from "react";
import { Button } from "@/Components/ui/button";
import { Plus, Sparkles } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/Components/ui/command";
import { FieldTypeIcon } from "@/API/Maps/FieldType";
import { uuid } from "@/Utils/uuid";
import { PopoverWrapper } from "@/Components/Molecules/PopoverWrapper";
import { cn } from "@/Utils/shadcn";
import { DETAIL_LIMIT } from "@/Utils/constants";
import { useIsMobile } from "@/Hooks/useIsMobile";

export type AddDetailProps = {
  card: App.Data.Models.CardData;
  fields: App.Data.Models.FieldData[];
  availableFields: App.Data.FieldDefinitionData[];
  fieldCount: number;
  onAdd: (field: App.Data.Models.FieldData) => void;
};

export const AddDetail = (props: AddDetailProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [open, setOpen] = useState(false);

  const isMobile = useIsMobile();

  return (
    <PopoverWrapper
      open={open}
      setOpen={setOpen}
      trigger={
        <Button
          ref={buttonRef}
          disabled={props.fieldCount >= DETAIL_LIMIT}
          variant="outline"
          className="w-full">
          <Plus className="mr-1.5 size-4" />
          Add detail ({props.fieldCount}/{DETAIL_LIMIT})
        </Button>
      }
      className="p-0">
      <Command>
        <CommandInput placeholder="Start typing to filter details" />
        <CommandList className={cn(isMobile && "max-h-full overflow-y-auto")}>
          <CommandEmpty>No details found.</CommandEmpty>
          <CommandGroup>
            {props.availableFields.map((field) => {
              const Icon = FieldTypeIcon.get(field.type) ?? Sparkles;

              return (
                <CommandItem
                  key={field.type}
                  value={[field.type_label, ...field.aliases].join(" ")}
                  onSelect={() => {
                    setOpen(false);

                    setTimeout(
                      () => {
                        props.onAdd({
                          id: uuid(),
                          type: field.type,
                          type_label: field.type_label,
                          body: "",
                          product_link: "",
                          fields: [],
                        });
                      },
                      // timeout to allow the field to be auto focsued after the drawer has closed
                      isMobile ? 400 : 0,
                    );
                  }}
                  className={cn(isMobile && "py-2 text-base")}>
                  <div className="flex items-center gap-1.5">
                    <Icon className="size-3.5" />
                    {field.type_label}
                  </div>
                </CommandItem>
              );
            })}
          </CommandGroup>
        </CommandList>
      </Command>
    </PopoverWrapper>
  );
};
