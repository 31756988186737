import React from "react";
import { DialogWrapper } from "@/Components/Molecules/DialogWrapper";
import { useUploadImages } from "@/Hooks/useUploadImages";
import { useFormErrors } from "@/Hooks/useFormErrors";
import { BattlestationForm } from "@/API/Forms/BattlestationForm";
import { FormErrorsAlert } from "@/Components/Molecules/FormErrorsAlert";
import { FileInput } from "@/Components/Atoms/FileInput";
import { useFormContext } from "react-hook-form";

export type UploadImageDialogProps = {
  name: `widgets.${number}`;
  image?: App.Data.Models.ImageData;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onUploaded: () => void;
};

export const UploadImageDialog = (props: UploadImageDialogProps) => {
  const form = useFormContext<BattlestationForm>();

  const [uploading, upload] = useUploadImages(() => {
    const aspect = form.getValues(`${props.name}.width`);
    return [
      {
        width: aspect === 1 ? "200" : "400",
        height: "200",
      },
    ];
  });

  const errors = useFormErrors<BattlestationForm>([props.name]);

  return (
    <DialogWrapper
      title="Upload Image"
      description="Upload an image for the widget to display."
      open={props.open}
      setOpen={props.onOpenChange}
      className="h-auto">
      <div className="flex flex-col gap-4">
        {errors.length ? <FormErrorsAlert errors={errors} /> : null}
        <FileInput
          uploading={uploading}
          onChange={async (files) => {
            const newImages = await upload(files, 1);
            if (newImages) {
              form.setValue(`${props.name}.image`, newImages[0]);
              props.onUploaded();
            }
          }}
        />
      </div>
    </DialogWrapper>
  );
};

export default UploadImageDialog;
