import React from "react";
import { FieldTypeIcon } from "@/API/Maps/FieldType";
import { ExternalLink, Sparkles, X } from "lucide-react";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/Components/ui/form";
import { InlineEdit } from "@/Components/Atoms/InlineEdit";
import { Button, buttonVariants } from "@/Components/ui/button";
import { FieldPathByValue, useFormContext } from "react-hook-form";
import { BattlestationForm } from "@/API/Forms/BattlestationForm";
import { useBattlestationStore } from "@/Stores/useBattlestationStore";
import { EditProductLink } from "@/Components/Molecules/Battlestation/Widgets/CustomCardWidget/EditProductLink";
import { cn } from "@/Utils/shadcn";

export type SubfieldProps = {
  name: FieldPathByValue<BattlestationForm, App.Data.Models.SubFieldData>;
  field: App.Data.Models.SubFieldData;
  onRemove: () => void;
};

export const Subfield = (props: SubfieldProps) => {
  const form = useFormContext<BattlestationForm>();

  const editing = useBattlestationStore((state) => state.editing);

  const Icon = FieldTypeIcon.get(props.field.type) ?? Sparkles;

  return (
    <div key={props.field.id} className="flex w-full flex-col gap-1">
      {editing ? (
        <>
          <FormField
            control={form.control}
            name={`${props.name}.body`}
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <div className="relative self-start text-muted-foreground">
                  <FormLabel className="flex items-center gap-1.5 text-sm font-bold">
                    <Icon className="size-3.5" />
                    {props.field.type_label}
                  </FormLabel>
                </div>
                <div className="mt-1 flex items-center gap-2">
                  <FormControl>
                    <InlineEdit
                      className="m-0 text-sm font-bold"
                      placeholder="Enter a name or model..."
                      {...field}
                    />
                  </FormControl>
                  <Button
                    variant="outline"
                    size="icon"
                    className="size-8 shrink-0"
                    onClick={props.onRemove}>
                    <X className="size-4" />
                  </Button>
                </div>
                <FormMessage />
              </FormItem>
            )}
          />
          <EditProductLink name={`${props.name}.product_link`} />
        </>
      ) : (
        <>
          <div className="flex items-center justify-between">
            <div className="relative flex items-center gap-1.5 text-sm font-bold text-muted-foreground">
              <Icon className="size-3.5" />
              {props.field.type_label}
            </div>
            {props.field.product_link && (
              <a
                href={props.field.product_link}
                target="_blank"
                rel="noopener noreferrer"
                className={cn(
                  buttonVariants({ variant: "link" }),
                  "h-auto p-0 text-sm text-muted-foreground hover:text-primary",
                )}>
                Buy now <ExternalLink className="ml-1.5 size-3.5" />
              </a>
            )}
          </div>
          <div className="break-words text-sm font-bold">{props.field.body}</div>
        </>
      )}
    </div>
  );
};
