import { RefObject, useEffect, useState } from "react";

export const useTruncate = (ref: RefObject<any>, deps: any[] = []) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const [isShowingMore, setIsShowingMore] = useState(false);
  const [needsUpdate, setNeedsUpdate] = useState(false);

  useEffect(() => {
    if (needsUpdate) {
      setNeedsUpdate(false);
    }

    const { offsetHeight, scrollHeight } = ref.current || {};

    if (!offsetHeight || !scrollHeight) {
      setNeedsUpdate(true);
      return;
    }

    if (offsetHeight && scrollHeight && offsetHeight < scrollHeight) {
      setIsTruncated(true);
    } else {
      setIsTruncated(false);
    }
  }, [ref, needsUpdate, ...deps]);

  return {
    isTruncated,
    isShowingMore,
    setIsShowingMore,
  };
};
