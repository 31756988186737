import React, { ComponentPropsWithoutRef, useLayoutEffect, useMemo, useRef, useState } from "react";
import useResizeObserver from "@react-hook/resize-observer";
import { clamp } from "@/Utils/clamp";
import { scale } from "@/Utils/scale";
import { throttle } from "lodash";

export type GradientScrollProps = ComponentPropsWithoutRef<"div"> & {
  offset?: number;
  start?: number;
};

export const GradientScroll = ({ offset = 25, start = 90, ...props }: GradientScrollProps) => {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);

  useResizeObserver(scrollContainerRef.current, (entry) => onResizeHandler(entry.contentRect));

  useLayoutEffect(() => {
    if (!scrollContainerRef.current) return;
    setScrollTop(scrollContainerRef.current.scrollTop);
    setScrollHeight(scrollContainerRef.current.scrollHeight);
    setClientHeight(scrollContainerRef.current.clientHeight);
  }, [
    scrollContainerRef.current,
    scrollContainerRef.current?.scrollTop,
    scrollContainerRef.current?.scrollHeight,
    scrollContainerRef.current?.clientHeight,
  ]);

  const onScrollHandler = throttle((event: React.UIEvent<HTMLElement>) => {
    if (!event.currentTarget) return;
    setScrollTop(event.currentTarget.scrollTop);
    setScrollHeight(event.currentTarget.scrollHeight);
    setClientHeight(event.currentTarget.clientHeight);
  }, 100);

  const onResizeHandler = throttle((rect: DOMRectReadOnly) => {
    setClientHeight(rect.height);
  }, 100);

  const maskImage = useMemo(() => {
    const distanceFromTop = scrollTop;
    const distanceFromBottom = scrollHeight - clientHeight - scrollTop;

    const topOpacity = 100 - clamp(scale(distanceFromTop, 0, offset, 0, 100), 0, 100);

    const bottomOpacity = 100 - clamp(scale(distanceFromBottom, 0, offset, 0, 100), 0, 100);

    const topMask = `linear-gradient(to top, rgba(0, 0, 0, 1.0) ${start}%, rgba(0, 0, 0, ${topOpacity}%) 100%)`;
    const bottomMask = `linear-gradient(to bottom, rgba(0, 0, 0, 1.0) ${start}%, rgba(0, 0, 0, ${bottomOpacity}%) 100%)`;

    return `${topMask}, ${bottomMask}`;
  }, [scrollTop, scrollHeight, clientHeight]);

  return (
    <div
      {...props}
      ref={scrollContainerRef}
      onScroll={onScrollHandler}
      style={{
        maskImage,
        maskComposite: "intersect",
      }}
    />
  );
};
