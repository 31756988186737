import create from "zustand";

type State = {
  editing: boolean;
  rearranging: boolean;
  commentsOpen: boolean;
  widgetsExpanded: boolean;
};

type Actions = {
  init: () => void;
  setEditing: (editing: boolean) => void;
  setRearranging: (rearranging: boolean) => void;
  setCommentsOpen: (commentsOpen: boolean) => void;
  setWidgetsExpanded: (widgetsExpanded: boolean) => void;
};

const defaultState: State = {
  editing: false,
  rearranging: false,
  commentsOpen: false,
  widgetsExpanded: false,
};

export const useBattlestationStore = create<State & Actions>()((set) => ({
  ...defaultState,

  init: () => set(defaultState),

  setEditing: (editing) => {
    if (!editing) {
      set({ rearranging: false });
    }
    set({ editing });
  },
  setRearranging: (rearranging) => set({ rearranging }),
  setCommentsOpen: (commentsOpen) => set({ commentsOpen }),
  setWidgetsExpanded: (widgetsExpanded) => set({ widgetsExpanded }),
}));
