import React from "react";
import { Link } from "@/Components/Atoms/Link";
import { route } from "ziggy-js";
import { Validator } from "@/Components/Atoms/Validator";
import { z } from "zod";
import { useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/Components/ui/form";
import { Input } from "@/Components/ui/input";
import { Checkbox } from "@/Components/ui/checkbox";
import { Button } from "@/Components/ui/button";
import { PasswordInput } from "@/Components/ui/password-input";
import { useMomentumModal } from "@/Hooks/useMomentumModal";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/Components/ui/dialog";

const formSchema = z.object({
  username: z.string(),
  email: z.string(),
  password: z.string(),
  remember_me: z.boolean(),
  subscribe: z.boolean(),
});

const Register = () => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: "",
      email: "",
      password: "",
      remember_me: false,
      subscribe: true,
    },
  });

  const password = useWatch({ control: form.control, name: "password" });

  const { show, close, redirect } = useMomentumModal();

  return (
    <Dialog
      open={show}
      onOpenChange={(open) => {
        if (!open) {
          // We call close() first to make closing the dialog feel more responsive
          close();
          redirect();
        }
      }}>
      <DialogContent className="max-h-[94%] max-w-sm overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Create a new account</DialogTitle>
          <DialogDescription>
            Or{" "}
            <Link href={route("login")} preserveState preserveScroll>
              sign in to your existing account
            </Link>
          </DialogDescription>
        </DialogHeader>
        <Form
          {...form}
          route={route("register.store")}
          options={{
            preserveState: true,
            preserveScroll: true,
          }}
          className="space-y-4">
          <FormField
            control={form.control}
            name="username"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Username</FormLabel>
                <FormControl>
                  <Input placeholder="Enter username..." autoComplete="username" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input placeholder="Enter email..." autoComplete="email" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Password</FormLabel>
                <FormControl>
                  <PasswordInput
                    placeholder="Enter password..."
                    autoComplete="new-password"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="space-y-1 text-sm">
            <div className="mb-2 text-gray-300">Your password must:</div>
            <Validator
              description="Be at least 8 characters"
              isValid={() => (password.length ?? 0) >= 8}
            />
            <Validator
              description="Contain a mix of lowercase and uppercase"
              isValid={() => /[a-z]/.test(password) && /[A-Z]/.test(password)}
            />
            <Validator
              description="Contain a mix of letters and numbers"
              isValid={() => /\d/.test(password)}
            />
          </div>
          <FormField
            control={form.control}
            name="remember_me"
            render={({ field }) => (
              <FormItem>
                <div className="flex items-center gap-2">
                  <FormControl>
                    <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                  <FormLabel className="font-normal">Remember me</FormLabel>
                </div>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="subscribe"
            render={({ field }) => (
              <FormItem>
                <div className="flex items-center gap-2">
                  <FormControl>
                    <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                  <FormLabel className="font-normal">Receive updates via email</FormLabel>
                </div>
                <FormDescription>
                  We won't spam you or sell your details. We'll only ever use your email address to
                  let you know when we release new stuff.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type="submit" className="w-full">
            Create account
          </Button>
          <div className="text-sm text-gray-300">
            By creating an account, you agree to our{" "}
            <Link
              href={route("user-agreement")}
              className="font-medium text-indigo-400 hover:text-indigo-300">
              User Agreement and Privacy Policy
            </Link>
            .
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default Register;
