import React, { Dispatch, SetStateAction } from "react";
import { useFormContext, useFormState } from "react-hook-form";
import { TextareaAutosize } from "@/Components/ui/textarea";
import { CreateLayout } from "@/Layouts/CreateLayout";
import { CreateForm } from "@/API/Forms/CreateForm";
import { FormControl, FormField, FormItem, FormMessage } from "@/Components/ui/form";
import { Separator } from "@/Components/ui/separator";
import { Button } from "@/Components/ui/button";
import { Import } from "lucide-react";
import { useIsMobile } from "@/Hooks/useIsMobile";
import { Badge } from "@/Components/ui/badge";

export type StepOneProps = {
  setStep: Dispatch<SetStateAction<number>>;
};

const StepOne = (props: StepOneProps) => {
  const form = useFormContext<CreateForm>();

  const state = useFormState({
    control: form.control,
    name: "stepOne.details",
    exact: true,
  });

  const isMobile = useIsMobile();

  return (
    <CreateLayout
      step={1}
      form={form}
      dirty={state.isDirty}
      onNext={async () => {
        let isValid = await form.trigger("stepOne.details");
        if (isValid) {
          props.setStep((step) => step + 1);
        }
      }}
      onBack={() => {
        props.setStep((step) => step - 1);
      }}>
      <div className="flex flex-col items-center gap-6">
        <div className="flex flex-col items-center gap-2">
          <h2 className="text-balance text-center text-2xl font-bold leading-tight tracking-tight">
            Add your setup details{" "}
            <Badge variant="outline" className="ml-2 inline-block align-middle">
              Beta
            </Badge>
          </h2>
          <p className="text-balance text-center text-sm text-muted-foreground">
            Auto-add your gear and specs from plain-text, or import from a PCPartPicker build.
          </p>
        </div>
        <div className="flex w-full flex-col items-center gap-6">
          <FormField
            control={form.control}
            name="stepOne.details"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormControl>
                  <TextareaAutosize
                    minRows={4}
                    maxRows={isMobile ? 4 : 6}
                    placeholder="Example format:&#10;CPU: Intel Core i7-9700K&#10;Microphone: Blue Yeti&#10;..."
                    className="resize-none"
                    {...field}
                  />
                </FormControl>
                <FormMessage className="text-center" />
              </FormItem>
            )}
          />
          <Separator text className="after:content-['or']" />
          <div className="flex w-full flex-col items-center gap-2">
            <Button className="w-full sm:w-auto" disabled>
              <Import className="mr-1.5 size-3.5" />
              Import from PCPartPicker
            </Button>
            <span className="text-xs text-muted-foreground">Coming soon!</span>
          </div>
        </div>
      </div>
    </CreateLayout>
  );
};

export default StepOne;
