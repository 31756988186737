import React, { ReactNode } from "react";
import { Drawer, DrawerContent, DrawerFooter, DrawerTrigger } from "@/Components/ui/drawer";
import { cn } from "@/Utils/shadcn";
import { Popover, PopoverContent, PopoverTrigger } from "@/Components/ui/popover";
import { useIsMobile } from "@/Hooks/useIsMobile";

export type PopoverWrapperProps = {
  children: ReactNode;
  drawerFooter?: ReactNode;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  className?: string;
  trigger?: ReactNode;
  nested?: boolean;
};

export const PopoverWrapper = (props: PopoverWrapperProps) => {
  const isMobile = useIsMobile();

  return isMobile ? (
    <Drawer nested={props.nested} open={props.open} onOpenChange={props.setOpen}>
      {props.trigger && <DrawerTrigger asChild>{props.trigger}</DrawerTrigger>}
      <DrawerContent
        className={cn("mx-auto h-full max-h-[90%] max-w-sm bg-popover px-4", props.className)}>
        <div className="h-full overflow-auto">{props.children}</div>
        {props.drawerFooter && <DrawerFooter className="px-0">{props.drawerFooter}</DrawerFooter>}
      </DrawerContent>
    </Drawer>
  ) : (
    <Popover open={props.open} onOpenChange={props.setOpen} modal>
      {props.trigger && <PopoverTrigger asChild>{props.trigger}</PopoverTrigger>}
      <PopoverContent onCloseAutoFocus={(e) => e.preventDefault()} className={cn(props.className)}>
        {props.children}
      </PopoverContent>
    </Popover>
  );
};
