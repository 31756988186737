import React, { ReactNode } from "react";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/Components/ui/drawer";
import { cn } from "@/Utils/shadcn";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/Components/ui/dialog";
import { useIsMobile } from "@/Hooks/useIsMobile";

export type DialogWrapperProps = {
  title: string;
  description?: ReactNode;
  children: ReactNode;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  className?: string;
  dialogClassName?: string;
  drawerClassName?: string;
  drawerContentClassName?: string;
  preventClickOutside?: boolean;
  trigger?: ReactNode;
  footer?: ReactNode;
  nested?: boolean;
};

export const DialogWrapper = (props: DialogWrapperProps) => {
  const isMobile = useIsMobile();

  return isMobile ? (
    <Drawer nested={props.nested} open={props.open} onOpenChange={props.setOpen}>
      {props.trigger && <DrawerTrigger asChild>{props.trigger}</DrawerTrigger>}
      <DrawerContent
        onPointerDownOutside={(e) => {
          if (props.preventClickOutside) {
            e.preventDefault();
          }
        }}
        className={cn("mx-auto h-full max-h-[90%] px-4", props.className, props.drawerClassName)}>
        <DrawerHeader>
          <DrawerTitle>{props.title}</DrawerTitle>
          {props.description && (
            <DrawerDescription className="text-balance">{props.description}</DrawerDescription>
          )}
        </DrawerHeader>
        <div className={cn("h-full overflow-auto pb-4 pt-1", props.drawerContentClassName)}>
          {props.children}
        </div>
        {props.footer && <DrawerFooter className="px-0">{props.footer}</DrawerFooter>}
      </DrawerContent>
    </Drawer>
  ) : (
    <Dialog open={props.open} onOpenChange={props.setOpen}>
      {props.trigger && <DialogTrigger asChild>{props.trigger}</DialogTrigger>}
      <DialogContent
        onPointerDownOutside={(e) => {
          if (props.preventClickOutside) {
            e.preventDefault();
          }
        }}
        className={cn("max-h-[94%] overflow-y-auto", props.className, props.dialogClassName)}>
        <DialogHeader>
          <DialogTitle>{props.title}</DialogTitle>
          {props.description && <DialogDescription>{props.description}</DialogDescription>}
        </DialogHeader>
        {props.children}
        {props.footer && <DialogFooter>{props.footer}</DialogFooter>}
      </DialogContent>
    </Dialog>
  );
};
