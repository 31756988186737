import React from "react";
import { BattlestationCardSmall } from "@/Components/Molecules/BattlestationCardSmall";
import { Flame } from "lucide-react";
import { usePage } from "@inertiajs/react";
import type { BattlestationProps } from "@/Pages/Battlestation/Battlestation";
import { cn } from "@/Utils/shadcn";
import { useBattlestationStore } from "@/Stores/useBattlestationStore";

export const BattlestationRelated = () => {
  const { props } = usePage<BattlestationProps>();

  const editing = useBattlestationStore((state) => state.editing);

  if (editing)
    // skeleton UI for editing
    return (
      <div className="flex flex-col gap-2">
        <h2 className="flex items-center gap-1 text-xs text-muted-foreground">
          <Flame className="size-3.5" /> Hot Setups
        </h2>
        <div className="flex flex-col gap-3">
          {Array.from({ length: 9 }).map((_, index) => (
            // skeleton
            <div
              key={index}
              className={cn(
                "items-center justify-between rounded-2xl border p-2",
                index > 2 && "hidden lg:flex",
              )}>
              <div className="flex items-center">
                {/* image */}
                <div className="aspect-square h-24 shrink-0 rounded-lg border bg-primary/10 shadow-xl sm:aspect-[4/3]"></div>
                <div className="flex flex-col justify-center px-4">
                  <div className="mb-0.5 flex items-center gap-1.5">
                    {/* avatar */}
                    <div className="h-4 w-4 rounded-full bg-primary/10" />
                    <div className="h-3 w-14 rounded-md bg-primary/10" />
                  </div>
                  {/* battlestation name */}
                  <div className="mt-1.5 h-6 w-28 rounded-md bg-primary/10" />
                  <div className="mt-2 flex items-center gap-2 text-xs leading-tight text-muted-foreground">
                    {/* likes and comments */}
                    <div className="h-4 w-8 rounded-md bg-primary/10" />
                    <span className="text-xs text-primary/10">&bull;</span>
                    <div className="h-4 w-8 rounded-md bg-primary/10" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );

  return (
    <div className="flex flex-col gap-2">
      <h2 className="flex items-center gap-1 text-xs text-muted-foreground">
        <Flame className="size-3.5" /> Hot Setups
      </h2>
      <div className="flex flex-col gap-3">
        {props.relatedBattlestations.map((related) => (
          <BattlestationCardSmall key={related.id} battlestation={related} />
        ))}
      </div>
    </div>
  );
};
