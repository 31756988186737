import React from "react";
import { BadgeCheck, LucideProps } from "lucide-react";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/Components/ui/tooltip";
import { Wrap } from "@/Components/Atoms/Wrap";
import { cn } from "@/Utils/shadcn";
import { TooltipPortal } from "@radix-ui/react-tooltip";

export type VerifiedBadgeProps = LucideProps & {
  for: App.Data.Models.UserData;
  withoutTooltip?: boolean;
};

export const VerifiedBadge = ({
  for: user,
  withoutTooltip,
  className,
  ...props
}: VerifiedBadgeProps) => {
  if (!user.verified) return null;

  return (
    <Tooltip>
      <Wrap
        if={!withoutTooltip}
        with={(children) => <TooltipTrigger asChild>{children}</TooltipTrigger>}>
        <BadgeCheck strokeWidth={3} className={cn("text-indigo-200", className)} {...props} />
      </Wrap>
      {/*
        the portal is needed if the badge is displayed inside a div with a mask-image set,
        such as within the BattlestationDetails component, otherwise the entire div displays
        as a solid black rectangle. not sure why this is happening but portalling the content
        into the body is a quick fix
      */}
      <TooltipPortal>
        <TooltipContent className="font-normal">Verified account</TooltipContent>
      </TooltipPortal>
    </Tooltip>
  );
};
