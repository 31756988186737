import React from "react";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";

export type ValidatorProps = {
  description: string;
  isValid: () => boolean;
};

export const Validator = (props: ValidatorProps) => {
  return (
    <div className="flex items-center gap-2">
      <div className={props.isValid() ? "text-green-400" : "text-red-400"}>
        {props.isValid() ? (
          <CheckCircleIcon className="h-5 w-5" />
        ) : (
          <XCircleIcon className="h-5 w-5" />
        )}
      </div>
      <div>{props.description}</div>
    </div>
  );
};
