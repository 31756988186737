import { usePage } from "@inertiajs/react";
import { ResponseBase } from "@/API/ResponseBase";

export const useFlash = () => {
  const {
    props: { flash },
  } = usePage<ResponseBase>();

  return flash;
};
