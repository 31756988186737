import React, { forwardRef, InputHTMLAttributes } from "react";
import { cn } from "@/Utils/shadcn";
import { Input } from "@/Components/ui/input";

export type InlineEditProps = InputHTMLAttributes<HTMLInputElement>;

export const InlineEdit = forwardRef<HTMLInputElement, InlineEditProps>(
  ({ className, ...props }, ref) => {
    return (
      <Input
        ref={ref}
        className={cn(
          "-m-1 h-auto w-full rounded-sm border-none p-1 ring-1 ring-muted transition-none focus-visible:shadow-sm disabled:cursor-text disabled:opacity-100",
          className,
        )}
        placeholder=" "
        autoComplete="off"
        {...props}
      />
    );
  },
);
