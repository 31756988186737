import { ElementType } from "react";
import {
  AppWindow,
  Armchair,
  ArrowUpDown,
  AudioLines,
  AudioWaveform,
  Axis3d,
  BatteryFull,
  BookA,
  BoomBox,
  Box,
  Brackets,
  BrickWall,
  Briefcase,
  Cable,
  Camera,
  Cat,
  CircleOff,
  CirclePower,
  CircuitBoard,
  Cpu,
  Cylinder,
  DiscAlbum,
  Dog,
  DoorClosed,
  Drum,
  Ear,
  Fan,
  FileMusic,
  FileVolume,
  Gamepad,
  Gamepad2,
  Gauge,
  Grid3x3,
  Guitar,
  Hammer,
  HardDrive,
  HdmiPort,
  Headphones,
  Image,
  Joystick,
  Keyboard,
  KeyboardMusic,
  LampDesk,
  Laptop,
  LibraryBig,
  Lightbulb,
  MemoryStick,
  Mic,
  Monitor,
  Mouse,
  Music,
  PanelBottom,
  PanelBottomClose,
  PcCase,
  Piano,
  Plane,
  Printer,
  RadioReceiver,
  RectangleHorizontal,
  Router,
  Ruler,
  Smartphone,
  Sparkles,
  Speaker,
  Speech,
  Sprout,
  SquarePen,
  SquarePlay,
  SquarePower,
  Sun,
  Syringe,
  Table,
  Tablet,
  Tv,
  Tv2,
  Usb,
  Webcam,
  Wifi,
} from "lucide-react";
import { Desk, SteeringWheel, VideoCard, VrHeadset } from "@/Components/Atoms/Icons";
import { FieldType } from "@/Types/generated_enums";

export const FieldTypeIcon = new Map<FieldType, ElementType | null>([
  [FieldType.None, null],

  /* PC Build */
  [FieldType.VideoCard, VideoCard],
  [FieldType.CPU, Cpu],
  [FieldType.Case, PcCase],
  [FieldType.Motherboard, CircuitBoard],
  [FieldType.RAM, MemoryStick],
  [FieldType.PowerSupply, CirclePower],
  [FieldType.CPUCooler, Fan],
  [FieldType.CaseFan, Fan],
  [FieldType.ThermalPaste, Syringe],
  [FieldType.SSD, HardDrive],
  [FieldType.HDD, HardDrive],
  [FieldType.NetworkAdapter, Wifi],
  [FieldType.CustomCables, Cable],
  [FieldType.OperatingSystem, AppWindow],

  /* Peripherals */
  [FieldType.Keyboard, Keyboard],
  [FieldType.Mouse, Mouse],
  [FieldType.Monitor, Monitor],
  [FieldType.MonitorArm, Axis3d],
  [FieldType.Wallpaper, Image],
  [FieldType.DrawingTablet, SquarePen],
  [FieldType.DrawingTabletStand, Axis3d],
  [FieldType.Webcam, Webcam],
  [FieldType.WebcamStand, Axis3d],
  [FieldType.PCGamepad, Gamepad2],
  [FieldType.Joystick, Joystick],
  [FieldType.Pedal, Gauge],
  [FieldType.SteeringWheel, SteeringWheel],
  [FieldType.FlightController, Plane],
  [FieldType.UPS, SquarePower],
  [FieldType.DockingStation, HdmiPort],
  [FieldType.USBHub, Usb],
  [FieldType.USBAccessory, Usb],
  [FieldType.CaptureCard, SquarePlay],
  [FieldType.Accessory, Sparkles],

  /* Audio */
  [FieldType.Headphones, Headphones],
  [FieldType.Earpads, Ear],
  [FieldType.HeadphoneAmp, AudioWaveform],
  [FieldType.HeadphoneStand, Axis3d],
  [FieldType.Speakers, Speaker],
  [FieldType.SpeakerStand, Axis3d],
  [FieldType.Subwoofer, DiscAlbum],
  [FieldType.Microphone, Mic],
  [FieldType.MicrophoneStand, Axis3d],
  [FieldType.PopFilter, CircleOff],
  [FieldType.Amplifier, AudioLines],
  [FieldType.AudioInterface, RadioReceiver],
  [FieldType.DAC, FileVolume],
  [FieldType.AudioAccessory, Sparkles],
  [FieldType.KeyboardPedal, Piano],
  [FieldType.GuitarPedal, Guitar],
  [FieldType.PedalBoard, Table],
  [FieldType.MusicStreamer, BoomBox],
  [FieldType.MIDIController, KeyboardMusic],
  [FieldType.DrumMachine, Drum],
  [FieldType.AudioMixer, FileMusic],
  [FieldType.MIDIInterface, Music],
  [FieldType.DAWController, Grid3x3],
  [FieldType.Instrument, Music],

  /* Devices */
  [FieldType.Console, PcCase],
  [FieldType.ConsoleGamepad, Gamepad],
  [FieldType.TV, Tv],
  [FieldType.Laptop, Laptop],
  [FieldType.LaptopStand, Axis3d],
  [FieldType.Tablet, Tablet],
  [FieldType.TabletStand, Axis3d],
  [FieldType.Phone, Smartphone],
  [FieldType.PhoneStand, Axis3d],
  [FieldType.ThreeDPrinter, Box],
  [FieldType.Printer, Printer],
  [FieldType.VRHeadset, VrHeadset],
  [FieldType.VRHeadStrap, Ruler],
  [FieldType.VRCharger, Cable],
  [FieldType.Modem, Router],
  [FieldType.Router, Router],
  [FieldType.Camera, Camera],
  [FieldType.AssistantDevice, Speech],
  [FieldType.PowerBank, BatteryFull],

  /* Decoration */
  [FieldType.Desk, Desk],
  [FieldType.DeskDimensions, Ruler],
  [FieldType.Chair, Armchair],
  [FieldType.Backboard, BrickWall],
  [FieldType.Shelving, Hammer],
  [FieldType.WallDecoration, Image],
  [FieldType.Plant, Sprout],
  [FieldType.Lamp, LampDesk],
  [FieldType.RGBLight, Lightbulb],
  [FieldType.LightBar, Sun],
  [FieldType.LightStrip, Sun],
  [FieldType.TubeLight, Sun],
  [FieldType.VideoLight, Sun],
  [FieldType.Mousepad, RectangleHorizontal],
  [FieldType.Drawer, PanelBottomClose],
  [FieldType.Cupboard, DoorClosed],
  [FieldType.Bookcase, LibraryBig],
  [FieldType.Footrest, Cylinder],
  [FieldType.Accessory, Sparkles],
  [FieldType.Cat, Cat],
  [FieldType.Dog, Dog],

  /* Keyboard Build */
  [FieldType.KeyboardCase, Keyboard],
  [FieldType.Switches, ArrowUpDown],
  [FieldType.SwitchFilms, PanelBottom],
  [FieldType.Keycaps, BookA],
  [FieldType.KeyboardCable, Cable],
  [FieldType.StarterKit, Briefcase],
  [FieldType.Plate, Grid3x3],
  [FieldType.PCB, CircuitBoard],
  [FieldType.Stabilizers, Brackets],
  [FieldType.Lube, Syringe],
  [FieldType.KeyboardAccessory, Sparkles],

  /* Additional */
  [FieldType.SensorPanel, Tv2],
]);
