import { GridSpaceItem } from "@/Types/Widgets/GridSpaceItem";
import { ScreenSpaceItem } from "@/Types/Widgets/ScreenSpaceItem";
import { Position } from "@/Types/Position";
import { Rect } from "@/Types/Rect";
import { Id } from "@/Types/Id";
import { CoordinateArray } from "@/Types/CoordinateArray";
import { create } from "mutative";

export const widgetsToGridSpaceItemDictionary = (
  widgets: App.Data.Models.WidgetData[],
  columns: number,
): Record<Id, GridSpaceItem> => {
  const cursor = { x: 1, y: 1 };

  return widgets.reduce<Record<Id, GridSpaceItem>>((acc, widget) => {
    const position = { ...cursor };
    cursor.x += widget.width;

    if (cursor.x > columns) {
      cursor.x = 1;
      cursor.y += widget.height;
    }

    acc[widget.id] = {
      id: widget.id,
      x: position.x,
      y: position.y,
      width: widget.width,
      height: widget.height,
      widget,
    };

    return acc;
  }, {});
};

export const gridSpaceItemToScreenSpaceItem = (
  item: GridSpaceItem,
  unitSize: number,
  padding: number,
): ScreenSpaceItem => {
  return {
    id: item.id,
    x: calculatePosition(item.x, unitSize, padding),
    y: calculatePosition(item.y, unitSize, padding),
    width: calculateSize(item.width, unitSize, padding),
    height: calculateSize(item.height, unitSize, padding),
    widget: item.widget,
  };
};

export const gridSpaceItemsToScreenSpaceItemDictionary = (
  items: Record<Id, GridSpaceItem>,
  unitSize: number,
  padding: number,
): Record<Id, ScreenSpaceItem> => {
  return Object.fromEntries(
    Object.entries(items).map(([key, item]) => [
      key,
      gridSpaceItemToScreenSpaceItem(item, unitSize, padding),
    ]),
  );
};

export const getLastItem = (items: Record<Id, GridSpaceItem>): GridSpaceItem | null => {
  // return the item with the highest y value, then the highest x value
  return Object.values(items).reduce<GridSpaceItem | null>((acc, item) => {
    if (acc === null) {
      return item;
    }

    if (item.y > acc.y) {
      return item;
    }

    if (item.y === acc.y && item.x > acc.x) {
      return item;
    }

    return acc;
  }, null);
};

export const calculateGridHeight = (items: Rect[]) => {
  return Math.max(
    0,
    ...items.map((item) => {
      return item.y + item.height;
    }),
  );
};

export const calculateUnitSize = (
  containerWidth: number,
  columns: number,
  padding: number,
): number => {
  return (containerWidth - padding * (columns - 1)) / columns;
};

export const calculateSize = (units: number, unitSize: number, padding: number): number => {
  return unitSize * units + padding * (units - 1);
};

export const calculatePosition = (units: number, unitSize: number, padding: number): number => {
  return (unitSize + padding) * (units - 1);
};

/**
 * Convert 1D array to 2D array, indexed first by row (y) then by column (x)
 */
export const toCoordinateArray = <T extends Position>(
  array: T[],
  columns: number,
): CoordinateArray<T> => {
  const rows = Math.max(...array.map((item) => item.y));

  // initialise empty 2D array with nulls, where the first index is the row (y) and the second is the column (x)
  const result: T[][] = Array.from({ length: rows }, () => Array(columns).fill(null));

  for (const item of array) {
    result[item.y - 1][item.x - 1] = item;
  }

  return result;
};

/**
 * Convert 2D array indexed first by row (y) then by column (x) to 1D array excluding null values
 */
export const toOrderedArray = <T>(array: T[][]): NonNullable<T>[] =>
  array.flat().filter((item) => item !== null) as NonNullable<T>[];

/**
 * Copies an item from positionA to positionB
 */
export const copy = (
  items: CoordinateArray<GridSpaceItem>,
  positionA: Position,
  positionB: Position,
): CoordinateArray<GridSpaceItem> => {
  const item = items[positionA.y - 1][positionA.x - 1];

  if (item === null) {
    return items;
  }

  items[positionB.y - 1][positionB.x - 1] = {
    ...item,
    x: positionB.x,
    y: positionB.y,
  };

  return items;
};

const get = (items: CoordinateArray<GridSpaceItem>, position: Position): GridSpaceItem | null => {
  if (
    position.x < 1 ||
    position.y < 1 ||
    position.y > items.length ||
    position.x > items[position.y - 1].length
  ) {
    return null;
  }

  return items[position.y - 1][position.x - 1];
};

const set = (
  items: CoordinateArray<GridSpaceItem>,
  position: Position,
  item: GridSpaceItem | null,
): CoordinateArray<GridSpaceItem> => {
  if (
    position.x < 1 ||
    position.y < 1 ||
    position.y > items.length ||
    position.x > items[position.y - 1].length
  ) {
    return items;
  }

  items[position.y - 1][position.x - 1] =
    item == null ? item : { ...item, x: position.x, y: position.y };

  return items;
};

const delta = (positionA: Position, positionB: Position): { x: number; y: number } => {
  return { x: positionB.x - positionA.x, y: positionB.y - positionA.y };
};

const translate = (position: Position, x: number, y: number): Position => {
  return { x: position.x + x, y: position.y + y };
};

/**
 * Moves an item from positionA to positionB
 */
export const move = (
  items: CoordinateArray<GridSpaceItem>,
  positionA: Position,
  positionB: Position,
): CoordinateArray<GridSpaceItem> => {
  const item = get(items, positionA);

  if (item === null) {
    return items;
  }

  set(items, positionA, null);
  set(items, positionB, item);

  return items;
};

/**
 * Moves an item from positionA to positionB and swaps any overlapping items
 */
export const moveAndSwap = (
  items: CoordinateArray<GridSpaceItem>,
  positionA: Position,
  positionB: Position,
): CoordinateArray<GridSpaceItem> => {
  const item = get(items, positionA);
  const moved = delta(positionA, positionB);

  let adjacentOffset = 0;
  let adjacentItem: GridSpaceItem | null = null;

  if (item === null) {
    return items;
  }

  // Find all overlapping items
  const overlapping: GridSpaceItem[] = [];

  // Check one cell to left since the maximum width of a widget is 2
  const positionBLeft = get(items, { x: positionB.x - 1, y: positionB.y });
  if (positionBLeft?.width === 2) {
    overlapping.push(positionBLeft);
    set(items, positionBLeft, null);
  }

  // Check all cells within the width of the item
  for (let x = positionB.x; x < positionB.x + item.width; x++) {
    const current = get(items, { x, y: positionB.y });
    if (current) {
      overlapping.push(current);
    }

    set(items, { x, y: positionB.y }, null);
  }

  // Check if we need to move an adjacent item
  if (overlapping.some((x) => x.width === 2) && item.width === 1) {
    adjacentOffset = item.x % 2 === 0 ? -1 : 1;
    adjacentItem = get(items, { x: item.x + adjacentOffset, y: item.y });
  }

  // Remove the item from positionA
  set(items, positionA, null);
  if (adjacentItem) {
    set(items, translate(positionA, adjacentOffset, 0), null);
  }

  // Reinsert the item at positionB
  set(items, positionB, item);
  if (adjacentItem) {
    set(items, translate(positionB, adjacentOffset, 0), adjacentItem);
  }

  // Reinsert the overlapping items, moved by the negative delta
  for (const current of overlapping) {
    set(items, { x: current.x - moved.x, y: current.y - moved.y }, current);
  }

  return items;
};

/** Finds the next occupied space in the grid */
export const findNextOccupiedSpace = (
  items: CoordinateArray<GridSpaceItem>,
  startX: number,
  startY: number,
): Position | null => {
  for (let y = startY; y < items.length; y++) {
    for (let x = y === startY ? startX : 0; x < items[y].length; x++) {
      if (items[y][x] !== null) {
        return { x, y };
      }
    }
  }

  return null;
};

/** Populates the fields of a grid of itemes with their new positions */
export const populateFields = (
  items: CoordinateArray<GridSpaceItem>,
): CoordinateArray<GridSpaceItem> =>
  create(items, (draft) => {
    for (let y = 0; y < draft.length; y++) {
      for (let x = 0; x < draft[y].length; x++) {
        const item = draft[y][x];
        if (item === null) continue;

        item.x = x + 1;
        item.y = y + 1;
        item.widget.width = item.width;

        x += item.width - 1;
      }
    }

    return draft;
  });

export const getTotalUnits = (widgets: App.Data.Models.WidgetData[]): number =>
  widgets.reduce((acc, widget) => {
    return acc + widget.width;
  }, 0);

export const truncateByUnits = (
  widgets: App.Data.Models.WidgetData[],
  maxUnits: number,
): App.Data.Models.WidgetData[] =>
  widgets.reduce<{ widgets: App.Data.Models.WidgetData[]; totalUnits: number }>(
    (acc, widget) => {
      const newTotal = acc.totalUnits + widget.width;
      return newTotal <= maxUnits
        ? { widgets: [...acc.widgets, widget], totalUnits: newTotal }
        : acc;
    },
    { widgets: [], totalUnits: 0 },
  ).widgets;
