import React from "react";
import type { SVGProps } from "react";
const SvgVideoCard = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" viewBox="0 0 24 24" {...props}>
    <path d="M0 0h24v24H0z" />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={2}
      d="M16 6H4a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12M2 4v16M6 18v2M10 18v2"
    />
    <circle cx={16} cy={12} r={2} stroke="currentColor" strokeWidth={2} />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={2}
      d="M17.449 10.543C17.2 8.84 19.854 7.716 21 9"
    />
    <path
      fill="currentColor"
      d="M14.686 11.541a1 1 0 0 0-.287-1.98zm-1.02-3.795.746-.666-1.332-1.492-.746.666zm.733 1.816c-.235.034-.597-.118-.83-.659-.231-.54-.112-.971.097-1.157l-1.332-1.492c-1.076.96-1.036 2.428-.602 3.439.435 1.01 1.486 2.061 2.954 1.848z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={2}
      d="M17.5 13.501c1.703-.247 2.827 2.406 1.543 3.551M14.551 13.55c.247 1.704-2.404 2.827-3.551 1.543"
    />
    <circle cx={16} cy={12} r={6} stroke="currentColor" strokeWidth={2} />
  </svg>
);
export default SvgVideoCard;
