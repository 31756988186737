import React, { memo } from "react";
import { cn } from "@/Utils/shadcn";
import { img } from "@/Utils/img";
import { NamedTransformation } from "@/API/NamedTransformation";

const BG_TRANSITION_DELAY = 400;
const BG_TRANSITION_DURATION = 1500;

export type FeaturedBattlestationsBackgroundProps = {
  battlestations: App.Data.Models.BattlestationData[];
  activeIndex: number;
};

export const FeaturedBattlestationsBackground = memo(
  (props: FeaturedBattlestationsBackgroundProps) => {
    return (
      <div
        className="pointer-events-none absolute -inset-5 -bottom-20 bg-primary sm:-bottom-10"
        style={{
          maskImage: "linear-gradient(to bottom, rgba(0, 0, 0, 0.25) 25%, rgba(0, 0, 0, 0.0) 100%)",
        }}>
        {props.battlestations.map((battlestation, index) => (
          <div
            key={battlestation.id}
            className={cn(
              "absolute inset-0 bg-cover bg-center bg-no-repeat blur-2xl transition-opacity will-change-[opacity]",
              index === props.activeIndex ? "opacity-100" : "opacity-0",
            )}
            style={{
              transitionDelay:
                index === props.activeIndex
                  ? `${BG_TRANSITION_DELAY}ms`
                  : `${BG_TRANSITION_DELAY + BG_TRANSITION_DURATION}ms`,
              transitionDuration: index === props.activeIndex ? `${BG_TRANSITION_DURATION}ms` : "0",
              backgroundImage: `url(${img(battlestation.images?.[0], { named: NamedTransformation.ImageRegular })})`,
            }}
          />
        ))}
      </div>
    );
  },
  (prev, next) => prev.activeIndex === next.activeIndex,
);
