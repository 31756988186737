import { z } from "zod";
import { cardSchema } from "@/Schemas/cardSchema";
import { idSchema } from "@/Schemas/idSchema";
import { fileSchema } from "@/Schemas/fileSchema";
import { WidgetType } from "@/Types/generated_enums";

const cardWidgetSchema = z.object({
  card: cardSchema.optional(),
});

const imageWidgetSchema = z.object({
  image: fileSchema.nullish(),
});

const spotlightWidgetSchema = z.object({
  spotlight: z
    .object({
      name: z
        .string()
        .min(2, "Widget name must be at least 2 characters.")
        .max(100, "Widget name must be no more than 100 characters."),
      description: z
        .string()
        .min(2, "Widget description must be at least 2 characters.")
        .max(2000, "Widget description must be no more than 2000 characters."),
      product_link: z.string().url("Link to product must be a valid URL."),
      discount_code: z.string().max(200, "Discount code must be no more than 200 characters."),
      image: fileSchema.nullable(),
    })
    .optional(),
});

export const widgetSchema = z
  .object({
    id: idSchema,
    width: z.number(),
    height: z.number(),
    type: z.nativeEnum(WidgetType),
  })
  .merge(cardWidgetSchema)
  .merge(imageWidgetSchema)
  .merge(spotlightWidgetSchema);
