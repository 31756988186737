import React from "react";
import type { SVGProps } from "react";
const SvgVrHeadset = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" viewBox="0 0 24 24" {...props}>
    <path d="M0 0h24v24H0z" />
    <path
      stroke="currentColor"
      strokeWidth={2}
      d="M2 11a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-4.5a1.5 1.5 0 0 1-1.5-1.5 1.5 1.5 0 0 0-1.5-1.5h-1a1.5 1.5 0 0 0-1.5 1.5A1.5 1.5 0 0 1 8.5 21H4a2 2 0 0 1-2-2z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={2}
      d="M20 9V6a2 2 0 0 0-2-2h-2M4 9V6a2 2 0 0 1 2-2h2M12 4v5M9 13h6"
    />
  </svg>
);
export default SvgVrHeadset;
