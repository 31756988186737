import React, { createElement, useEffect, useRef, useState } from "react";
import { GridSpaceItem } from "@/Types/Widgets/GridSpaceItem";
import { ScreenSpaceItem } from "@/Types/Widgets/ScreenSpaceItem";
import { useWidgetsStore } from "@/Stores/useWidgetsStore";
import { cn } from "@/Utils/shadcn";
import { Motion, spring } from "react-motion";
import { springIf } from "@/Utils/springIf";
import { useStateWithRef } from "@/Hooks/useStateWithRef";
import { Position } from "@/Types/Position";
import { Rect } from "@/Types/Rect";
import { useHasErrors } from "@/Hooks/useHasErrors";
import { WidgetPropsBase } from "@/Types/Widgets/WidgetPropsBase";
import { WidgetComponentByType } from "@/API/Maps/WidgetComponentByType";
import { useBattlestationStore } from "@/Stores/useBattlestationStore";

export type WidgetProps = {
  name: `widgets.${number}`;
  gridSpaceItem: GridSpaceItem;
  screenSpaceItem: ScreenSpaceItem;
  initialRect?: Rect;
  onMove: (position: Position) => void;
  onResize: (width: number) => void;
  onDelete: () => void;
};

// settings
const DRAG_DELAY = 150;

const SCROLL_THRESHOLD = 50;
const SCROLL_SPEED = 100;

const JIGGLE_FREQUENCY = 45;
const JIGGLE_AMPLITUDE = 0.01;

export const Widget = ({ gridSpaceItem, screenSpaceItem, ...props }: WidgetProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const tickOffset = useRef(Math.random() * 1000);

  const grid = useWidgetsStore((state) => state.grid);
  const padding = useWidgetsStore((state) => state.padding);

  const editing = useBattlestationStore((state) => state.editing);
  const rearranging = useBattlestationStore((state) => state.rearranging);
  const setRearranging = useBattlestationStore((state) => state.setRearranging);

  const [moving, setMoving] = useState(!!props.initialRect);
  const [initialAnimation, setInitialAnimation] = useState(!!props.initialRect);

  const [dragging, draggingRef, setDragging] = useStateWithRef(false);
  const [justReleased, setJustReleased] = useState(false);

  const [translate, translateRef, setTranslate] = useStateWithRef(() => {
    const gridRect = grid?.current?.getBoundingClientRect();

    if (!gridRect || !props.initialRect) {
      return { x: screenSpaceItem.x, y: screenSpaceItem.y };
    }

    return {
      x: props.initialRect.x - gridRect.x,
      y: props.initialRect.y - gridRect.y,
    };
  });

  const screenSpaceItemRef = useRef(screenSpaceItem);

  useEffect(() => {
    screenSpaceItemRef.current = screenSpaceItem;
  }, [screenSpaceItem]);

  const offset = useRef({ x: 0, y: 0 });
  const lastPointerPosition = useRef({ x: 0, y: 0, timestamp: 0 });
  const lastGridPosition = useRef<Position | null>({
    x: gridSpaceItem.x,
    y: gridSpaceItem.y,
  });

  const edgeScrollDirection = useRef<number | null>(null);

  const dragDelayTimeout = useRef<ReturnType<typeof setTimeout>>();
  const movingTimeout = useRef<ReturnType<typeof setTimeout>>();

  const showErrors = useHasErrors(props.name) && editing && !rearranging;

  const jiggleTick = (delta: number) => {
    if (!ref.current) return;

    if (!useBattlestationStore.getState().rearranging) {
      ref.current.style.rotate = "0rad";
      return;
    }

    const rotation = Math.sin((delta + tickOffset.current) / JIGGLE_FREQUENCY) * JIGGLE_AMPLITUDE;
    ref.current.style.rotate = `${rotation}rad`;

    requestAnimationFrame(jiggleTick);
  };

  const scrollTick = () => {
    if (!edgeScrollDirection.current) return;

    const scrollAmount = edgeScrollDirection.current;

    if (
      (scrollAmount < 0 && window.scrollY === 0) ||
      (scrollAmount > 0 && window.scrollY + window.innerHeight === document.body.scrollHeight)
    ) {
      return;
    }

    window.scrollBy(0, scrollAmount);

    offset.current = {
      x: offset.current.x,
      y: offset.current.y - scrollAmount,
    };

    setTranslate({
      x: translateRef.current!.x,
      y: translateRef.current!.y + scrollAmount,
    });

    setTimeout(scrollTick, SCROLL_SPEED);
  };

  useEffect(() => {
    if (rearranging) {
      requestAnimationFrame(jiggleTick);
    } else if (dragging) {
      // Fallback in case dragging isn't captured properly and widgets are left floating
      // This shouldn't happen, but it's a good safety net
      onDragEnd();
    }
  }, [rearranging]);

  useEffect(() => {
    if (dragging) return;

    setTranslate({
      x: screenSpaceItem.x,
      y: screenSpaceItem.y,
    });

    setMoving(true);

    movingTimeout.current = setTimeout(() => {
      if (draggingRef.current) return;

      setMoving(false);
      if (initialAnimation) {
        setInitialAnimation(false);
      }
    }, 500);
  }, [screenSpaceItem, screenSpaceItem.x, screenSpaceItem.y]);

  useEffect(() => {
    lastGridPosition.current = {
      x: gridSpaceItem.x,
      y: gridSpaceItem.y,
    };
  }, [gridSpaceItem]);

  /*
   * utility functions
   */

  const addListeners = () => {
    window.addEventListener("touchmove", onTouchMove, { passive: false });
    window.addEventListener("mousemove", onMouseMove, { passive: false });

    window.addEventListener("touchend", onDragEnd);
    window.addEventListener("mouseup", onDragEnd);
  };

  const removeListeners = () => {
    clearTimeout(dragDelayTimeout.current);

    window.removeEventListener("touchmove", onTouchMove);
    window.removeEventListener("mousemove", onMouseMove);

    window.removeEventListener("touchend", onDragEnd);
    window.removeEventListener("mouseup", onDragEnd);
  };

  const startDrag = () => {
    setMoving(true);
    setDragging(true);
    document.body.classList.add("global-grabbing");
    document.documentElement.classList.add("gutter-stable");
  };

  const endDrag = () => {
    setDragging(false);
    setJustReleased(true);
    document.body.classList.remove("global-grabbing");
    document.documentElement.classList.remove("gutter-stable");
  };

  /*
   * native event handlers - start
   */

  const onTouchStart = (event: React.TouchEvent) => {
    if (!ref.current?.contains(event.target as Node)) return;

    const touch = event.touches[0];
    onDragStart(event, touch.clientX, touch.clientY);
  };

  const onMouseDown = (event: React.MouseEvent) => {
    if (!ref.current?.contains(event.target as Node)) return;

    onDragStart(event, event.clientX, event.clientY);
  };

  const onDragStart = (
    event: React.TouchEvent | React.MouseEvent,
    eventX: number,
    eventY: number,
  ) => {
    // we're safe to use React state here as this is called from a React event handler

    if (!editing) {
      return;
    }

    addListeners();

    if (rearranging && event.type === "mousedown") {
      // start dragging straight away if we're already rearranging and we're on desktop
      startDrag();
    } else {
      // otherwise wait for a delay before starting to drag to allow for scrolling on mobile
      dragDelayTimeout.current = setTimeout(() => {
        setRearranging(true);
        startDrag();
      }, DRAG_DELAY);
    }

    lastPointerPosition.current = {
      x: eventX,
      y: eventY,
      timestamp: Date.now(),
    };

    offset.current = {
      x: eventX - screenSpaceItem.x,
      y: eventY - screenSpaceItem.y,
    };
  };

  /*
   * native event handlers - move
   */

  const onMouseMove = (event: MouseEvent) => {
    onDragMove(event, event.clientX, event.clientY);
  };

  const onTouchMove = (event: TouchEvent) => {
    const touch = event.touches[0];
    onDragMove(event, touch.clientX, touch.clientY);
  };

  const onDragMove = (event: TouchEvent | MouseEvent, eventX: number, eventY: number) => {
    // this is executed outside the React lifecycle,
    // so we need to use refs instead of React state

    if (!draggingRef.current) {
      if (lastPointerPosition.current.timestamp + DRAG_DELAY > Date.now()) {
        // pointer moved too soon after touch for it to be considered a drag
        // the user is probably scrolling

        removeListeners();

        return;
      }
    }

    // this needs to run asap to avoid a race condition with the browser trying to scroll the page
    if (event.cancelable) {
      event.preventDefault();
    }

    // set these values after cancelling the event to avoid
    // the browser trying to scroll the page (race condition)
    if (!draggingRef.current) {
      setRearranging(true);
      setMoving(true);
      setDragging(true);
    }

    const gridRect = grid?.current?.getBoundingClientRect();
    if (!gridRect) return;

    lastPointerPosition.current = {
      x: eventX,
      y: eventY,
      timestamp: Date.now(),
    };

    // make sure we're getting the latest store state
    // as the store state might have changed since the last render
    const store = useWidgetsStore.getState();

    setTranslate({
      x: eventX - offset.current.x,
      y: eventY - offset.current.y,
    });

    const position = {
      x: Math.round(translateRef.current!.x / (store.unitSize + padding)) + 1,
      y: Math.round(translateRef.current!.y / (store.unitSize + padding)) + 1,
    };

    if (position.x !== lastGridPosition.current?.x || position.y !== lastGridPosition.current?.y) {
      lastGridPosition.current = position;
      props.onMove?.(position);
    }

    // edge scrolling
    if (eventY < SCROLL_THRESHOLD) {
      edgeScrollDirection.current = -1;
      scrollTick();
    } else if (eventY > window.innerHeight - SCROLL_THRESHOLD) {
      edgeScrollDirection.current = 1;
      scrollTick();
    } else {
      edgeScrollDirection.current = null;
    }
  };

  /*
   * native event handlers - end
   */

  const onDragEnd = () => {
    // we're safe to use React state here as this is called from a React event handler

    if (!editing) {
      return;
    }

    removeListeners();

    endDrag();

    setTranslate({
      x: screenSpaceItemRef.current.x,
      y: screenSpaceItemRef.current.y,
    });

    lastGridPosition.current = null;
    edgeScrollDirection.current = null;

    movingTimeout.current = setTimeout(() => {
      // no React state within this timeout
      if (!draggingRef.current) {
        setMoving(false);
        setJustReleased(false);
      }
    }, 500);
  };

  useEffect(() => {
    return () => {
      // clean up event listeners on unmount
      removeListeners();
      clearTimeout(movingTimeout.current);
    };
  }, []);

  const widgetProps: WidgetPropsBase<any> = {
    gridConfig: {
      gridSpaceItem,
      editing,
      rearranging,
      initialAnimation,
      showErrors,
      onTouchStart,
      onMouseDown,
      onResize: props.onResize,
      onDelete: props.onDelete,
    },
    widget: gridSpaceItem.widget,
  };

  const WidgetComponent = WidgetComponentByType.get(gridSpaceItem.widget.type);
  if (!WidgetComponent) {
    console.error(
      `Tried to render unknown widget type ${gridSpaceItem.widget.type}. Add it to the WidgetComponentByType map.`,
    );
    return null;
  }

  return (
    <>
      {/* Placeholder */}
      <Motion
        style={{
          x: spring(screenSpaceItem.x),
          y: spring(screenSpaceItem.y),
        }}>
        {(style) => (
          <div
            className={cn(
              "rounded-3xl border-4 border-dashed opacity-0 transition-opacity duration-300",
              dragging && "opacity-100",
            )}
            style={{
              position: "absolute",
              willChange: "translate",
              translate: `${style.x}px ${style.y}px`,
              width: screenSpaceItem.width,
              height: screenSpaceItem.height,
            }}
          />
        )}
      </Motion>
      {/* Widget */}
      <Motion
        defaultStyle={{
          x: translate.x,
          y: translate.y,
          width: props.initialRect?.width ?? screenSpaceItem.width,
          height: props.initialRect?.height ?? screenSpaceItem.height,
          scale: 1,
          boxShadow: 0,
        }}
        style={{
          x: springIf(!dragging, translate.x, {
            stiffness: 110,
            damping: 15,
            precision: 0.1,
          }),
          y: springIf(!dragging, translate.y, {
            stiffness: 110,
            damping: 15,
            precision: 0.1,
          }),
          width: spring(screenSpaceItem.width, {
            stiffness: 110,
            damping: 15,
            precision: 0.1,
          }),
          height: spring(screenSpaceItem.height, {
            stiffness: 110,
            damping: 15,
            precision: 0.1,
          }),
          scale: spring(dragging ? 1.06 : 1, {
            stiffness: 120,
            damping: 6,
            precision: 0.001,
          }),
          boxShadow: spring(dragging ? 0.4 : 0, {
            stiffness: 140,
            damping: 6,
            precision: 0.001,
          }),
        }}>
        {(style) => (
          <div
            ref={ref}
            style={{
              position: "absolute",
              willChange: "translate, scale, transform",
              translate: `${style.x}px ${style.y}px`,
              scale: style.scale.toString(),
              width: `${style.width}px`,
              height: `${style.height}px`,
              boxShadow: `0 25px 50px -12px rgba(0, 0, 0, ${style.boxShadow})`,
            }}
            className={cn(
              "group z-10",
              moving && "z-20 touch-none",
              (dragging || justReleased) && "z-30",
              moving && initialAnimation && "z-[100]",
            )}
            data-rearrange-ignore-click="true">
            {/* Widget content */}
            {createElement(WidgetComponent, widgetProps)}
          </div>
        )}
      </Motion>
    </>
  );
};
