import React from "react";
import { CircleAlert } from "lucide-react";
import { cn } from "@/Utils/shadcn";

export type ErrorBadgeProps = {
  className?: string;
};

export const ErrorBadge = (props: ErrorBadgeProps) => {
  return (
    <div className="pointer-events-none relative">
      <div className="absolute size-full animate-ping-sm rounded-full bg-destructive opacity-75" />
      <div className="relative size-full rounded-full bg-destructive p-1 text-destructive-foreground shadow-md">
        <CircleAlert className={cn("size-5", props.className)} />
      </div>
    </div>
  );
};
