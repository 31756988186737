import React, { useState } from "react";
import { FormControl, FormField, FormItem, FormMessage } from "@/Components/ui/form";
import { LinkIcon, Unlink } from "lucide-react";
import { Input } from "@/Components/ui/input";
import { Button } from "@/Components/ui/button";
import { useFormContext } from "react-hook-form";
import { BattlestationForm } from "@/API/Forms/BattlestationForm";
import { useConfirm } from "@/Contexts/ConfirmDialogContext";
import { usePage } from "@inertiajs/react";
import { BattlestationProps } from "@/Pages/Battlestation/Battlestation";

export type EditProductLinkProps = {
  name:
    | `widgets.${number}.card.fields.${number}.product_link`
    | `widgets.${number}.card.fields.${number}.fields.${number}.product_link`;
};

export const EditProductLink = (props: EditProductLinkProps) => {
  const page = usePage<BattlestationProps>();

  const form = useFormContext<BattlestationForm>();

  const confirm = useConfirm();

  const [hasProductLink, setHasProductLink] = useState(() => form.getValues(props.name) !== "");

  return (
    <FormField
      control={form.control}
      name={props.name}
      render={({ field }) => (
        <>
          {page.props.edit?.can.add_product_links && (
            <FormItem className="mb-1 flex flex-col gap-1">
              {hasProductLink ? (
                <>
                  <FormControl>
                    <div className="flex">
                      <div className="flex h-full items-center rounded-l-sm border border-r-0 border-input bg-card px-3 py-1 text-sm text-muted-foreground">
                        <LinkIcon className="size-3.5" />
                      </div>
                      <Input
                        className="m-0 h-8 rounded-l-none text-sm"
                        placeholder="Enter product link..."
                        autoComplete="off"
                        {...field}
                      />
                    </div>
                  </FormControl>
                  <FormMessage />
                  <Button
                    variant="link"
                    className="h-auto self-start p-0 text-xs text-muted-foreground hover:text-primary"
                    onClick={async () => {
                      if (field.value !== "") {
                        const choice = await confirm({
                          title: "Delete link?",
                          description: "Are you sure you want to delete this link?",
                          actionText: "Delete link",
                        });

                        if (!choice) return;
                      }

                      form.setValue(props.name, "", {
                        shouldValidate: true,
                      });

                      setHasProductLink(false);
                    }}>
                    <Unlink className="mr-1 size-3" /> Remove product link
                  </Button>
                </>
              ) : (
                <Button
                  variant="link"
                  className="h-auto self-start p-0 text-xs text-muted-foreground hover:text-primary"
                  onClick={() => {
                    setHasProductLink(true);
                  }}>
                  <LinkIcon className="mr-1 size-3" /> Add product link
                </Button>
              )}
            </FormItem>
          )}
        </>
      )}
    />
  );
};
