export const getOrFail = <T extends Map<any, any>>(
  map: T,
  key: T extends Map<infer K, any> ? K : never,
): T extends Map<any, infer U> ? U : never => {
  if (!map.has(key)) {
    throw new Error(`Key ${key} not found in map`);
  }

  return map.get(key);
};
