import React, { useEffect, useState } from "react";
import { Head } from "@/Components/Atoms/Head";
import { Avatar } from "@/Components/Atoms/Avatar";
import { ImageBanner } from "@/Components/Molecules/ImageBanner";
import { Points } from "@/Components/Atoms/Points";
import { Container } from "@/Components/Atoms/Container";
import { BattlestationCardSmall } from "@/Components/Molecules/BattlestationCardSmall";
import { useUser } from "@/Hooks/useUser";
import { route } from "ziggy-js";
import { Link } from "@inertiajs/react";
import { FaceFrownIcon } from "@heroicons/react/24/outline";
import { Button } from "@/Components/ui/button";
import { Plus } from "lucide-react";
import { VerifiedBadge } from "@/Components/Atoms/VerifiedBadge";

export type ProfileProps = {
  user: App.Data.Models.UserData;
};

const Profile = (props: ProfileProps) => {
  const currentUser = useUser();
  const [viewingOwnProfile, setViewingOwnProfile] = useState(false);

  useEffect(() => {
    setViewingOwnProfile(currentUser?.id === props.user.id);
  }, [currentUser, props.user]);

  return (
    <>
      <Head title={`${props.user.username}'s profile`} />
      <ImageBanner image={props.user.banner_image!}>
        <div className="flex items-center gap-6">
          <Avatar
            image={props.user.avatar}
            username={props.user.username}
            className="size-24 rounded-xl"
          />
          <div className="flex flex-col">
            <div className="flex items-center gap-2.5">
              <h2 className="text-3xl font-bold leading-tight text-white">{props.user.username}</h2>
              <VerifiedBadge for={props.user} className="size-7" />
            </div>
            <span className="text-sm text-gray-300">
              <Points points={props.user.points!} />
            </span>
          </div>
        </div>
      </ImageBanner>
      <Container className="flex flex-col gap-6 py-6 lg:flex-row">
        {/* About */}
        <div className="flex flex-col gap-2 overflow-hidden lg:basis-5/12">
          <span className="heading-xs text-gray-300">About</span>
          {props.user.about ? (
            <p className="text-ellipsis break-words">{props.user.about}</p>
          ) : (
            <p className="text-gray-400">
              <em>
                {viewingOwnProfile
                  ? "You haven't written anything about yourself yet."
                  : `${props.user.username} hasn't written anything about themself yet.`}
              </em>
            </p>
          )}
        </div>
        {/* Battlestations */}
        <div className="flex flex-1 flex-col gap-2">
          <span className="heading-xs text-gray-300">Battlestations</span>
          {(props.user.battlestations?.length ?? 0) > 0 ? (
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-1">
              {props.user.battlestations?.map((battlestation) => (
                <BattlestationCardSmall
                  key={battlestation.id}
                  battlestation={battlestation}
                  className="active:scale-[99%]"
                  imageClassName="lg:!aspect-[4/3]"
                />
              ))}
              {viewingOwnProfile && (
                <Link
                  href={route("battlestation.create")}
                  className="inline-block w-full justify-self-start sm:w-auto">
                  <Button variant="secondary" className="w-full active:scale-[98%]">
                    <Plus className="mr-2 h-5 w-5" />
                    Create a new battlestation
                  </Button>
                </Link>
              )}
            </div>
          ) : (
            <div className="relative block w-full rounded-md border-2 border-dashed border-gray-700 p-8 text-center">
              <FaceFrownIcon className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-medium">
                {viewingOwnProfile
                  ? "You don't have any battlestations yet."
                  : `${props.user.username} doesn't have any battlestations yet.`}
              </h3>
              <span className="mt-1 text-sm">
                {viewingOwnProfile ? (
                  <Link href={route("battlestation.create")}>
                    <Button variant="gradient" className="mt-3 active:scale-[98%]">
                      <Plus className="mr-2 h-5 w-5" />
                      Create your first setup
                    </Button>
                  </Link>
                ) : (
                  "Encourage them to create one!"
                )}
              </span>
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default Profile;
