import React, { useEffect, useRef, useState } from "react";
import { route } from "ziggy-js";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/Components/ui/dialog";
import { useMomentumModal } from "@/Hooks/useMomentumModal";
import { Mail } from "lucide-react";
import { Link } from "@inertiajs/react";
import { buttonVariants } from "@/Components/ui/button";

export type VerificationNoticeProps = {
  email: string;
};

const VerificationNotice = (props: VerificationNoticeProps) => {
  const intervalRef = useRef<ReturnType<typeof setInterval>>();

  const [timer, setTimer] = useState(0);

  const { show, close, redirect } = useMomentumModal();

  const resend = () => {
    setTimer(60);
    intervalRef.current = setInterval(() => {
      setTimer((t) => t - 1);
    }, 1000);
  };

  useEffect(() => {
    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    if (timer <= 0) {
      clearInterval(intervalRef.current);
    }
  }, [timer]);

  return (
    <Dialog
      open={show}
      onOpenChange={(open) => {
        if (!open) {
          // We call close() first to make closing the dialog feel more responsive
          close();
          redirect();
        }
      }}>
      <DialogContent className="max-h-[94%] max-w-sm overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Verify your email address</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col items-center gap-4 text-balance text-center text-sm">
          <Mail className="size-12 text-muted-foreground" />
          <div className="text-primary">
            We've sent an email to <strong>{props.email}</strong>. Click the link in the email to
            finish creating your account.
          </div>
          <div className="text-muted-foreground">
            Didn't receive anything? Click the button below and check your email again.
          </div>
          <div className="flex items-center gap-2">
            <Link
              as="button"
              disabled={timer > 0}
              preserveState
              preserveScroll
              onClick={resend}
              href={route("verification.send")}
              method="post"
              className={buttonVariants({ variant: "secondary" })}>
              <div className="flex items-center gap-2">
                <span>Resend email</span>
                {timer > 0 && <span className="opacity-75">{timer}</span>}
              </div>
            </Link>
            <a
              href="https://mail.google.com"
              target="_blank"
              rel="noopener noreferrer"
              className={buttonVariants({ variant: "accent" })}>
              Open Gmail
            </a>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default VerificationNotice;
