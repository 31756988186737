import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  calculateGridHeight,
  calculatePosition,
  calculateUnitSize,
  findNextOccupiedSpace,
  getLastItem,
  getTotalUnits,
  gridSpaceItemsToScreenSpaceItemDictionary,
  moveAndSwap,
  populateFields,
  toCoordinateArray,
  toOrderedArray,
  truncateByUnits,
  widgetsToGridSpaceItemDictionary,
} from "@/Utils/grid";
import { useWidgetsStore } from "@/Stores/useWidgetsStore";
import { Widget } from "@/Components/Molecules/Battlestation/Widgets/Widget";
import { Button } from "@/Components/ui/button";
import { Info } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "@/Components/ui/popover";
import { Position } from "@/Types/Position";
import { GridSpaceItem } from "@/Types/Widgets/GridSpaceItem";
import useResizeObserver from "@react-hook/resize-observer";
import { Breakpoint } from "@/Types/Enums/Breakpoint";
import { useWindowSize } from "react-use";
import { Rect } from "@/Types/Rect";
import { AddWidget } from "@/Components/Molecules/Battlestation/AddWidget/AddWidget";
import { Id } from "@/Types/Id";
import { CoordinateArray } from "@/Types/CoordinateArray";
import { clamp } from "@/Utils/clamp";
import { DefaultValues, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { BattlestationForm } from "@/API/Forms/BattlestationForm";
import { create } from "mutative";
import type { BattlestationProps } from "@/Pages/Battlestation/Battlestation";
import { usePage } from "@inertiajs/react";
import { keyBy } from "lodash";
import { useConfirm } from "@/Contexts/ConfirmDialogContext";
import { uuid } from "@/Utils/uuid";
import { useBattlestationStore } from "@/Stores/useBattlestationStore";
import { ExpandWidgets } from "@/Components/Molecules/Battlestation/ExpandWidgets";
import { WidgetType } from "@/Types/generated_enums";

const COLUMN_BREAKPOINT = 560;
const MAX_WIDGETS = 20;

export type BattlestationWidgetsProps = {
  defaultValues: DefaultValues<BattlestationForm>;
};

export const BattlestationWidgets = (props: BattlestationWidgetsProps) => {
  const page = usePage<BattlestationProps>();

  const ref = useRef<HTMLDivElement>(null);

  const { width: windowWidth } = useWindowSize();

  const confirm = useConfirm();

  const [width, setWidth] = useState(0);

  const [initialRect, setInitialRect] = useState<Rect | null>(null);
  const [lastAddedWidgetId, setLastAddedWidgetId] = useState<Id | null>(null);

  const columns = windowWidth < COLUMN_BREAKPOINT ? 2 : 4;

  // TODO: This sucks. Make it better?
  const padding = useMemo(() => {
    if (windowWidth < Breakpoint.XXS) {
      return 8;
    }
    if (windowWidth < Breakpoint.XS) {
      return 12;
    }
    if (windowWidth < COLUMN_BREAKPOINT - 100) {
      return 16;
    }
    if (windowWidth < COLUMN_BREAKPOINT) {
      return 20;
    }
    if (windowWidth < Breakpoint.SM) {
      return 8;
    }
    return 16;
  }, [windowWidth]);

  const unitSize = calculateUnitSize(width, columns, padding);

  const editing = useBattlestationStore((state) => state.editing);
  const rearranging = useBattlestationStore((state) => state.rearranging);
  const setRearranging = useBattlestationStore((state) => state.setRearranging);

  const init = useWidgetsStore((state) => state.init);

  useResizeObserver(ref, (entry) => {
    setWidth(entry.contentRect.width);
  });

  const form = useFormContext<BattlestationForm>();

  const [formUuid, setFormUuid] = useState(uuid());

  const { replace } = useFieldArray({
    control: form.control,
    name: "widgets",
    keyName: "key",
  });

  const widgetsWatch = useWatch({
    control: form.control,
    name: "widgets",
  });

  const widgetsExpanded = useBattlestationStore((state) => state.widgetsExpanded);
  const setWidgetsExpanded = useBattlestationStore((state) => state.setWidgetsExpanded);

  const collapsed = useMemo(
    () =>
      !editing &&
      !widgetsExpanded &&
      columns === 2 &&
      getTotalUnits(page.props.battlestation.widgets!) >= 15,
    [editing, widgetsExpanded, columns, page.props.battlestation.widgets!],
  );

  const widgets: App.Data.Models.WidgetData[] = editing
    ? widgetsWatch
    : collapsed
      ? truncateByUnits(page.props.battlestation.widgets!, 12)
      : page.props.battlestation.widgets!;

  const freeWidgets = useMemo(() => {
    if (!page.props.edit) return [];

    const cardDefinitionsByType = keyBy(page.props.edit.card_definitions, "type");

    return page.props.edit.available_widgets.free.filter((widget) => {
      if (widget.type !== WidgetType.Card) return true;

      const cardDefinition = cardDefinitionsByType[widget.card!.type];

      if (!cardDefinition) return false;

      const count = widgets.filter(
        (x) => x.type === WidgetType.Card && x.card!.type === widget.card!.type,
      ).length;

      return !cardDefinition.limit || (count || 0) < cardDefinition.limit;
    });
  }, [
    page.props.edit,
    page.props.edit?.card_definitions,
    page.props.edit?.available_widgets.free,
    widgets,
  ]);

  const showAddButton = editing && !!freeWidgets.length && widgets.length < MAX_WIDGETS;

  const gridSpaceItemsById = useMemo(
    () => widgetsToGridSpaceItemDictionary(widgets, columns),
    [widgets, columns],
  );

  const screenSpaceItemsById = useMemo(
    () => gridSpaceItemsToScreenSpaceItemDictionary(gridSpaceItemsById, unitSize, padding),
    [gridSpaceItemsById, unitSize],
  );

  const addButtonPositionGrid = useMemo<Position>(() => {
    const lastItem = getLastItem(gridSpaceItemsById);
    if (!lastItem) {
      return { x: 1, y: 1 };
    }

    if (lastItem.x + lastItem.width - 1 === columns) {
      return { x: 1, y: lastItem.y + lastItem.height };
    }

    return { x: lastItem.x + lastItem.width, y: lastItem.y };
  }, [gridSpaceItemsById, columns]);

  const addButtonPositionScreen = useMemo<Rect>(() => {
    return {
      x: calculatePosition(addButtonPositionGrid.x, unitSize, padding),
      y: calculatePosition(addButtonPositionGrid.y, unitSize, padding),
      width: unitSize,
      height: unitSize,
    };
  }, [addButtonPositionGrid, unitSize, padding]);

  const collapsedPositionGrid = useMemo<Position>(() => {
    const lastItem = getLastItem(gridSpaceItemsById);
    if (!lastItem) {
      return { x: 1, y: 1 };
    }

    return {
      x: 1,
      y: lastItem.y + lastItem.height,
    };
  }, [gridSpaceItemsById]);

  const collapsedPositionScreen = useMemo<Rect>(() => {
    return {
      x: calculatePosition(collapsedPositionGrid.x, unitSize, padding),
      y: calculatePosition(collapsedPositionGrid.y, unitSize, padding),
      width: width,
      height: 60 + padding * 2,
    };
  }, [collapsedPositionGrid, unitSize, padding, width]);

  const gridHeight = useMemo(() => {
    let items: Rect[] = Object.values(screenSpaceItemsById);
    if (showAddButton) {
      items = [...items, addButtonPositionScreen];
    }

    let height = calculateGridHeight(items);
    if (collapsed) {
      height += collapsedPositionScreen.height + padding;
    }

    return height;
  }, [screenSpaceItemsById, gridSpaceItemsById, addButtonPositionGrid, editing]);

  useEffect(() => {
    init(unitSize, padding, columns, ref, () => {
      form.reset(props.defaultValues);
      setFormUuid(uuid());
    });
  }, [unitSize, padding, columns]);

  useEffect(() => {
    if (!editing) {
      setRearranging(false);
    }
  }, [editing]);

  useEffect(() => {
    if (lastAddedWidgetId && !editing) {
      setLastAddedWidgetId(null);
    }
  }, [lastAddedWidgetId, editing]);

  useEffect(() => {
    // revalidate widgets after rearranging
    if (!rearranging) {
      form.trigger("widgets");
    }
  }, [form.trigger, rearranging]);

  useEffect(() => {
    // detect click outside of widget board when reordering
    const onClickOutside = (event: MouseEvent) => {
      if (!rearranging) return;

      if (
        event.target instanceof HTMLElement &&
        (event.target.dataset.rearrangeIgnoreClick === "true" ||
          event.target.closest('[data-rearrange-ignore-click="true"]'))
      ) {
        return;
      }

      setRearranging(false);
    };

    document.addEventListener("click", onClickOutside);

    return () => {
      document.removeEventListener("click", onClickOutside);
    };
  }, [rearranging]);

  const onMove = (itemId: Id, newPosition: Position) => {
    // this is executed outside the React lifecycle,
    // so we need to use refs instead of React state

    const gridSpaceItems = widgetsToGridSpaceItemDictionary(form.getValues("widgets"), columns);
    const item = gridSpaceItems[itemId];

    if (!item) return;

    newPosition = {
      x: clamp(newPosition.x, 1, columns - item.width + 1),
      y: clamp(newPosition.y, 1, getLastItem(gridSpaceItems)?.y ?? 1),
    };

    let coordinateArray = toCoordinateArray(Object.values(gridSpaceItems), columns);

    coordinateArray = moveAndSwap(coordinateArray, item, newPosition);

    if (!validatePositions(coordinateArray)) {
      return;
    }

    const orderedArray = toOrderedArray(coordinateArray);
    if (orderedArray.length !== Object.values(gridSpaceItems).length) {
      // we've lost an item (probably dragged into a non-existent row)
      return;
    }

    replace(orderedArray.map((x) => x.widget));
  };

  const onResize = (itemId: Id, newWidth: number) => {
    const item = gridSpaceItemsById[itemId];
    if (!item) return;

    let coordinateArray = toCoordinateArray(Object.values(gridSpaceItemsById), columns);

    let y = item.y - 1;
    let x = item.x - 1;

    const coordinateArrayItem = coordinateArray[y][x];
    if (!coordinateArrayItem) return;

    coordinateArrayItem.width = newWidth;

    const newRow = Array(columns).fill(null);

    // if the widget is 2x1, we need to mame sure it doesn't end up in the middle of the grid
    // or overflowing the grid. swap it with the widget to the left if necessary
    if (newWidth === 2 && x % 2 !== 0) {
      newRow[0] = coordinateArray[y][x - 1];
      coordinateArray[y][x - 1] = coordinateArray[y][x];
      coordinateArray[y][x] = null;
    }

    // take all widgets that are to the right of the resized widget and move them
    // down into a new row to make sure nothing is overlapping
    for (x++; x < columns; x++) {
      newRow[x] = coordinateArray[y][x];
      coordinateArray[y][x] = null;
    }

    coordinateArray.splice(y + 1, 0, newRow);

    // now auto layout to fill the gaps
    coordinateArray = autoLayout(coordinateArray);

    // populate the fields again to make sure the x and y values are correct
    // as well as propogating width changes to the widgets
    coordinateArray = populateFields(coordinateArray);

    if (!validatePositions(coordinateArray)) {
      console.error("onResize: Invalid positions after auto layout.", coordinateArray);
      return;
    }

    const orderedArray = toOrderedArray(coordinateArray);

    // call Object.assign to create a new object so that React will re-render,
    // and we won't have issues with readonly properties on the widget object
    const newWidgets = orderedArray.map((x) => Object.assign({}, x.widget));

    replace(newWidgets);
  };

  const onDelete = (itemId: Id) => {
    // the logic here is similar to onResize, but we're removing the widget instead of resizing it
    // refer to onResize for more detailed comments

    const item = gridSpaceItemsById[itemId];
    if (!item) return;

    let coordinateArray = toCoordinateArray(Object.values(gridSpaceItemsById), columns);

    coordinateArray[item.y - 1][item.x - 1] = null;

    coordinateArray = autoLayout(coordinateArray);

    coordinateArray = populateFields(coordinateArray);

    if (!validatePositions(coordinateArray)) {
      console.error("onDelete: Invalid positions after auto layout.", coordinateArray);
      return;
    }

    const orderedArray = toOrderedArray(coordinateArray);

    const newWidgets = orderedArray.map((x) => Object.assign({}, x.widget));

    replace(newWidgets);
  };

  const autoLayout = (items: CoordinateArray<GridSpaceItem>) =>
    create(items, (draft) => {
      for (let y = 0; y < draft.length; y++) {
        for (let x = 0; x < draft[y].length; x++) {
          const item = draft[y][x];
          if (item !== null) {
            x += item.width - 1;
            continue;
          }

          const nextOccupiedSpace = findNextOccupiedSpace(draft, x, y);
          if (nextOccupiedSpace === null) {
            return draft;
          }

          const next = draft[nextOccupiedSpace.y][nextOccupiedSpace.x];
          if (!next) {
            console.error("autoLayout: Next item doesn't exist. This shouldn't be possible.");
            return draft;
          }

          if (next.width === 1 || (x % 2 === 0 && draft[y][x + 1] === null)) {
            // we want to move the next widget into the empty space if it's a 1x1
            // or if the empty space and the widget are both 2x1
            draft[y][x] = next;
            draft[nextOccupiedSpace.y][nextOccupiedSpace.x] = null;
            x += next.width - 1;
          } else {
            // otherwise if the next widget is 2x1 and the empty space is 1x1
            // we need to get the 1x1 widget that appears next to the empty space
            // and swap it with the 2x1 widget
            const swapX = x % 2 === 0 ? x + 1 : x - 1;
            const swapItem = draft[y][swapX];
            if (!swapItem) {
              console.error("autoLayout: Swap item doesn't exist. This shouldn't be possible.");
              return draft;
            }

            // we need to make sure the 2x1 is at either x position 0 or 2 so that it fits in the grid
            // so if the empty space is at x position 1 or 3, we need to correct it
            draft[y][Math.floor(x / 2) * 2] = next;
            draft[nextOccupiedSpace.y][nextOccupiedSpace.x] = swapItem;
          }
        }
      }

      return draft;
    });

  const validatePositions = (items: CoordinateArray<GridSpaceItem>): boolean => {
    let gapFound = false;

    for (let y = 0; y < items.length; y++) {
      for (let x = 0; x < items[y].length; x++) {
        const current = items[y][x];

        if (current == null) {
          gapFound = true;
          continue;
        }

        if (gapFound) {
          return false;
        }

        // Check if the widget overlaps with any other widget
        for (let i = 0; i < current.width; i++) {
          const overlapping = items[y][x + i];
          if (overlapping && overlapping.id !== current.id) {
            return false;
          }
        }

        // Check if the widget is a 2x1 widget in the middle of the grid
        if (current.x === 2 && current.width === 2) {
          return false;
        }

        x += current.width - 1;
      }
    }

    return true;
  };

  const onAddWidget = (widget: App.Data.Models.WidgetData, rect: Rect) => {
    setInitialRect(rect);

    setLastAddedWidgetId(widget.id);

    const draft = [...widgets];

    const lastItem = getLastItem(gridSpaceItemsById);

    if (!lastItem) {
      draft.push(widget);
      replace(draft);
      return;
    }

    const wideWidgetWouldBeCentered =
      lastItem.width === 1 && lastItem.x === 1 && widget.width === 2;

    const wideWidgetWouldOverflow = lastItem.width === 1 && lastItem.x === 3 && widget.width === 2;

    if (wideWidgetWouldBeCentered || wideWidgetWouldOverflow) {
      draft.splice(-1, 0, widget);
    } else {
      draft.push(widget);
    }

    replace(draft);
  };

  return (
    <div className="flex flex-col gap-4">
      {editing && (
        <div className="flex items-center justify-between">
          <h2 className="font-bold xs:text-lg">Edit widget board</h2>
          <div className="flex items-center gap-2">
            <Popover>
              <PopoverTrigger asChild>
                <Button variant="ghost" size="icon">
                  <Info className="size-5" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="text-sm leading-tight">
                You can press and hold on a widget to enter reorder mode.
              </PopoverContent>
            </Popover>
            <Button
              variant={rearranging ? "default" : "secondary"}
              onClick={() => setRearranging(!rearranging)}
              data-rearrange-ignore-click="true">
              {rearranging ? "Done" : "Rearrange"}
            </Button>
          </div>
        </div>
      )}
      <div
        ref={ref}
        className="relative w-full"
        style={{
          height: gridHeight,
        }}>
        {width > 0 ? (
          <>
            {widgets.map((item, index) => {
              return (
                <Widget
                  name={`widgets.${index}`}
                  // by prefixing the key with a uuid that's regenerated on each form reset,
                  // we can ensure that the widget is re-mounted when the form is reset.
                  // this is a workaround for RHF field arrays not re-rendering when the form is reset.
                  // it means widgets don't animate when the form is reset, but it's the best solution I've found so far.
                  key={`${formUuid}-${item.id}`}
                  gridSpaceItem={gridSpaceItemsById[item.id]}
                  screenSpaceItem={screenSpaceItemsById[item.id]}
                  initialRect={
                    initialRect && lastAddedWidgetId === item.id ? initialRect : undefined
                  }
                  onMove={(position) => onMove(item.id, position)}
                  onResize={(width) => onResize(item.id, width)}
                  onDelete={async () => {
                    const choice = await confirm({
                      title: "Delete widget?",
                      description:
                        "Are you sure you want to delete this widget? All data will be lost.",
                      actionText: "Delete widget",
                    });

                    if (!choice) return;

                    onDelete(item.id);
                  }}
                />
              );
            })}
            <AddWidget
              buttonRect={addButtonPositionScreen}
              freeWidgets={freeWidgets}
              proWidgets={page.props.edit?.available_widgets.pro ?? []}
              showAddButton={showAddButton}
              onAddWidget={onAddWidget}
            />
            <ExpandWidgets
              rect={collapsedPositionScreen}
              collapsed={collapsed}
              onExpand={() => setWidgetsExpanded(true)}
            />
          </>
        ) : null}
      </div>
    </div>
  );
};
