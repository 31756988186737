import React from "react";
import { Page } from "@/API/Page";
import { BaseLayout } from "@/Layouts/BaseLayout";
import { Link } from "@/Components/Atoms/Link";
import { route } from "ziggy-js";
import { ErrorLayout } from "@/Layouts/ErrorLayout";

export type ErrorProps = {
  status: number;
  statusText: string;
  gif: string;
};

const Error: Page<ErrorProps> = (props: ErrorProps) => {
  return (
    <ErrorLayout
      content={
        <div>
          That's a {props.status}. {props.statusText}
        </div>
      }
      footer={<Link href={route("browse.index")}>Go home.</Link>}
      gif={props.gif}
    />
  );
};

Error.layout = (page) => <BaseLayout children={page} />;

export default Error;
