import React, { useMemo, useState } from "react";
import { WidgetPropsBase } from "@/Types/Widgets/WidgetPropsBase";
import { WidgetLayout } from "@/Components/Molecules/Battlestation/Widgets/WidgetLayout";
import { cn } from "@/Utils/shadcn";
import { useBattlestationStore } from "@/Stores/useBattlestationStore";
import { SpotlightEditDialog } from "@/Components/Molecules/Battlestation/Widgets/SpotlightWidget/SpotlightEditDialog";
import { useFormContext, useWatch } from "react-hook-form";
import { BattlestationForm } from "@/API/Forms/BattlestationForm";
import { CropImageDialog } from "@/Components/Molecules/Battlestation/Widgets/CropImageDialog";
import { create } from "mutative";
import { useFormErrors } from "@/Hooks/useFormErrors";
import { Crop } from "react-image-crop";
import { SpotlightViewDialog } from "@/Components/Molecules/Battlestation/Widgets/SpotlightWidget/SpotlightViewDialog";
import { Image } from "@/Components/Atoms/Image";
import { useWidgetsStore } from "@/Stores/useWidgetsStore";
import { BreakpointMap, getResponsiveClasses } from "@/Utils/getResponsiveClasses";
import { ArrowUpRight } from "lucide-react";
import { useMeasure } from "react-use";
import { Glow } from "@/Components/Molecules/Battlestation/Widgets/CustomCardWidget/Glow";
import { usePreloadImages } from "@/Hooks/usePreloadImages";
import { NamedTransformation } from "@/API/NamedTransformation";

const UNIT_TO_PADDING: BreakpointMap = new Map([
  [144, "!p-4"],
  [192, "!p-5"],
]);

const UNIT_TO_FONT_SIZE: BreakpointMap = new Map([
  [144, "!text-xl"],
  [176, "!text-2xl"],
]);

export type SpotlightWidgetProps = WidgetPropsBase<App.Data.Models.WidgetData>;

export const SpotlightWidget = ({ widget, ...props }: SpotlightWidgetProps) => {
  usePreloadImages(
    [widget.spotlight!.image],
    [
      // cropped and scaled down
      {
        transforms: {
          named: NamedTransformation.ImageThumbnail,
        },
      },
      // uncropped full size
      {
        preventCrop: true,
      },
    ],
  );

  const [ref, measure] = useMeasure<HTMLDivElement>();

  const [viewEditOpen, setViewEditOpen] = useState(false);
  const [cropOpen, setCropOpen] = useState(false);

  const [crop, setCrop] = useState<Crop>();

  const form = useFormContext<BattlestationForm>();

  const editing = useBattlestationStore((state) => state.editing);
  const rearranging = useBattlestationStore((state) => state.rearranging);

  // const unitSize = useWidgetsStore((state) => state.unitSize);
  const padding = useWidgetsStore((state) => state.padding);

  const widgetIndex = form.getValues("widgets").findIndex((x) => x.id === widget.id);

  const name: `widgets.${number}` = `widgets.${widgetIndex}`;

  const imageWatcher = useWatch({
    control: form.control,
    name: `${name}.spotlight.image`,
  });

  const image = editing && !props.preview ? imageWatcher! : widget.spotlight!.image;

  const widthWatcher = useWatch({
    control: form.control,
    name: `${name}.width`,
  });

  const width = editing && !props.preview ? widthWatcher! : widget.width;

  const aspect = width === 2 ? 2 : 1;

  const errors = useFormErrors<BattlestationForm>([name]);

  let unitSize = measure.width;
  if (width === 2) {
    unitSize = (unitSize - padding) / 2;
  }

  // not sure why this is off by 2px. maybe border width? (1px on each side)
  const paddingClass = useMemo(() => getResponsiveClasses(UNIT_TO_PADDING, unitSize), [unitSize]);
  const fontSizeClass = useMemo(
    () => getResponsiveClasses(UNIT_TO_FONT_SIZE, unitSize),
    [unitSize],
  );

  return (
    <WidgetLayout
      gridConfig={props.gridConfig}
      clickable={true}
      needsAttention={!widget.spotlight!.name || !widget.spotlight!.image}
      onResize={() => {
        const image = form.getValues(`${name}.spotlight.image`);
        form.setValue(
          `${name}.spotlight.image`,
          create(image, (draft) => {
            if (!draft) return;
            draft.crop = null;
          }),
        );
      }}>
      {!props.preview && (
        <>
          {editing ? (
            <SpotlightEditDialog
              name={`${name}`}
              showErrors={props.gridConfig?.showErrors ?? false}
              aspect={aspect}
              open={viewEditOpen}
              onOpenChange={setViewEditOpen}
              onOpenCrop={() => {
                setViewEditOpen(false);
                setCrop(undefined);
                setCropOpen(true);
              }}
            />
          ) : (
            <SpotlightViewDialog
              spotlight={widget.spotlight!}
              aspect={aspect}
              open={viewEditOpen}
              onOpenChange={setViewEditOpen}
            />
          )}
          <CropImageDialog
            image={image}
            aspect={aspect}
            errors={errors}
            crop={crop}
            setCrop={setCrop}
            open={cropOpen}
            onOpenChange={(open) => {
              setCropOpen(open);
              if (!open) {
                setViewEditOpen(true);
              }
            }}
            onCrop={(crop) => {
              const image = form.getValues(`${name}.spotlight.image`);
              form.setValue(
                `${name}.spotlight.image`,
                create(image, (draft) => {
                  if (!draft) return;
                  draft.crop = crop;
                }),
              );
            }}
          />
        </>
      )}
      <div
        className={cn(
          "flex items-center justify-center overflow-hidden rounded-3xl",
          props.preview ? "size-full" : "absolute inset-0",
          props.preview && (widget.width === 1 ? "aspect-square" : "aspect-[2/1]"),
        )}
        onClick={() => {
          if (rearranging || props.preview) return;
          setViewEditOpen(true);
        }}>
        {!image && <Glow width={widget.width} typeLabel={"PC Build"} />}
        {/* image */}
        <Image
          image={image}
          transform={{
            named: NamedTransformation.ImageThumbnail,
            aspectRatio: `${aspect}-1`,
          }}
          className="size-full border-none bg-transparent object-cover"
        />
        {/* gradient overlay */}
        {image && (
          <div className="pointer-events-none absolute inset-x-0 bottom-0 flex justify-center bg-gradient-to-t from-background/90 to-transparent pb-4 pt-20" />
        )}
        {/* container */}
        <div ref={ref} className="absolute inset-0">
          {/* content */}
          <div
            className={cn(
              "flex size-full flex-col justify-between overflow-visible p-3",
              paddingClass,
            )}>
            <div className="flex items-center justify-end">
              {props.gridConfig?.showErrors || (
                <div className="flex gap-0.5 rounded-full bg-background/60 px-2 py-1 text-sm font-medium backdrop-blur-sm">
                  <span>View</span>
                  <ArrowUpRight className="size-5" />
                </div>
              )}
            </div>
            <h2
              className={cn(
                "line-clamp-2 text-ellipsis text-balance break-words text-lg font-bold",
                fontSizeClass,
                widget.spotlight!.name || "text-primary/80",
                "!leading-tight",
              )}
              title={widget.spotlight!.name || "Featured product"}>
              {widget.spotlight!.name || "Featured product"}
            </h2>
          </div>
        </div>
      </div>
    </WidgetLayout>
  );
};
