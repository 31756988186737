import React, { useMemo } from "react";
import { FormattedNumber } from "@/Components/Atoms/FormattedNumber";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/Components/ui/tooltip";
import { Link } from "@inertiajs/react";
import { route } from "ziggy-js";
import { Button } from "@/Components/ui/button";
import { Bookmark, Copy, MessageCircle, Share, ThumbsUp } from "lucide-react";
import { AnchorLink } from "@/Components/Atoms/AnchorLink";
import { Popover, PopoverContent, PopoverTrigger } from "@/Components/ui/popover";
import { SocialIcon } from "react-social-icons";
import { useUser } from "@/Hooks/useUser";
import { Input } from "@/Components/ui/input";
import { toast } from "sonner";
import { generateShareLink, ShareContent, socialNetworks } from "@/Utils/sharing";
import { useWindowSizeThreshold } from "@/Hooks/useWindowSizeThreshold";
import { Breakpoint } from "@/Types/Enums/Breakpoint";
import { useCommentsContext } from "@/Stores/useCommentsStore";
import { useBattlestationStore } from "@/Stores/useBattlestationStore";
import { img } from "@/Utils/img";
import { NamedTransformation } from "@/API/NamedTransformation";

export type BattlestationActionsProps = {
  battlestation?: App.Data.Models.BattlestationData;
};

export const BattlestationActions = (props: BattlestationActionsProps) => {
  const user = useUser();

  const commentsCount = useCommentsContext((state) => state.commentsCount);

  const setCommentsOpen = useBattlestationStore((state) => state.setCommentsOpen);

  const content = useMemo<ShareContent>(() => {
    if (!props.battlestation) return {};
    return {
      url: route("battlestation.show", { battlestation: props.battlestation }),
      title: props.battlestation.name,
      imageUrl: img(props.battlestation.images?.[0], {
        named: NamedTransformation.ImageRegular,
      }),
    };
  }, [props.battlestation]);

  const shouldOpenCommentsDrawer = useWindowSizeThreshold(Breakpoint.LG);

  return (
    <div className="flex max-w-sm flex-col gap-2">
      {props.battlestation ? (
        <div className="flex items-center gap-2">
          {/* Like */}
          <Tooltip>
            <TooltipTrigger asChild>
              <Link
                as="div"
                href={route("battlestation.upvote", {
                  battlestation: props.battlestation,
                })}
                method="post"
                preserveState
                preserveScroll
                className="flex-1">
                <Button
                  variant={props.battlestation.voted ? "default" : "secondary"}
                  size="lg"
                  className="w-full px-4 font-medium">
                  <ThumbsUp className="mr-2 h-4 w-4" />
                  <FormattedNumber number={props.battlestation.points!} />
                </Button>
              </Link>
            </TooltipTrigger>
            <TooltipContent>Like</TooltipContent>
          </Tooltip>
          {/* Comment */}
          <Tooltip>
            <TooltipTrigger asChild>
              {shouldOpenCommentsDrawer ? (
                <div className="flex-1">
                  <Button
                    onClick={() => {
                      setCommentsOpen(true);
                    }}
                    variant="secondary"
                    size="lg"
                    className="w-full px-4 font-medium">
                    <MessageCircle className="mr-2 h-4 w-4" />
                    <FormattedNumber number={commentsCount ?? 0} />
                  </Button>
                </div>
              ) : (
                <AnchorLink className="flex-1" href="#comments">
                  <Button variant="secondary" size="lg" className="w-full px-4 font-medium">
                    <MessageCircle className="mr-2 h-4 w-4" />
                    <FormattedNumber number={commentsCount ?? 0} />
                  </Button>
                </AnchorLink>
              )}
            </TooltipTrigger>
            <TooltipContent>
              {shouldOpenCommentsDrawer ? "Open comments" : "Jump to comments"}
            </TooltipContent>
          </Tooltip>
          {/* Share */}
          <Popover>
            <PopoverTrigger className="flex-1" asChild>
              <div>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button variant="secondary" size="lg" className="w-full px-4 font-medium">
                      <Share className="h-4 w-4" />
                      <span className="ml-2 inline md:hidden lg:inline">Share</span>
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>Share</TooltipContent>
                </Tooltip>
              </div>
            </PopoverTrigger>
            <PopoverContent collisionPadding={16}>
              <div className="grid gap-4">
                <div className="space-y-0.5">
                  <h4 className="font-medium leading-none">Share</h4>
                  <p className="text-sm text-muted-foreground">
                    Share your battlestation or link to it.
                  </p>
                </div>
                <div className="grid grid-cols-5 gap-2">
                  <Button
                    size="icon"
                    className="aspect-square h-auto w-full border bg-reddit hover:bg-reddit/90">
                    <SocialIcon
                      network="reddit"
                      url={generateShareLink(content, socialNetworks.reddit)}
                      target="_blank"
                      rel="noopener noreferrer"
                      bgColor="rgba(0,0,0,0)"
                      className="!h-10 !w-10"
                    />
                  </Button>
                  <Button
                    size="icon"
                    className="aspect-square h-auto w-full border bg-pinterest hover:bg-pinterest/90">
                    <SocialIcon
                      network="pinterest"
                      url={generateShareLink(content, socialNetworks.pinterest)}
                      target="_blank"
                      rel="noopener noreferrer"
                      bgColor="rgba(0,0,0,0)"
                      className="!h-10 !w-10"
                    />
                  </Button>
                  <Button
                    size="icon"
                    className="aspect-square h-auto w-full border bg-facebook hover:bg-facebook/90">
                    <SocialIcon
                      network="facebook"
                      url={generateShareLink(content, socialNetworks.facebook)}
                      target="_blank"
                      rel="noopener noreferrer"
                      bgColor="rgba(0,0,0,0)"
                      className="!h-10 !w-10"
                    />
                  </Button>
                  <Button
                    size="icon"
                    className="aspect-square h-auto w-full border bg-telegram hover:bg-telegram/90">
                    <SocialIcon
                      network="telegram"
                      url={generateShareLink(content, socialNetworks.telegram)}
                      target="_blank"
                      rel="noopener noreferrer"
                      bgColor="rgba(0,0,0,0)"
                      className="!h-10 !w-10"
                    />
                  </Button>
                  <Button
                    size="icon"
                    className="aspect-square h-auto w-full border bg-twitter hover:bg-twitter/90">
                    <SocialIcon
                      network="x"
                      url={generateShareLink(content, socialNetworks.twitter)}
                      target="_blank"
                      rel="noopener noreferrer"
                      bgColor="rgba(0,0,0,0)"
                      className="!h-10 !w-10"
                    />
                  </Button>
                </div>
                <div className="grid grid-cols-5 gap-2">
                  <Input
                    id="share-url"
                    className="col-span-4"
                    value={window.location.href}
                    readOnly
                    onClick={(event) => {
                      event.currentTarget.select();
                      navigator.clipboard.writeText(window.location.href);
                      toast.success("Copied to clipboard.");
                    }}
                  />
                  <Button
                    size="icon"
                    variant="secondary"
                    className="w-full"
                    onClick={() => {
                      const input = document.getElementById("share-url") as HTMLInputElement;
                      input?.select();
                      navigator.clipboard.writeText(window.location.href);
                      toast.success("Copied to clipboard.");
                    }}>
                    <Copy className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            </PopoverContent>
          </Popover>
          {/* Save */}
          <Tooltip>
            <TooltipTrigger asChild>
              <Link
                as="div"
                href={route("battlestation.save", {
                  battlestation: props.battlestation,
                })}
                method="post"
                preserveScroll={!!user}>
                <Button
                  variant={props.battlestation.saved ? "default" : "ghost"}
                  size="lg"
                  className="w-full px-4 font-medium">
                  <Bookmark className="h-4 w-4" />
                </Button>
              </Link>
            </TooltipTrigger>
            <TooltipContent>Save</TooltipContent>
          </Tooltip>
        </div>
      ) : (
        <div className="flex items-center gap-2">
          <Button variant="secondary" size="lg" className="w-full px-4 font-medium">
            <ThumbsUp className="mr-2 h-4 w-4" />0
          </Button>
          <Button variant="secondary" size="lg" className="w-full px-4 font-medium">
            <MessageCircle className="mr-2 h-4 w-4" />0
          </Button>
          <Button variant="secondary" size="lg" className="w-full px-4 font-medium">
            <Share className="h-4 w-4" />
            <span className="ml-2 hidden lg:inline">Share</span>
          </Button>
          <Button variant="secondary" size="lg" className="w-full px-4 font-medium">
            <Bookmark className="h-4 w-4" />
          </Button>
        </div>
      )}
    </div>
  );
};
