import React from "react";
import { Head } from "@/Components/Atoms/Head";
import { Container } from "@/Components/Atoms/Container";
import { BattlestationCardSmall } from "@/Components/Molecules/BattlestationCardSmall";
import { FaceFrownIcon } from "@heroicons/react/24/outline";
import { SimpleBanner } from "@/Components/Molecules/SimpleBanner";
import { Bookmark } from "lucide-react";

export type SavedBattlestationsProps = {
  battlestations: Array<App.Data.Models.BattlestationData>;
};

const SavedBattlestations = (props: SavedBattlestationsProps) => {
  return (
    <>
      <Head title="Saved setups" />
      <SimpleBanner icon={Bookmark}>
        <h2 className="text-3xl font-bold leading-tight text-white">Saved setups</h2>
      </SimpleBanner>
      <Container className="py-6">
        {/* Battlestations */}
        <div className="flex flex-col gap-2">
          {(props.battlestations.length ?? 0) > 0 ? (
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
              {props.battlestations.map((battlestation) => (
                <BattlestationCardSmall key={battlestation.id} battlestation={battlestation} />
              ))}
            </div>
          ) : (
            <div className="relative block w-full rounded-lg border-2 border-dashed border-gray-700 p-8 text-center">
              <FaceFrownIcon className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-medium">
                You haven't saved any battlestations yet.
              </h3>
              <span className="mt-1 text-sm text-gray-400">
                Save your favourite battlestations so you can come back to them later!
              </span>
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default SavedBattlestations;
