import React, { ReactNode } from "react";
import { Container } from "@/Components/Atoms/Container";
import { Link } from "@/Components/Atoms/Link";
import { route } from "ziggy-js";
import { Head } from "@/Components/Atoms/Head";

export type ErrorLayoutProps = {
  title?: string;
  content?: ReactNode;
  footer?: ReactNode;
  gif?: string;
};

export const ErrorLayout = ({
  title = "Ah crap",
  content = "Looks like something went wrong.",
  footer = <Link href={route("browse.index")}>Go home</Link>,
  ...props
}: ErrorLayoutProps) => {
  return (
    <Container className="flex h-screen !max-w-4xl flex-col items-center py-12 md:flex-row md:justify-between">
      <Head title={title} />
      <div className="flex flex-col items-start gap-6">
        <h1 className="heading-xl text-5xl">{title}</h1>
        <div className="text-gray-300">{content}</div>
        {footer}
      </div>
      {props.gif && <img src={props.gif} alt="" className="w-42 mt-12 rounded-md md:m-0 md:w-72" />}
    </Container>
  );
};
