import React, { UIEvent, useEffect, useMemo, useRef, useState } from "react";
import { Avatar } from "@/Components/Atoms/Avatar";
import { cn } from "@/Utils/shadcn";
import { Link as InertiaLink } from "@inertiajs/react";
import { route } from "ziggy-js";
import { Points } from "@/Components/Atoms/Points";
import { CommentInput } from "@/Components/Molecules/CommentInput";
import parse, { domToReact, Element } from "html-react-parser";
import { Link } from "@/Components/Atoms/Link";
import { Spinner } from "@/Components/Atoms/Spinner";
import { useUser } from "@/Hooks/useUser";
import { useWindowSize } from "@/Hooks/useWindowSize";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/Components/ui/dropdown-menu";
import { Button, buttonVariants } from "@/Components/ui/button";
import {
  ChevronDown,
  ChevronUp,
  EllipsisVertical,
  Pencil,
  Reply,
  ThumbsUp,
  Trash,
} from "lucide-react";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/Components/ui/collapsible";
import { Form, FormControl, FormField } from "@/Components/ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { router } from "@inertiajs/react";
import { useCommentsContext } from "@/Stores/useCommentsStore";
import { useConfirm } from "@/Contexts/ConfirmDialogContext";
import { CommentForm, CommentFormSchema } from "@/API/Forms/CommentForm";
import { TextareaAutosize } from "@/Components/ui/textarea";
import { useTruncate } from "@/Hooks/useTruncate";
import { CommentType } from "@/Types/Enums/CommentType";
import { Wrap } from "@/Components/Atoms/Wrap";
import { VerifiedBadge } from "@/Components/Atoms/VerifiedBadge";

export type CommentProps = {
  comment: App.Data.Models.CommentData;
  rootComment?: App.Data.Models.CommentData;
};

export const Comment = (props: CommentProps) => {
  const confirm = useConfirm();

  const focusedCommentId = useCommentsContext((state) => state.focusedCommentId);
  const loadMoreReplies = useCommentsContext((state) => state.loadMoreReplies);
  const addReply = useCommentsContext((state) => state.addReply);
  const toggleLiked = useCommentsContext((state) => state.toggleLiked);
  const editComment = useCommentsContext((state) => state.editComment);
  const deleteComment = useCommentsContext((state) => state.deleteComment);

  const user = useUser();

  const editTextareaRef = useRef<HTMLTextAreaElement>(null);
  const commentBodyRef = useRef<HTMLParagraphElement>(null);

  const { width } = useWindowSize();

  const { isTruncated, isShowingMore, setIsShowingMore } = useTruncate(commentBodyRef, [
    width,
    props.comment.body,
  ]);

  const [showReplies, setShowReplies] = useState(() => props.comment.replies_preloaded);

  const [loadingReplies, setLoadingReplies] = useState(false);
  const [editing, setEditing] = useState(false);
  const [replying, setReplying] = useState(false);

  const editForm = useForm<CommentForm>({
    resolver: zodResolver(CommentFormSchema),
    defaultValues: {
      body: props.comment.body_plaintext,
    },
  });

  const toggleLikedForCommentOrReply = () => {
    if (props.rootComment) {
      toggleLiked(props.rootComment.id, props.comment.id);
    } else {
      toggleLiked(props.comment.id);
    }
  };

  // show replies when they finish loading
  useEffect(() => {
    if (!loadingReplies) return;

    setShowReplies(!!props.comment.replies);

    if (props.comment.replies) {
      setLoadingReplies(false);
    }
  }, [props.comment.replies]);

  const parseText = (text: string) =>
    parse(text, {
      replace: (domNode) => {
        if (domNode instanceof Element && domNode.name === "a") {
          return <Link href={domNode.attribs.href}>{domToReact(domNode.children)}</Link>;
        }
      },
    });

  const parsedBody = useMemo(() => parseText(props.comment.body), [props.comment.body]);

  useEffect(() => {
    if (editing) {
      const strLength = (editTextareaRef.current?.value.length ?? 0) * 2;

      editTextareaRef.current?.focus();
      editTextareaRef.current?.setSelectionRange(strLength, strLength);
    }
  }, [editing]);

  const redirectIfNoAuth = (e: UIEvent) => {
    if (!user) {
      e.preventDefault();
      return router.get(
        route("login"),
        {},
        {
          preserveState: true,
          preserveScroll: true,
        },
      );
    }

    if (user.email_verified_at == null) {
      e.preventDefault();
      return router.get(
        route("verification.notice"),
        {},
        {
          preserveState: true,
          preserveScroll: true,
        },
      );
    }
  };

  return (
    <div id={`comment-${props.comment.id}`}>
      <div
        className={cn(
          "group flex gap-3 text-xs xs:gap-4 xs:text-sm",
          focusedCommentId == props.comment.id && "animate-highlight",
        )}>
        <Wrap
          if={!!props.comment.author}
          with={(children) => (
            <InertiaLink
              disabled={props.comment.deleted}
              href={route("user.show", {
                user: props.comment.author!,
              })}
              className="shrink-0 self-start"
              aria-label={`View ${props.comment.author!.username}'s profile`}>
              {children}
            </InertiaLink>
          )}>
          <Avatar
            image={props.comment.author?.avatar}
            username={props.comment.author?.username}
            className={cn(props.rootComment == null ? "size-8 xs:size-12" : "size-8")}
          />
        </Wrap>
        <div className="flex min-w-0 flex-1 flex-col gap-1">
          {/* Username & created at */}
          <div className={cn("flex items-center gap-2 xs:gap-4", editing && "mb-2")}>
            <Wrap
              if={!!props.comment.author}
              with={(children) => (
                <div className="flex items-center gap-1">
                  <InertiaLink
                    disabled={props.comment.deleted}
                    href={route("user.show", {
                      user: props.comment.author!,
                    })}>
                    {children}
                  </InertiaLink>
                  <VerifiedBadge for={props.comment.author!} className="size-4" />
                </div>
              )}>
              <span
                className={cn(
                  "self-start font-medium leading-tight",
                  props.comment.author?.username || "text-gray-500",
                )}>
                {props.comment.author?.username ?? "[deleted user]"}
              </span>
            </Wrap>
            <span className="leading-tight text-gray-400">{props.comment.created_at}</span>
          </div>
          {editing ? (
            // Textarea for editing comment
            <Form
              {...editForm}
              className="space-y-2"
              onSubmit={async (data, resolve, reject) => {
                try {
                  if (props.rootComment) {
                    await editComment(data, props.rootComment.id, props.comment.id);
                  } else {
                    await editComment(data, props.comment.id);
                  }

                  setEditing(false);
                  setIsShowingMore(false);

                  editForm.reset({
                    body: data.body,
                  });

                  resolve();
                } catch (error) {
                  reject();
                  throw error;
                }
              }}>
              <FormField
                control={editForm.control}
                name="body"
                render={({ field }) => (
                  <FormControl>
                    <TextareaAutosize
                      autoFocus
                      minRows={2}
                      placeholder="Edit your comment..."
                      className="resize-none"
                      {...field}
                    />
                  </FormControl>
                )}
              />
              <div className="mt-3 flex justify-end">
                <div className="flex gap-2">
                  <Button
                    type="reset"
                    variant="ghost"
                    onClick={() => {
                      editForm.reset();
                      setEditing(false);
                    }}>
                    Cancel
                  </Button>
                  <Button type="submit">Save</Button>
                </div>
              </div>
            </Form>
          ) : (
            // Comment body, action buttons, and replies
            <Collapsible open={replying} onOpenChange={setReplying} className="flex flex-col gap-2">
              <p
                ref={commentBodyRef}
                className={cn(
                  "text-ellipsis whitespace-pre-line break-words text-sm",
                  props.comment.deleted ? "text-gray-500" : "text-gray-100",
                  isShowingMore || "line-clamp-6",
                )}>
                {parsedBody}
              </p>
              {isTruncated && (
                <div>
                  <button
                    type="button"
                    className="text-sm text-indigo-300 hover:text-indigo-200"
                    onClick={() => setIsShowingMore((current) => !current)}>
                    {isShowingMore ? "Show less" : "Show more"}
                  </button>
                </div>
              )}
              <div className="flex gap-2">
                <InertiaLink
                  as="button"
                  onClick={redirectIfNoAuth}
                  disabled={props.comment.deleted}
                  href={route("comment.upvote", {
                    comment: props.comment,
                  })}
                  onStart={toggleLikedForCommentOrReply}
                  onError={toggleLikedForCommentOrReply}
                  method="post"
                  preserveState
                  preserveScroll
                  className={cn(
                    buttonVariants({
                      variant: props.comment.voted ? "default" : "outline",
                      size: "sm",
                    }),
                    "h-7 border",
                  )}>
                  <ThumbsUp className="mr-1.5 size-3.5" />
                  <Points points={props.comment.points} appendSuffix={false} />
                </InertiaLink>
                <CollapsibleTrigger
                  asChild
                  onClick={redirectIfNoAuth}
                  disabled={props.comment.deleted}>
                  <Button variant="outline" size="sm" className="h-7">
                    <Reply className="mr-1.5 size-3.5" />
                    Reply
                  </Button>
                </CollapsibleTrigger>
              </div>
              <CollapsibleContent className={cn("mt-3", props.rootComment != null && "-ml-12")}>
                <CommentInput
                  autoFocus
                  type={CommentType.Reply}
                  entity={props.rootComment ?? props.comment}
                  defaultValue={`@${props.comment.author?.username} `}
                  onCancel={() => setReplying(false)}
                  onSubmit={async (data) => {
                    if (!props.comment.replies) {
                      setLoadingReplies(true);
                    }

                    await addReply(data, props.rootComment?.id ?? props.comment.id);

                    setShowReplies(true);
                    setReplying(false);
                  }}
                />
              </CollapsibleContent>
              {props.comment.replies_count ? (
                // Button to expand and collapse replies
                <div className="flex items-center gap-3">
                  <button
                    type="button"
                    disabled={loadingReplies}
                    className="flex items-center gap-1 self-start text-xs text-indigo-400 hover:text-indigo-300 xs:text-sm"
                    onClick={() => {
                      if (loadingReplies) return;

                      if (!showReplies) {
                        if (!props.comment.replies) {
                          setLoadingReplies(true);
                          loadMoreReplies(props.rootComment?.id ?? props.comment.id);
                        } else {
                          setShowReplies(true);
                        }
                      } else {
                        setShowReplies(false);
                      }
                    }}>
                    {showReplies ? (
                      <>
                        Collapse {props.comment.replies_count === 1 ? "reply" : "replies"}
                        <ChevronUp className="mt-0.5 size-5" />
                      </>
                    ) : (
                      <>
                        View{" "}
                        {props.comment.replies_count === 1
                          ? "reply"
                          : `${props.comment.replies_count} replies`}
                        <ChevronDown className="mt-0.5 size-5" />
                      </>
                    )}
                  </button>
                  {loadingReplies && <Spinner className="h-4 w-4" />}
                </div>
              ) : null}
            </Collapsible>
          )}
        </div>
        {!editing && !props.comment.deleted && props.comment.author?.id === user?.id && (
          // Vertical dots menu
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                className="size-7 opacity-100 group-hover:opacity-100 data-[state=open]:opacity-100 lg:pointer-fine:opacity-0">
                <EllipsisVertical className="size-4" />
                <span className="sr-only">Open comment options</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem onClick={() => setEditing(true)}>
                <Pencil className="mr-1.5 size-3.5" />
                Edit
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={async () => {
                  const choice = await confirm({
                    title: "Delete comment?",
                    description: "Are you sure you want to permanently delete this comment?",
                    actionText: "Delete comment",
                  });

                  if (!choice) return;

                  if (props.rootComment) {
                    await deleteComment(props.rootComment?.id, props.comment.id);
                  } else {
                    await deleteComment(props.comment.id);
                  }
                }}>
                <Trash className="mr-1.5 size-3.5" />
                Delete
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </div>
      {props.comment.replies?.data?.length && showReplies ? (
        // Replies
        <div className="mt-3">
          <Replies replies={props.comment.replies.data} rootComment={props.comment} />
          {props.comment.replies.data.length < props.comment.replies_count! ? (
            <div className="ml-16 mt-3 flex items-center gap-3">
              <button
                disabled={loadingReplies}
                className="flex items-center gap-1 self-start text-sm font-medium text-indigo-400 hover:text-indigo-300"
                onClick={() => {
                  if (loadingReplies) return;
                  setLoadingReplies(true);
                  loadMoreReplies(props.rootComment?.id ?? props.comment.id);
                }}>
                Load more replies
                <ChevronDown className="mt-0.5 size-5" />
              </button>
              {loadingReplies && <Spinner className="size-4" />}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

type RepliesProps = {
  replies: App.Data.Models.CommentData[];
  rootComment: App.Data.Models.CommentData;
};

const Replies = (props: RepliesProps) => {
  return (
    <div className="ml-12 flex flex-col gap-4 xs:ml-16">
      {props.replies.map((comment) => (
        <Comment key={comment.id} comment={comment} rootComment={props.rootComment} />
      ))}
    </div>
  );
};
