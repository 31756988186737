import { usePage } from "@inertiajs/react";
import { ResponseBase } from "@/API/ResponseBase";

export const useAbilities = () => {
  const {
    props: {
      auth: { can },
    },
  } = usePage<ResponseBase>();

  return can;
};
