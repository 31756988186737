import { ElementType } from "react";
import { Keyboard, MonitorSpeaker, Mouse, PcCase, Sofa, TabletSmartphone } from "lucide-react";
import { CardType } from "@/Types/generated_enums";

export const CardTypeIcon = new Map<CardType, ElementType>([
  [CardType.None, "div"],
  [CardType.Decoration, Sofa],
  [CardType.PCBuild, PcCase],
  [CardType.Peripherals, Mouse],
  [CardType.Audio, MonitorSpeaker],
  [CardType.Devices, TabletSmartphone],
  [CardType.KeyboardBuild, Keyboard],
]);

export const CardTypeColor = new Map<CardType, string>([
  [CardType.Decoration, "bg-[#40a44b]"],
  [CardType.PCBuild, "bg-[#4f45e4]"],
  [CardType.Peripherals, "bg-[#b64c9b]"],
  [CardType.Audio, "bg-teal-600"],
  [CardType.Devices, "bg-sky-600"],
  [CardType.KeyboardBuild, "bg-[#9a45e4]"],
]);
