import create from "zustand";

export type FileUploadsStore = {
  uploadsInProgress: number;
  uploadStarted: () => void;
  uploadFinished: () => void;
};

export const useFileUploadsStore = create<FileUploadsStore>((set) => ({
  uploadsInProgress: 0,
  uploadStarted: () => set((state) => ({ uploadsInProgress: state.uploadsInProgress + 1 })),
  uploadFinished: () => set((state) => ({ uploadsInProgress: state.uploadsInProgress - 1 })),
}));
