import React, { HTMLAttributes } from "react";
import { cn } from "@/Utils/shadcn";

export type LogoProps = HTMLAttributes<SVGSVGElement> & {
  svgClassName?: string;
};

export const Logo = ({ className, svgClassName, ...props }: LogoProps) => {
  return (
    <div className={cn("rounded-md bg-gradient-to-r from-indigo-600 to-blue-500 p-0.5", className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className={svgClassName}
        {...props}>
        <circle cx="12.02" cy="11.99" r="2.28" />
        <path d="M12.24 3.07a1.73 1.73 0 0 0-1-.81C9 1.71 6.39 3.09 5.1 4.8a.28.28 0 0 0 .25.47 8.87 8.87 0 0 1 1.34-.35 3.92 3.92 0 0 1 3.43 1.52 7.66 7.66 0 0 1 1.06 1.7.3.3 0 0 0 .34.18 2.54 2.54 0 0 1 1.1 0 .27.27 0 0 0 .32-.23 11.21 11.21 0 0 0-.7-5.02ZM3.61 9a1.77 1.77 0 0 0-1.09.71c-1.21 2-.7 4.86.53 6.61.21.3.52.13.52-.08a8.9 8.9 0 0 1 .09-1.38 3.91 3.91 0 0 1 2.5-2.8 7.76 7.76 0 0 1 1.94-.49.3.3 0 0 0 .28-.26 2.52 2.52 0 0 1 .38-1 .28.28 0 0 0-.11-.38A11.32 11.32 0 0 0 3.61 9ZM6.6 19.07a1.75 1.75 0 0 0 .34 1.25c1.5 1.77 4.4 2.17 6.45 1.54a.27.27 0 0 0 .08-.52 8.14 8.14 0 0 1-1.29-.51 3.91 3.91 0 0 1-1.88-3.24 7.46 7.46 0 0 1 .13-2 .28.28 0 0 0-.16-.34 2.86 2.86 0 0 1-.86-.69.27.27 0 0 0-.4 0 11.25 11.25 0 0 0-2.41 4.51Zm10.48.26a1.75 1.75 0 0 0 1.29.06c2.15-.88 3.42-3.52 3.46-5.66 0-.36-.34-.4-.47-.23a8.3 8.3 0 0 1-.88 1.06 3.93 3.93 0 0 1-3.67.8 7.71 7.71 0 0 1-1.81-.75.28.28 0 0 0-.37.05 2.81 2.81 0 0 1-.92.61.27.27 0 0 0-.13.37 11.26 11.26 0 0 0 3.5 3.69Zm3.48-9.89A1.71 1.71 0 0 0 21 8.23c-.18-2.31-2.29-4.34-4.31-5-.35-.12-.49.2-.37.37a9 9 0 0 1 .74 1.17 3.88 3.88 0 0 1-.38 3.73 7.21 7.21 0 0 1-1.26 1.5.3.3 0 0 0-.07.38 2.56 2.56 0 0 1 .29 1.06.29.29 0 0 0 .32.24 11.35 11.35 0 0 0 4.6-2.24Z" />
      </svg>
    </div>
  );
};
