import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useFieldArray, useFormContext, useFormState } from "react-hook-form";
import { CreateForm } from "@/API/Forms/CreateForm";
import { FormControl, FormField, FormItem, FormMessage } from "@/Components/ui/form";
import { CreateLayout } from "@/Layouts/CreateLayout";
import { FileInput } from "@/Components/Atoms/FileInput";
import { useUploadImages } from "@/Hooks/useUploadImages";
import { IMAGE_LIMIT } from "@/Utils/constants";
import { Image } from "@/Components/Atoms/Image";
import { X } from "lucide-react";
import { Button } from "@/Components/ui/button";
import { DialogWrapper } from "@/Components/Molecules/DialogWrapper";

export type StepTwoProps = {
  setStep: Dispatch<SetStateAction<number>>;
};

const StepTwo = (props: StepTwoProps) => {
  const form = useFormContext<CreateForm>();

  const {
    fields: images,
    append,
    remove,
  } = useFieldArray({
    control: form.control,
    name: "stepTwo.images",
  });

  const state = useFormState({
    control: form.control,
    name: "stepTwo.images",
    exact: true,
  });

  const [uploading, upload] = useUploadImages();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (images.length === 0 && open) {
      setOpen(false);
    }
  }, [images]);

  return (
    <CreateLayout
      step={2}
      form={form}
      dirty={state.isDirty}
      onNext={async () => {
        let isValid = await form.trigger("stepTwo.images");
        if (!isValid) return;

        props.setStep((step) => step + 1);
      }}
      onBack={() => {
        props.setStep((step) => step - 1);
      }}>
      <div className="flex flex-col items-center gap-6">
        <div className="flex flex-col items-center gap-2">
          <h2 className="text-2xl font-bold leading-tight tracking-tight">Upload some photos</h2>
          <p className="text-balance text-center text-sm text-muted-foreground">
            Add up to 10 photos of your setup. These can be cropped and re-ordered later.
          </p>
        </div>
        <FormField
          control={form.control}
          name="stepTwo.images"
          render={() => (
            <FormItem className="flex w-full flex-col items-center gap-4">
              {/* manage images dialog */}
              <DialogWrapper
                open={open}
                setOpen={setOpen}
                title="Uploaded photos"
                description="Browse and manage your uploaded photos.">
                <div className="grid grid-cols-3 gap-4 px-4 sm:grid-cols-4 sm:px-0">
                  {images.map((image, index) => (
                    <div key={image.id} className="relative">
                      <Image
                        key={image.id}
                        image={image}
                        className="aspect-square rounded-lg border object-cover"
                      />
                      <Button
                        type="button"
                        size="icon"
                        onClick={() => remove(index)}
                        className="absolute right-0 top-0 z-10 size-6 -translate-y-1/4 translate-x-1/4 border-2 border-background">
                        <X className="size-4" />
                      </Button>
                    </div>
                  ))}
                </div>
              </DialogWrapper>
              {/* uploaded images preview */}
              {images.length ? (
                <div className="flex items-center justify-center gap-2">
                  {images.slice(0, 3).map((image, index) => (
                    <div key={image.id} className="relative">
                      <Image
                        key={image.id}
                        image={image}
                        className="aspect-square w-16 rounded-lg border object-cover sm:size-20"
                      />
                      <Button
                        type="button"
                        size="icon"
                        onClick={() => remove(index)}
                        className="absolute right-0 top-0 z-10 size-6 -translate-y-1/4 translate-x-1/4 border-2 border-background">
                        <X className="size-4" />
                      </Button>
                    </div>
                  ))}
                  {images.length > 3 ? (
                    <button
                      type="button"
                      onClick={() => setOpen(true)}
                      className="flex aspect-square w-16 items-center justify-center rounded-lg border text-xs text-muted-foreground transition-all hover:bg-secondary hover:text-secondary-foreground active:scale-95 sm:size-20 sm:text-sm">
                      +{images.length - 3} more
                    </button>
                  ) : null}
                </div>
              ) : null}
              <FormControl>
                <div className="w-full">
                  <FileInput
                    uploading={uploading}
                    disabled={images.length >= IMAGE_LIMIT}
                    onChange={async (files) => {
                      const newImages = await upload(files, IMAGE_LIMIT - images.length);
                      if (newImages) {
                        append(newImages);
                      }
                    }}
                    className="h-32 sm:h-40"
                    multiple
                  />
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </CreateLayout>
  );
};

export default StepTwo;
