import React, { PropsWithChildren, ReactNode } from "react";

export type WrapProps = PropsWithChildren<{
  if: boolean;
  with: (children: ReactNode) => JSX.Element;
}>;

export const Wrap = (props: WrapProps) => {
  return props.if ? props.with(props.children) : <>{props.children}</>;
};
