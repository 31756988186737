import ImageKit from "imagekit-javascript";
import { Environment } from "@/API/Enums/Environment";
import { useEnvironment } from "@/Hooks/useEnvironment";

const ENDPOINT_BASE = "https://ik.imagekit.io/rigrgg/";

export const EnvironmentEndpoint = new Map<string, string>([
  [Environment.Development, `${ENDPOINT_BASE}/development`],
  [Environment.Staging, `${ENDPOINT_BASE}/staging`],
  [Environment.Production, `${ENDPOINT_BASE}/production`],
]);

let imageKit: ImageKit;

export const useImageKit = () => {
  const environment = useEnvironment();

  if (!imageKit) {
    imageKit = new ImageKit({
      urlEndpoint: EnvironmentEndpoint.get(environment)!,
    });
  }

  return imageKit;
};
