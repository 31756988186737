import React, { useEffect, useState } from "react";
import StepOne from "@/Pages/Battlestation/Create/StepOne";
import { FormProvider, useForm } from "react-hook-form";
import { createSchema } from "@/Schemas/createSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { CreateForm } from "@/API/Forms/CreateForm";
import StepTwo from "@/Pages/Battlestation/Create/StepTwo";
import StepThree from "@/Pages/Battlestation/Create/StepThree";
import StepFour from "@/Pages/Battlestation/Create/StepFour";
import StepFive from "@/Pages/Battlestation/Create/StepFive";
import { router } from "@inertiajs/react";

export type CreateProps = {
  availableTags: Array<App.Data.Models.TagData>;
};

const Create = () => {
  const [step, setStep] = useState(1);

  const form = useForm<CreateForm>({
    resolver: zodResolver(createSchema),
    defaultValues: {
      stepOne: {
        details: "",
      },
      stepTwo: {
        images: [],
      },
      stepThree: {
        tags: [],
      },
      stepFour: {
        about: "",
      },
      stepFive: {
        name: "",
      },
    },
  });

  useEffect(() => {
    const beforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = true;
    };

    window.addEventListener("beforeunload", beforeUnload);

    const removeListener = router.on("before", (event) => {
      if (event.detail.visit.method === "get") {
        return confirm("You have unsaved changes. Are you sure you want to leave?");
      }
    });

    return () => {
      window.removeEventListener("beforeunload", beforeUnload);
      removeListener();
    };
  }, []);

  return (
    <FormProvider {...form}>
      {step === 1 && <StepOne setStep={setStep} />}
      {step === 2 && <StepTwo setStep={setStep} />}
      {step === 3 && <StepThree setStep={setStep} />}
      {step === 4 && <StepFour setStep={setStep} />}
      {step === 5 && <StepFive setStep={setStep} />}
    </FormProvider>
  );
};

export default Create;
