import React, { ReactNode } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { Button } from "@/Components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/Components/ui/card";

export type FormBoxProps<T extends FieldValues> = {
  form: UseFormReturn<T>;
  title: string;
  description: string;
  children: ReactNode;
  disabled?: boolean;
};

export const FormBox = <T extends FieldValues>({
  form,
  disabled = false,
  ...props
}: FormBoxProps<T>) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>{props.title}</CardTitle>
        <CardDescription>{props.description}</CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">{props.children}</CardContent>
      <CardFooter className="justify-end gap-2 border-t px-6 py-4">
        <Button
          type="reset"
          variant="ghost"
          disabled={!form.formState.isDirty}
          onClick={() => {
            form.clearErrors();
            form.reset();
          }}>
          Reset
        </Button>
        <Button
          type="submit"
          disabled={!form.formState.isDirty}
          loading={form.formState.isSubmitting}>
          Save
        </Button>
      </CardFooter>
    </Card>
  );
};
