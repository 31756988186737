import React from "react";
import { Head } from "@/Components/Atoms/Head";
import { AuthenticationLayout } from "@/Layouts/AuthenticationLayout";
import { route } from "ziggy-js";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/Components/ui/form";
import { Input } from "@/Components/ui/input";
import { Button } from "@/Components/ui/button";

const formSchema = z.object({
  email: z.string().email(),
});

const ForgotPassword = () => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  });

  return (
    <>
      <Head title="Forgot password" />
      <AuthenticationLayout
        heading="Forgot password?"
        subheading="Enter your email below to receive a link to reset your password">
        <Form {...form} route={route("password.forgot.store")} className="space-y-4">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input placeholder="Enter email..." autoComplete="email" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type="submit" className="w-full">
            Send password reset link
          </Button>
        </Form>
      </AuthenticationLayout>
    </>
  );
};

export default ForgotPassword;
