import { Disclosure } from "@headlessui/react";
import React, { Fragment, MutableRefObject, useEffect } from "react";
import { NavigationItem, UserNavigationItem } from "@/Components/Organisms/Navbar/Navbar";
import { router, Link, usePage } from "@inertiajs/react";
import { ResponseBase } from "@/API/ResponseBase";
import { Avatar } from "@/Components/Atoms/Avatar";
import { cn } from "@/Utils/shadcn";
import { route } from "ziggy-js";
import { Discord } from "@/Components/Atoms/Icons";
import { NavbarNotifications } from "@/Components/Organisms/Navbar/NavbarNotifications";
import { buttonVariants } from "@/Components/ui/button";
import { Separator } from "@/Components/ui/separator";
import { Points } from "@/Components/Atoms/Points";
import { VerifiedBadge } from "@/Components/Atoms/VerifiedBadge";

export type MobileMenuProps = {
  close: (focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null>) => void;
  navigation: NavigationItem[];
  userNavigation: UserNavigationItem[][];
};

export const NavbarMobileMenu = (props: MobileMenuProps) => {
  const {
    props: { auth },
  } = usePage<ResponseBase>();

  useEffect(() => {
    return router.on("start", (e) => {
      if (e.detail.visit.url.href === window.location.href) return;
      props.close();
    });
  }, []);

  return (
    <Disclosure.Panel className="lg:hidden">
      {({ close }) => (
        <>
          <div className="gap-y-1 border-t border-gray-800 px-2 py-3 sm:px-4">
            {props.navigation.map((item) => (
              <Link
                key={item.name}
                href={item.href}
                onClick={() => close()}
                aria-current={item.active ? "page" : undefined}
                className={cn(
                  buttonVariants({
                    variant: item.active ? "transparent" : "transparentGhost",
                  }),
                  "w-full justify-start",
                )}>
                {item.name}
              </Link>
            ))}
          </div>
          <div className="border-t border-gray-800 pb-3 pt-4">
            {auth.user ? (
              <>
                <div className="flex items-center px-5">
                  <Avatar
                    image={auth.user.avatar}
                    username={auth.user.username}
                    className="h-10 w-10 shrink-0"
                  />
                  <div className="ml-3 flex flex-col gap-0.5">
                    <div className="flex items-center gap-1">
                      <div className="text-sm font-medium text-white">{auth.user.username}</div>
                      <VerifiedBadge for={auth.user!} className="size-3.5" />
                    </div>
                    <div className="text-xs font-medium text-gray-400">
                      <Points points={auth.user.points!} />
                    </div>
                  </div>
                  <div className="ml-auto">
                    <NavbarNotifications />
                  </div>
                </div>
                <div className="mt-3 gap-y-1 px-2">
                  <div className="mb-2">
                    {!auth.user.battlestations_count && (
                      <div className="my-2">
                        <Link
                          href={route("battlestation.create")}
                          className={cn(
                            buttonVariants({
                              variant: "gradient",
                            }),
                            "w-full justify-start",
                          )}>
                          Create your battlestation
                        </Link>
                      </div>
                    )}
                    <a
                      href="https://discord.gg/jRVNHdBFzn"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={cn(
                        buttonVariants({ variant: "transparent" }),
                        "w-full justify-start",
                      )}>
                      <Discord className="mr-2 size-5" />
                      Join the Discord
                    </a>
                  </div>
                  {props.userNavigation.map((group, index) => (
                    <Fragment key={index}>
                      {group.map((item) =>
                        item.external ? (
                          <a
                            key={item.name}
                            href={item.href}
                            onClick={() => close()}
                            className={cn(
                              buttonVariants({
                                variant: item.active ? "transparent" : "transparentGhost",
                              }),
                              "w-full justify-start",
                            )}>
                            {item.name}
                          </a>
                        ) : (
                          <Link
                            key={item.name}
                            href={item.href}
                            onClick={() => close()}
                            as={(item.method ?? "get") === "get" ? "a" : "button"}
                            method={item.method ?? "get"}
                            aria-current={item.active ? "page" : undefined}
                            className={cn(
                              buttonVariants({
                                variant: item.active ? "transparent" : "transparentGhost",
                              }),
                              "w-full justify-start",
                            )}>
                            {item.name}
                          </Link>
                        ),
                      )}
                      {props.userNavigation.length > 1 &&
                        index < props.userNavigation.length - 1 && <Separator className="my-1" />}
                    </Fragment>
                  ))}
                </div>
              </>
            ) : (
              <div className="px-2">
                <div className="mb-2">
                  <a
                    href="https://discord.gg/jRVNHdBFzn"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={cn(
                      buttonVariants({ variant: "transparent" }),
                      "w-full justify-start",
                    )}>
                    <Discord className="mr-2 size-5" />
                    Join the Discord
                  </a>
                </div>
                <div className="w-100 flex gap-2 sm:px-4">
                  <Link
                    preserveState
                    preserveScroll
                    href={route("login")}
                    onClick={() => close()}
                    className={cn(buttonVariants({ variant: "transparent" }), "w-full")}>
                    Sign in
                  </Link>
                  <Link
                    preserveState
                    preserveScroll
                    href={route("register")}
                    onClick={() => close()}
                    className={cn(buttonVariants({ variant: "gradient" }), "w-full")}>
                    Create account
                  </Link>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </Disclosure.Panel>
  );
};
