import React from "react";
import { route } from "ziggy-js";
import axios from "axios";
import { router } from "@inertiajs/react";
import { Popover, PopoverContent, PopoverTrigger } from "@/Components/ui/popover";
import { Button } from "@/Components/ui/button";
import { Bell } from "lucide-react";
import { Notifications } from "@/Components/Organisms/Notifications/Notifications";
import { useUser } from "@/Hooks/useUser";
import { FormattedNumber } from "@/Components/Atoms/FormattedNumber";

export const NavbarNotifications = () => {
  const user = useUser();

  const markAllAsSeen = async () => {
    await axios.post(route("notifications.mark-all-as-seen"));
    if (user?.notifications_count) {
      router.reload();
    }
  };

  return (
    <Popover
      modal
      onOpenChange={(open) => {
        if (open) {
          markAllAsSeen();
        }
      }}>
      <PopoverTrigger asChild>
        <Button variant="transparentGhost" size="icon" className="relative">
          <span className="sr-only">View notifications</span>
          <Bell className="size-5" />
          {/* Unread count badge */}
          {user!.notifications_count! > 0 && (
            <div className="pointer-events-none absolute right-2 top-2 flex -translate-y-1/2 translate-x-1/2 transform flex-col items-center justify-center">
              <span
                className="absolute right-0 top-0 inline-flex -translate-y-1/2 translate-x-1/2 transform items-center justify-center rounded-full bg-accent px-1.5 py-0.5 text-xs font-bold leading-none text-accent-foreground
              ">
                <FormattedNumber number={user!.notifications_count!} />
              </span>
            </div>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent align="end" className="w-[calc(100vw-40px)] sm:w-80">
        <Notifications />
      </PopoverContent>
    </Popover>
  );
};
