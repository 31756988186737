import React, { useMemo } from "react";

type FormattedNumberWithoutSuffixProps = {
  number: number;
};

type FormattedNumberWithSuffixProps = {
  number: number;
  suffixSingular: string;
  suffixPlural: string;
};

export type FormattedNumberProps =
  | FormattedNumberWithoutSuffixProps
  | FormattedNumberWithSuffixProps;

export const FormattedNumber = (props: FormattedNumberProps) => {
  const suffixSingular = "suffixSingular" in props ? props.suffixSingular : "";
  const suffixPlural = "suffixPlural" in props ? props.suffixPlural : "";

  const content = useMemo(() => {
    const number = Intl.NumberFormat("en-US", {
      maximumFractionDigits: 1,
      notation: "compact",
      compactDisplay: "short",
    })
      .format(props.number)
      .toLowerCase();

    const suffix = props.number === 1 ? suffixSingular : suffixPlural;
    return [number, suffix].join(" ");
  }, [props.number, suffixSingular, suffixPlural]);

  return <>{content}</>;
};
