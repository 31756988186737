import React from "react";
import { cn } from "@/Utils/shadcn";

export type BadgeProps = {
  variant: "info" | "success" | "warning" | "error" | "indigo" | "purple" | "pink";
  text: string;
  className?: string;
};

export const Badge = (props: BadgeProps) => {
  return (
    <span
      className={cn(
        "inline-flex items-center rounded px-2 py-0.5 text-xs font-medium",
        props.variant === "info" && "bg-gray-100 text-gray-900",
        props.variant === "success" && "bg-green-100 text-green-900",
        props.variant === "warning" && "bg-yellow-100 text-yellow-900",
        props.variant === "error" && "bg-red-100 text-red-900",
        props.variant === "indigo" && "bg-indigo-100 text-indigo-900",
        props.variant === "purple" && "bg-purple-100 text-purple-900",
        props.variant === "pink" && "bg-pink-100 text-pink-900",
        props.className,
      )}>
      {props.text}
    </span>
  );
};
