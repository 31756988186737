import { z } from "zod";
import { idSchema } from "@/Schemas/idSchema";

export const fileSchema = z.object({
  id: idSchema,
  name: z
    .string()
    .min(2, "Image name must be at least 2 characters.")
    .max(100, "Image name must be no more than 100 characters.")
    .nullable(),
  key: z.string(),
  crop: z
    .object({
      unit: z.enum(["px", "%"]),
      x: z.number(),
      y: z.number(),
      width: z.number(),
      height: z.number(),
    })
    .nullish(),
  upload_response: z
    .object({
      bucket: z.string(),
      key: z.string(),
      url: z.string(),
      uuid: z.string(),
    })
    .optional(),
  meta: z
    .object({
      uploadedAt: z.string(),
    })
    .optional(),
});
