import React from "react";
import { CircleAlert } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "@/Components/ui/alert";

export type ErrorAlertProps = {
  errors: [string, string][];
};

export const FormErrorsAlert = (props: ErrorAlertProps) => {
  return (
    <Alert variant="destructive">
      <CircleAlert className="size-4" />
      <AlertTitle>Please fix the following issues:</AlertTitle>
      <AlertDescription>
        <ul>
          {props.errors.map(([key, value]) => (
            <li key={key} className="list-disc">
              {value}
            </li>
          ))}
        </ul>
      </AlertDescription>
    </Alert>
  );
};
