import React, { ReactNode } from "react";
import { useMomentumModal } from "@/Hooks/useMomentumModal";

export type ResolverFunction = typeof globalThis.resolveMomentumModal;

export type MomentumModalProps = {
  resolver?: ResolverFunction;
  children?: ReactNode;
};

export const MomentumModal = (props: MomentumModalProps) => {
  const { vnode } = useMomentumModal(props.resolver);
  return vnode;
};
