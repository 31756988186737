import React, { ReactNode } from "react";
import { Container } from "@/Components/Atoms/Container";
import { cn } from "@/Utils/shadcn";
import { Icon } from "@/Types/Icon";

export type SimpleBannerProps = {
  icon?: Icon;
  children?: ReactNode;
  containerClassName?: string;
};

export const SimpleBanner = (props: SimpleBannerProps) => {
  return (
    <div className="relative flex h-32 flex-col overflow-hidden">
      {/* Content */}
      <div className="absolute flex h-full w-full items-center justify-center border-b border-gray-800">
        <Container className={cn("flex items-center gap-4", props.containerClassName)}>
          {props.icon && (
            <div className="flex h-12 w-12 items-center justify-center rounded-md bg-secondary p-2">
              <props.icon className="-mx-1 h-7 w-7" aria-hidden={true} />
            </div>
          )}
          {props.children}
        </Container>
      </div>
    </div>
  );
};
