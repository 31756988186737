import React from "react";
import { useMomentumModal } from "@/Hooks/useMomentumModal";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/Components/ui/dialog";
import { PartyPopper } from "lucide-react";
import { buttonVariants } from "@/Components/ui/button";
import { Link } from "@inertiajs/react";
import { route } from "ziggy-js";

const EmailVerified = () => {
  const { show, close, redirect } = useMomentumModal();

  return (
    <Dialog
      open={show}
      onOpenChange={(open) => {
        if (!open) {
          // We call close() first to make closing the dialog feel more responsive
          close();
          redirect();
        }
      }}>
      <DialogContent className="max-h-[94%] max-w-sm overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Account verified</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col items-center gap-4 text-balance text-center text-sm">
          <PartyPopper className="size-12 text-muted-foreground" />
          <div className="text-primary">Thanks for joining the open beta!</div>
          <div className="text-primary">
            If you have a setup, now's the time to show it off. Check out the setup editor below, or
            just head to <strong>browse</strong> and enjoy rigr.gg!
          </div>
          <Link
            as="button"
            href={route("battlestation.create")}
            preserveState
            preserveScroll
            className={buttonVariants({ variant: "gradient" })}>
            Create a setup
          </Link>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EmailVerified;
