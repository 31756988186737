import { z } from "zod";
import { fileSchema } from "@/Schemas/fileSchema";
import { widgetSchema } from "@/Schemas/widgetSchema";
import { tagSchema } from "@/Schemas/tagSchema";

/*
 * !!! IMPORTANT !!!
 * Altering this schema may require a migration to be added to BattlestationAutoSave
 */

export const battlestationSchema = z.object({
  name: z
    .string()
    .min(2, "Setup name must be at least 2 characters.")
    .max(50, "Setup name must be no more than 50 characters."),
  about: z
    .string()
    .min(2, "Setup description must be at least 2 characters.")
    .max(3000, "Setup description must be no more than 3000 characters."),
  images: z.array(fileSchema),
  tags: z.array(tagSchema),
  widgets: z.array(widgetSchema),
});
