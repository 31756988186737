import Vapor from "laravel-vapor";
import { useFileUploadsStore } from "@/Stores/useFileUploadsStore";

export const uploadFileToS3 = async (file: File): Promise<App.Data.UploadResponseData> => {
  useFileUploadsStore.getState().uploadStarted();

  const result = await Vapor.store(file);

  useFileUploadsStore.getState().uploadFinished();

  return result;
};
