import { pick as _pick, map, get, set, merge, isArray } from "lodash";

export const pick = (object: any, paths: string[]) => {
  return paths.reduce((result, path) => {
    if (path.includes(".*.")) {
      const [collectionPath, itemPath] = path.split(/\.\*\.(.+)/);
      const collection = get(object, collectionPath);

      if (!isArray(collection)) {
        return result;
      }

      const partialResult = {};
      set(
        partialResult,
        collectionPath,
        map(collection, (item) => pick(item, [itemPath])),
      );

      return merge(result, partialResult);
    }

    return merge(result, _pick(object, [path]));
  }, {});
};

export const pickArray = (objects: any[], paths: string[]) =>
  objects.map((object) => pick(object, paths));
