import React, { memo, useMemo, useState } from "react";
import { CardTypeColor, CardTypeIcon } from "@/API/Maps/CardType";
import { cn } from "@/Utils/shadcn";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { BattlestationForm } from "@/API/Forms/BattlestationForm";
import type { BattlestationProps } from "@/Pages/Battlestation/Battlestation";
import { usePage } from "@inertiajs/react";
import { CustomCardDialog } from "@/Components/Molecules/Battlestation/Widgets/CustomCardWidget/CustomCardDialog";
import { Glow } from "@/Components/Molecules/Battlestation/Widgets/CustomCardWidget/Glow";
import { ArrowUpRight, Sparkles } from "lucide-react";
import { FieldTypeIcon } from "@/API/Maps/FieldType";
import { FormField } from "@/Components/ui/form";
import { countBy, filter, get, keyBy } from "lodash";
import { Skeleton } from "@/Components/ui/skeleton";
import { useMedia } from "react-use";
import { WidgetLayout } from "@/Components/Molecules/Battlestation/Widgets/WidgetLayout";
import { WidgetPropsBase } from "@/Types/Widgets/WidgetPropsBase";
import { useBattlestationStore } from "@/Stores/useBattlestationStore";

export type CustomCardWidgetProps = WidgetPropsBase<App.Data.Models.WidgetData>;

export const CustomCardWidget = memo(({ widget, ...props }: CustomCardWidgetProps) => {
  const page = usePage<BattlestationProps>();

  const form = useFormContext<BattlestationForm>();

  const editing = useBattlestationStore((state) => state.editing);
  const rearranging = useBattlestationStore((state) => state.rearranging);

  const isTouch = useMedia("(pointer: coarse)");

  const [open, setOpen] = useState(false);

  // passing in the name causes the widget to be re-rendered and briefly flash
  // the wrong widget data when two widgets are swapped. by using the widget id
  // to get the index instead, we can avoid this issue
  const widgetIndex = form.getValues("widgets").findIndex((x) => x.id === widget.id);

  const name: `widgets.${number}` = `widgets.${widgetIndex}`;

  const cardWatcher = useWatch({
    control: form.control,
    name: `${name}.card`,
  });

  const {
    fields: arrayFields,
    insert,
    remove,
  } = useFieldArray({
    control: form.control,
    name: `${name}.card.fields`,
  });

  const watchedFields = useWatch({
    control: form.control,
    name: `${name}.card.fields`,
  });

  const controlledFields = arrayFields.map((field, index) => ({
    ...field,
    ...watchedFields?.[index],
    // if we don't use the id from the field array weird shit happens
    id: field.id,
  }));

  const card = editing && !props.preview ? cardWatcher! : widget.card!;
  const fields = editing && !props.preview ? controlledFields : card.fields;

  const cardDefinition = useMemo(
    () => page.props.edit?.card_definitions.find((x) => x.type === card.type),
    [page.props.edit?.card_definitions, card.type],
  );

  const availableFields = useMemo(() => {
    const counts = countBy(fields, "type");
    return filter(
      cardDefinition?.fields,
      (field) => !field.limit || field.limit > get(counts, field.type, 0),
    );
  }, [cardDefinition?.fields, fields]);

  const fieldCount =
    editing && !props.preview
      ? watchedFields.length +
        watchedFields.reduce((total, field) => total + field.fields.length, 0)
      : card.fields.length + card.fields.reduce((total, field) => total + field.fields.length, 0);

  const fieldDefinitionByType = useMemo(
    () =>
      keyBy(
        page.props.edit?.card_definitions.flatMap((x) => x.fields),
        "type",
      ),
    [page.props.edit?.card_definitions],
  );

  const Icon = CardTypeIcon.get(card.type);

  return (
    <WidgetLayout
      gridConfig={props.gridConfig}
      clickable={true}
      needsAttention={editing && fields.length === 0}>
      {!props.preview && (
        <CustomCardDialog
          name={`${name}.card`}
          card={card}
          fields={fields}
          availableFields={availableFields!}
          fieldDefinitionByType={fieldDefinitionByType}
          fieldCount={fieldCount}
          showErrors={props.gridConfig?.showErrors ?? false}
          open={open}
          onOpenChange={setOpen}
          onAddField={(field) => {
            const order = cardDefinition!.fields.map((x) => x.type);
            const orderMap = Object.fromEntries(order.map((type, index) => [type, index]));

            const index = fields.findIndex((x) => orderMap[x.type] > orderMap[field.type]);

            insert(index === -1 ? fields.length : index, field);
          }}
          onRemoveField={remove}
        />
      )}
      <div
        className={cn(
          "flex justify-between overflow-hidden rounded-3xl",
          props.preview ? "h-full w-full" : "absolute inset-0",
        )}
        onClick={() => {
          if (rearranging || props.preview) return;
          setOpen(true);
        }}>
        {/* left side */}
        <div className="pointer-events-none relative z-10 aspect-square h-full @container">
          <div className="flex h-full w-full flex-col justify-between p-3 @6xs:p-4 @3xs:p-5">
            <div className="flex justify-between">
              {/* icon */}
              <div
                className={cn(
                  "flex size-12 items-center justify-center rounded-xl @5xs:size-14 @4xs:size-16 @3xs:size-[4.5rem]",
                  CardTypeColor.get(card.type),
                )}>
                {Icon && <Icon className="size-7 @4xs:size-9" />}
              </div>
              {/* arrow */}
              {widget.width === 1 && !props.gridConfig?.showErrors && (
                <ArrowUpRight className="-m-2 size-7 opacity-50 @4xs:size-9" />
              )}
            </div>
            <div className="flex flex-col">
              {/* item count */}
              <div className="@3xs:text-md text-xs font-bold leading-tight opacity-50 @4xs:text-sm">
                {fieldCount} item
                {fieldCount === 1 ? "" : "s"}
              </div>
              {/* widget name */}
              <div className="text-lg font-bold leading-tight @6xs:text-xl @4xs:text-2xl">
                {card.type_label}
              </div>
              {/* underline */}
              <div
                className={cn(
                  "mt-1 h-0.5 w-10 rounded-full @6xs:h-1 @6xs:w-14 sm:w-16",
                  CardTypeColor.get(card.type),
                )}
              />
            </div>
          </div>
        </div>
        {/* right side */}
        {widget.width === 2 && (
          <div className="pointer-events-none relative z-10 aspect-square h-full @container">
            <div className="h-full w-full p-3 @6xs:p-4 @3xs:p-5">
              {fieldCount > 0 ? (
                // first 3 items *
                <div className="flex flex-col gap-1.5">
                  {fields.slice(0, 3).map((item, index) => {
                    const Icon = FieldTypeIcon.get(item.type) ?? Sparkles;

                    return (
                      <div
                        key={item.id}
                        className={cn(
                          "flex gap-1.5 text-xs font-medium leading-tight @3xs:text-sm",
                          index === 1 && "opacity-90",
                          index === 2 && "opacity-80",
                        )}>
                        <Icon className="size-3.5 shrink-0 @3xs:mt-0.5 @3xs:size-4" />
                        {editing && !props.preview ? (
                          <FormField
                            control={form.control}
                            name={`${name}.card.fields.${index}.body`}
                            render={({ field }) => (
                              <span
                                className={cn(
                                  "line-clamp-2 text-ellipsis text-balance break-words",
                                  !field.value && "text-muted-foreground",
                                )}>
                                {field.value || item.type_label}
                              </span>
                            )}
                          />
                        ) : (
                          <span className="line-clamp-2 text-ellipsis text-balance break-words">
                            {item.body}
                          </span>
                        )}
                      </div>
                    );
                  })}
                  {fieldCount > 3 ? (
                    // + x more
                    <div className="hidden text-xs font-medium opacity-50 @5xs:block @3xs:text-sm">
                      + {fieldCount - 3} more <ArrowUpRight className="mb-0.5 inline h-4 w-4" />
                    </div>
                  ) : (
                    // click to expand
                    <div className="hidden text-xs font-medium opacity-50 @5xs:block @3xs:text-sm">
                      {isTouch ? "Touch" : "Click"} to expand{" "}
                      <ArrowUpRight className="mb-0.5 inline h-4 w-4" />
                    </div>
                  )}
                </div>
              ) : (
                // placeholder for no items
                <div className="flex h-full flex-col items-end justify-between">
                  {/* skeleton*/}
                  <div className="flex flex-col items-end gap-2">
                    <Skeleton className="h-4 w-20 animate-none rounded-sm" />
                    <Skeleton className="h-4 w-32 animate-none rounded-sm" />
                    <Skeleton className="h-4 w-28 animate-none rounded-sm" />
                  </div>
                  {/* click to expand */}
                  <div className="text-balance text-right text-xs font-medium opacity-50 @5xs:block @3xs:text-sm">
                    {isTouch ? "Touch" : "Click"} to add details{" "}
                    <ArrowUpRight className="mb-0.5 inline h-4 w-4" />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <Glow width={widget.width} typeLabel={card.type_label} />
      </div>
    </WidgetLayout>
  );
});
