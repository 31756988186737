import React from "react";
import { cn } from "@/Utils/shadcn";
import { InertiaLinkProps, Link as InertiaLink } from "@inertiajs/react";

export type LinkProps = InertiaLinkProps;

export const Link = ({ className, as, ...props }: LinkProps) => {
  return (
    <InertiaLink
      as={props.disabled ? "button" : as}
      className={cn(
        "font-medium text-indigo-400",
        props.disabled ? "cursor-not-allowed opacity-50" : "hover:text-indigo-300",
        className,
      )}
      {...props}
    />
  );
};
