import { SocialPlatform } from "@/Types/generated_enums";

export const SocialPlatformNetwork = new Map<SocialPlatform, string>([
  [SocialPlatform.YouTube, "youtube"],
  [SocialPlatform.Instagram, "instagram"],
  [SocialPlatform.Twitter, "x"],
  [SocialPlatform.Facebook, "facebook"],
  [SocialPlatform.TikTok, "tiktok"],
  [SocialPlatform.Twitch, "twitch"],
  [SocialPlatform.Patreon, "patreon"],
]);

export const SocialPlatformBackgroundStyle = new Map<SocialPlatform, string>([
  [SocialPlatform.YouTube, "bg-youtube"],
  [SocialPlatform.Instagram, "bg-instagram"],
  [SocialPlatform.Twitter, "bg-twitter border"],
  [SocialPlatform.Facebook, "bg-facebook"],
  [SocialPlatform.TikTok, "bg-tiktok border"],
  [SocialPlatform.Twitch, "bg-twitch"],
  [SocialPlatform.Patreon, "bg-patreon"],
]);
