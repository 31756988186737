import { useState, useEffect, useCallback, useRef } from "react";
import { FormImage } from "@/Types/FormImage";
import { uploadFileToS3 } from "@/Utils/uploadFileToS3";
import { uuid } from "@/Utils/uuid";
import { useImageKit } from "@/Hooks/useImageKit";

type TransformationType = Record<string, string>[];
type TransformationParam = TransformationType | (() => TransformationType);

export const useUploadImages = (transformationParam: TransformationParam = [{ width: "800" }]) => {
  const imageKit = useImageKit();
  const [uploading, setUploading] = useState(false);
  const transformationRef = useRef<TransformationType>([]);

  useEffect(() => {
    if (typeof transformationParam === "function") {
      transformationRef.current = transformationParam();
    } else {
      transformationRef.current = transformationParam;
    }
  }, [transformationParam]);

  const upload = useCallback(
    async (files: File[], limit: number): Promise<FormImage[] | undefined> => {
      if (!files.length) return;

      setUploading(true);

      const promises = files.slice(0, limit).map(async (file): Promise<FormImage> => {
        const uploadResponse = await uploadFileToS3(file);

        return {
          id: uuid(),
          name: file.name,
          key: uploadResponse.key,
          upload_response: uploadResponse,
          __meta: {
            uploadedAt: new Date().toISOString(),
          },
        };
      });

      const newImages = await Promise.all(promises);

      setUploading(false);

      return newImages;
    },
    [imageKit],
  );

  return [uploading, upload] as const;
};
