export type ShareContent = {
  url?: string;
  title?: string;
  imageUrl?: string;
};

export type SocialNetworkConfig = {
  url: string;
  params: {
    url?: string;
    title?: string;
    imageUrl?: string;
  };
};

export const generateShareLink = (
  content: ShareContent,
  socialConfig: SocialNetworkConfig,
): string => {
  const { url: baseUrl, params } = socialConfig;
  const queryParams: string[] = [];

  for (const [key, paramName] of Object.entries(params)) {
    if (content[key as keyof ShareContent] && paramName) {
      queryParams.push(
        `${paramName}=${encodeURIComponent(content[key as keyof ShareContent] as string)}`,
      );
    }
  }

  const queryString = queryParams.join("&");
  return queryString ? `${baseUrl}?${queryString}` : baseUrl;
};

export const socialNetworks: Record<string, SocialNetworkConfig> = {
  reddit: {
    url: "https://new.reddit.com/submit",
    params: { url: "url", title: "title" },
  },
  pinterest: {
    url: "https://pinterest.com/pin/create/button/",
    params: { url: "url", title: "description", imageUrl: "media" },
  },
  facebook: {
    url: "https://www.facebook.com/sharer/sharer.php",
    params: { url: "u" },
  },
  telegram: {
    url: "https://t.me/share/url",
    params: { url: "url", title: "text" },
  },
  twitter: {
    url: "https://twitter.com/intent/tweet",
    params: { url: "url", title: "text" },
  },
};
