import { RefObject, useRef, useState } from "react";

type SetStateAction<S> = S | ((prevState: S) => S);
type Dispatch<A> = (value: A) => void;

export const useStateWithRef = <S>(
  initialState: S | (() => S | undefined) | undefined,
): [S, RefObject<S | undefined>, Dispatch<SetStateAction<S>>] => {
  if (typeof initialState === "function") {
    initialState = (initialState as any)();
  }

  const [state, _setState] = useState(initialState);

  const stateRef = useRef(state);
  const setState = (newState: S | ((previousState: S) => S)) => {
    if (typeof newState === "function") {
      newState = (newState as any)(stateRef.current!);
    }

    stateRef.current = newState as S;
    _setState(newState as S);
  };

  return [state as S, stateRef, setState];
};
